import * as React from 'react'
import BootstrapSwitchButton from "bootstrap-switch-button-react"
import {getApiUrlFromRelativeUrl, request} from '../../utils/api/ApiUtil'
import { Action, ClientContext } from "react-fetching-library"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"
import Loading from '../loader/Loading'
import {AuthContext} from "../../App";
import AuthContextType from "../../types/AuthContext/AuthContextType";

interface PackageBootstrapSwitchButtonProps {
    packageName: string,
    packageId: number,
    advertisementId: number,
    jwtToken: string,
}

export default function PackageBootstrapSwitchButton(props: PackageBootstrapSwitchButtonProps): JSX.Element {
    // constants
    const INTERNAL_SERVER_ERROR_MESSAGE: string = 'Nastala neočakavaná chyba!'
    //- state
    const [isActive, setIsActive] = React.useState<boolean>(false)
    const [isLoading, setIsLoading] = React.useState<boolean>(true)
    //- actions
    const isActivePackageAction: Action = {
        method: 'GET',
        endpoint: getApiUrlFromRelativeUrl(`/api/secured/packageXAdvertisementXUser/hasUserActiveItemByPackageAdvertisement/${props.packageId}/${props.advertisementId}`),
        headers: {
            Authorization: 'Bearer ' + props.jwtToken,
            Accept: 'application/json',
        }
    }

    //- context
    const clientContext = React.useContext(ClientContext)
    const authContext: AuthContextType = React.useContext(AuthContext)
    //- init navigate
    const navigate = useNavigate()
    //- effect for isActive
    React.useEffect(() => {
        const isActivePackage = async (): Promise<void> => {
            const {payload, error, status} = await request(isActivePackageAction, clientContext)
            if (error) {
                toast.error(INTERNAL_SERVER_ERROR_MESSAGE)
                setIsLoading(false)
                return
            }
            if (status === 200) {
                setIsActive(payload.hasAccess)
            }
            if (status === 401) {
                authContext.logout()
            }
            setIsLoading(false)
        }
        isActivePackage()
    }, [])

    //- handlers

    return (
        <React.Fragment>
            {isLoading && <Loading/>}
            <BootstrapSwitchButton
                checked={isActive}
                disabled={isActive}
                onstyle="primary"
                onlabel={props.packageName}
                offlabel={props.packageName}
                offstyle="secondary"
                onChange={(checked: boolean) => checked ? navigate(`/order/${props.packageId}/${props.advertisementId}`) : setIsActive(true)}
            />
        </React.Fragment>
    )
}