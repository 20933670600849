import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { AdvertisementInterface } from "../../types/Advertisement/AdvertisementInterface"
import ShowAdvertisementsPageListCard from "./ShowAdvertisementsPageListCard"

interface ShowAdvertisementPageListProps {
    advertisements: AdvertisementInterface[],
    lastFetched: number,
}

export default function ShowAdvertisementPageList(props: ShowAdvertisementPageListProps): JSX.Element {
    //- init navigate
    const navigate = useNavigate()
    //- render
    return (
        <React.Fragment>
            {props.advertisements.map((advertisement: AdvertisementInterface) => (
                <div
                    key={advertisement.id}
                    style={{marginBottom: '2%'}}
                >
                    <ShowAdvertisementsPageListCard
                        advertisement={advertisement}
                        onCardClick={() => navigate(`/advertisement/view/${advertisement.id}`)}
                    />
                </div>
            ))}
        </React.Fragment>
    )
}
