import * as React from 'react'
import { Backdrop, CircularProgress, Typography } from "@mui/material"

export default function Loading(): JSX.Element {
    return (
        <Backdrop sx={{color: '#fff', zIndex: 100000000}} open={true}>
            {/* preto tu je takyto z-index, lebo napr. leaflet mapa ma z-index=400, t.j. bola nad loadingom, co nechceme */}
            <CircularProgress style={{color: '#1851AB'}} />
            <Typography variant='h6' component='div' style={{fontFamily: 'OpenSansBold', marginLeft: '1%'}}
                        className='text-primary'>
                Načítavam
            </Typography>
        </Backdrop>
    )
}
