import * as React from 'react'
import { Action, ClientContext } from 'react-fetching-library'
import { useNavigate } from 'react-router-dom'
import { getApiUrlFromRelativeUrl, request } from "../../utils/api/ApiUtil"
import ErrorComponent from "../../components/error/ErrorComponent"
import Loading from '../../components/loader/Loading'

export default function PaymentCheckStatusPage(): JSX.Element {
    //- constants
    const INTERNAL_SERVER_ERROR_MESSAGE: string = 'Nastala neočakavaná chyba!'
    const navigate = useNavigate()
    const searchParams = new URLSearchParams(window.location.search)
    const payment_uid = searchParams.get('payment_uid')
    const project_id = searchParams.get('project_id')

    const [isLoading, setIsLoading] = React.useState<boolean>(true)
    const [isError, setIsError] = React.useState<boolean>(false)
    // context
    const clientContext = React.useContext(ClientContext)

    //- actions
    const checkPaymentStatusAction: Action = {
        method: 'POST',
        endpoint: getApiUrlFromRelativeUrl(`/api/payment/status`),
        headers: {
            Accept: 'application/json'
        },
        body: {
            paymentId: payment_uid,
        }
    }

    //- effect
    React.useEffect(() => {
        const checkPaymentStatus = async (): Promise<void> => {
            setIsLoading(true)
            const {payload, error} = await request(checkPaymentStatusAction, clientContext)
            if (error) {
                setIsError(true)
            } else {
                if (payload.success) {
                    navigate(payload.data.redirectUrl)
                }
            }
            setIsLoading(false)
        }
        checkPaymentStatus()
    }, [])

    if (isLoading) {
        return (
            <Loading/>
        )
    }
    if (isError) {
        return (
            <ErrorComponent
                message={INTERNAL_SERVER_ERROR_MESSAGE}
            />
        )
    }

    //- nepotrebujeme renderovat, lebo effect usera redirectne kam treba
    return (
        <React.Fragment></React.Fragment>
    )
}