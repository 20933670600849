import * as React from 'react'
import { AuthContext } from '../../App'
import UserLoggedInterface from '../../types/User/UserLoggedInterface'
import UserViewProfileOrEditProfile from "../../components/user/UserViewProfileOrEditProfile"
import { useNavigate } from 'react-router-dom'
import { Container, Typography } from '@mui/material'
import { Action } from "react-fetching-library"
import { getApiUrlFromRelativeUrl } from "../../utils/api/ApiUtil"
import Loading from "../../components/loader/Loading"

export default function MyProfile(): JSX.Element {
    const authContext = React.useContext(AuthContext)
    const [userLogged, setUserLogged] = React.useState<UserLoggedInterface>(authContext.userLogged!)
    const [isLoading, setIsLoading] = React.useState<boolean>(true)
    const navigate = useNavigate()

    // actions
    const editUserAction: Action = {
        method: 'PUT',
        endpoint: getApiUrlFromRelativeUrl('/api/secured/user/edit/' + userLogged?.id),
        headers: {
            Authorization: 'Bearer ' + authContext.jwtToken,
            Accept: 'application/json'
        },
    }

    React.useEffect(() => {
        const getUser = async (): Promise<void> => {
            const userLogged = await authContext.getUserLogged()
            if (!userLogged) {
                navigate('/login')
            }
            setUserLogged(userLogged!)
            setIsLoading(false)
        }
        getUser()
    } , [])

    //- effect - enabled
    React.useEffect(() => {
        if (!authContext.userLogged?.enabled) {
            //- ak user nie je enabled, tak ho presmerujeme aby si aktivoval ucet
            navigate('/user/not-enabled')
        }
    }, [])

    if (isLoading) {
        return (
            <Loading/>
        )
    }

    return (
        <React.Fragment>
            <Container sx={{mt: '5%', mb: '5%'}}>
                <Typography variant='h5' component='div' style={{fontFamily: 'OpenSansBold'}} className='text-primary'>
                    Môj profil
                </Typography>
                <UserViewProfileOrEditProfile
                    userId={userLogged.id}
                    isView={false}
                    messageOnError={'Nepodarilo sa uložiť zmeny'}
                    messageOnSuccess={'Zmeny úspešne uložené'}
                    urlToRedirectToOnSuccess={'/my-profile'}
                    action={editUserAction}
                />
            </Container>
        </React.Fragment>
    )
}
