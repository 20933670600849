import * as React from 'react'
import { Card, Typography, Container, CardMedia, Tooltip } from '@mui/material'
import { AdvertisementInterface } from "../../types/Advertisement/AdvertisementInterface"
import { useNavigate } from "react-router-dom"
import {getApiUrlFromRelativeUrl} from "../../utils/api/ApiUtil";

interface ToppedAdvertisementCarouselItemProps {
    advertisement: AdvertisementInterface,
    urlToRedirect: string,
}

const getSubject = (subject: string): string => {
    // max 14 chars then add '...' and end on first possible space
    if (subject.length > 14) {
        return subject.substr(0, 14).concat('...')
    }
    return subject
}

export default function ToppedAdvertisementCarouselItem(props: ToppedAdvertisementCarouselItemProps): JSX.Element {
    //- init navigate
    const navigate = useNavigate()
    //- styles
    const cardStyle: React.CSSProperties = {
        border: 'none',
        width: '180px',
        height: '180px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#1851AB',
        borderRadius: '10px',
        position: 'relative',
        overflow: 'visible',
    }

    const imageStyle: React.CSSProperties = {
        width: '180px',
        height: '180px',
        objectFit: 'cover',
        borderRadius: '10px',
        position: 'absolute',
        left: '-5%', // Offset the image to the left by 5%
        top: '-5%',
    }

    const handleRedirect = () => {
        navigate(props.urlToRedirect)
        // scroll to top
        window.scrollTo(0, 0)
    }

    return (
        <Card style={cardStyle} className='clickable' onClick={handleRedirect}>
            {props.advertisement.photographs !== undefined && props.advertisement.photographs.length > 0 && (
                <React.Fragment>
                    <CardMedia
                        src={props.advertisement.photographs[0].url !== null && props.advertisement.photographs[0].url !== undefined ? getApiUrlFromRelativeUrl(props.advertisement.photographs[0].url ?? '') : ''}
                        component='img'
                        style={imageStyle}
                    />
                    <div className="rounded-borders background-primary"
                         style={{zIndex: 2, position: 'absolute', bottom: '20px', right: '20px', padding: '5px'}}>
                        <Typography variant='body1' align='center' style={{color: 'white', fontFamily: 'OpenSansBold'}}>
                            {props.advertisement.startingPrice?.price?.toFixed(2)} {props.advertisement.startingPrice?.currency}
                        </Typography>
                    </div>
                </React.Fragment>
            )}
            {(props.advertisement.photographs !== undefined && props.advertisement.photographs.length === 0) && (
                <Container>
                    <Typography variant='h6' align='center' style={{color: 'white'}}>
                        {getSubject(props.advertisement.subject ?? '').includes('...') ? (
                            <Tooltip title={props.advertisement.subject ?? ''}>
                                <span >
                                    {getSubject(props.advertisement.subject ?? '')}
                                </span>
                            </Tooltip>
                        ) : (
                            <React.Fragment>
                                {props.advertisement.subject}
                            </React.Fragment>
                        )}
                    </Typography>
                    <Typography variant='body1' align='center' style={{color: 'white'}}>
                        Nenašli sa žiadne fotografie
                    </Typography>
                    <div className="rounded-borders" style={{backgroundColor: 'white', marginTop: '15px'}}>
                        <Typography variant='body1' align='center' className='text-primary'
                                    style={{fontFamily: 'OpenSansBold'}}>
                            {props.advertisement.startingPrice?.price?.toFixed(2)} {props.advertisement.startingPrice?.currency}
                        </Typography>
                    </div>
                </Container>
            )}
        </Card>
    )
}
