import * as React from 'react'
import FaqItemInterface from "../../types/FaqItem/FaqItemInterface"
import { Action, ClientContext } from "react-fetching-library"
import { AuthContext } from "../../App"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"
import { DataGrid, GridColDef, GridToolbarQuickFilter } from "@mui/x-data-grid"
import PaginationProps from "../../types/pagination/PaginationProps"
import { getApiUrlFromRelativeUrl, request } from "../../utils/api/ApiUtil"
import ConfirmModal from "../../components/modal/ConfirmModal"
import {Box, Container, IconButton, Pagination, Stack, Tooltip, Typography} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from "@mui/icons-material/Delete"
import Loading from "../../components/loader/Loading"
import VisibilityIcon from "@mui/icons-material/Visibility"
import ErrorComponent from "../../components/error/ErrorComponent"
import PageSizeSelect from "../../components/pageSizeSelect/PageSizeSelect";

export default function FaqAdministration(): JSX.Element {
    // constants
    const INTERNAL_SERVER_ERROR_MESSAGE: string = 'Nastala neočakavaná chyba!'
    const FAQ_ITEM_SUCCESSFULLY_DELETED: string = 'FAQ položka bola úspešne vymazaná!'
    const [faqItems, setFaqItems] = React.useState<FaqItemInterface[]>([])
    const [isLoading, setIsLoading] = React.useState<boolean>(true)
    const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false)
    const [faqItemToDelete, setFaqItemToDelete] = React.useState<FaqItemInterface | null>(null)
    const [isError, setIsError] = React.useState<boolean>(false)
    const [page, setPage] = React.useState<number>(1)
    const [pageSize, setPageSize] = React.useState<number>(5)
    const [pagination, setPagination] = React.useState<PaginationProps>({
        pagination: [],
        total: 0,
        pagesCount: 0,
    })
    // Some API clients return undefined while loading
    // Following lines are here to prevent `rowCountState` from being undefined during the loading
    const [rowCountState, setRowCountState] = React.useState<number>(
        pagination.pagination.length > 0 ? pagination.pagination.length : 0,
    )
    React.useEffect(() => {
        setRowCountState(pagination.total)
    }, [pagination.total, setRowCountState])

    // context
    const clientContext = React.useContext(ClientContext)
    const authContext = React.useContext(AuthContext)

    // navigate
    const navigate = useNavigate()
    // actions
    const getFaqItemsAction: Action = {
        method: 'GET',
        endpoint: getApiUrlFromRelativeUrl('/api/faq/item/getAll/' + pageSize + '/' + page),
        headers: {
            Accept: 'application/json',
        }
    }

    const getFaqItems = async (): Promise<void> => {
        setIsLoading(true)
        const {payload, error} = await request(getFaqItemsAction, clientContext)
        if (error) {
            setIsError(true)
        } else {
            setFaqItems(payload.pagination)
            setPagination({
                ...pagination,
                pagesCount: payload.pagesCount === 0 ? 1 : payload.pagesCount,
                total: payload.total,
            })
        }
        setIsLoading(false)
    }

    //- effect - enabled
    React.useEffect(() => {
        if (!authContext.userLogged?.enabled) {
            //- ak user nie je enabled, tak ho presmerujeme aby si aktivoval ucet
            navigate('/user/not-enabled')
        }
    }, [])

    React.useEffect(() => {
        getFaqItems()
    }, [page, pageSize])

    React.useEffect(() => {
        if (!authContext.userLogged || !authContext.userLogged.hasAccessToFaqAdministration) {
            navigate('/')
        }
    }, [authContext.userLogged])

    const columns: GridColDef[] = [
        {
            field: 'id',
            headerClassName: 'background-primary open-sans-bold',
            headerAlign: 'center',
            headerName: '#',
            width: 70,
            renderCell: (params) => params.value
        },
        {
            field: 'question',
            headerClassName: 'background-primary open-sans-bold',
            headerAlign: 'center',
            headerName: 'Otázka',
            width: 200,
            renderCell: (params) => params.value
        },
        {
            field: 'answer',
            headerClassName: 'background-primary open-sans-bold',
            headerAlign: 'center',
            headerName: 'Odoveď',
            width: 350,
            renderCell: (params) => params.value
        },
        {
            field: 'created',
            headerClassName: 'background-primary open-sans-bold',
            headerAlign: 'center',
            headerName: 'Vytvorené',
            width: 200,
            renderCell: (params) => params.value
        },
        {
            field: 'lastEdited',
            headerClassName: 'background-primary open-sans-bold',
            headerAlign: 'center',
            headerName: 'Naposledy editované',
            width: 200,
            renderCell: (params) => params.value
        },
        {
            field: 'actions',
            headerClassName: 'background-primary open-sans-bold',
            headerAlign: 'center',
            headerName: 'Akcie',
            sortable: false,
            width: 140,
            renderCell: (params) => params.value
        }
    ]

    const rows: any[] = []

    faqItems.forEach((faqItem: FaqItemInterface) => {
        rows.push({
            id: faqItem.id,
            question: faqItem.question,
            answer: faqItem.answer,
            created: faqItem.createdBy.firstName + ' ' + faqItem.createdBy.lastName,
            lastEdited: (faqItem.lastEditedBy?.firstName ?? '') + ' ' + (faqItem.lastEditedBy?.lastName ?? ''),
            actions: (
                <React.Fragment>
                    <Tooltip title='Zobraziť FAQ položku'>
                        <IconButton aria-label="Zobraziť FAQ položku"
                                    onClick={() => handleViewFaqItem(faqItem.id)}>
                            <VisibilityIcon className='icon'/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title='Upraviť FAQ položku'>
                        <IconButton aria-label="Upraviť FAQ položku"
                                    onClick={() => handleEdit(faqItem.id)}>
                            <EditIcon className='icon'/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Zmazať FAQ položku">
                        <IconButton aria-label="Zmazať FAQ položku"
                                    onClick={() => askDeleteQuestion(faqItem)}
                        >
                            <DeleteIcon className='icon'/>
                        </IconButton>
                    </Tooltip>
                </React.Fragment>
            )
        })
    })

    const askDeleteQuestion = (faqItem: FaqItemInterface) => {
        setFaqItemToDelete(faqItem)
        setIsModalOpen(true)
    }

    const handleAdd = () => {
        navigate('/add/faq-item')
    }

    const handleEdit = (id: number) => {
        navigate(`/edit/faq-item/${id}`)
    }

    const handleDelete = (faqItem: FaqItemInterface) => {
        handleDelete2(faqItem.id)
        hideModalAndClearFaqItemToDelete()
    }

    const handleDelete2 = async (id: number) => {
        setIsLoading(true)
        const deleteFaqItemAction: Action = {
            method: 'DELETE',
            endpoint: getApiUrlFromRelativeUrl(`/api/secured/faq/item/delete/${id}`),
            headers: {
                Authorization: `Bearer ${authContext.jwtToken}`,
                Accept: 'application/json'
            }
        }
        const {error, status} = await request(deleteFaqItemAction, clientContext)
        if (error) {
            toast.error(INTERNAL_SERVER_ERROR_MESSAGE)
            setIsLoading(false)
            return
        }
        if (status === 401) {
            authContext.logout()
        }
        if (status === 204) {
            toast.success(FAQ_ITEM_SUCCESSFULLY_DELETED)
            getFaqItems()
            setIsLoading(false)
        } else {
            toast.error(INTERNAL_SERVER_ERROR_MESSAGE)
            setIsLoading(false)
        }
    }

    const handleViewFaqItem = (id: number) => {
        navigate(`/view/faq-item/${id}`)
    }

    const hideModalAndClearFaqItemToDelete = () => {
        setIsModalOpen(false)
        setFaqItemToDelete(null)
    }

    if (isError) {
        return (
            <ErrorComponent
                message={INTERNAL_SERVER_ERROR_MESSAGE}
            />
        )
    }

    return (
        <React.Fragment>
            {isLoading && <Loading/>}
            <ConfirmModal
                show={isModalOpen}
                onCloseCallback={() => hideModalAndClearFaqItemToDelete()}
                onSuccessCallback={() => handleDelete(faqItemToDelete as FaqItemInterface)}
                message={`Naozaj chcete vymazať FAQ položku '${faqItemToDelete?.question ?? ''}'?`}
                title='Vymazanie FAQ položky'
            />
            <Container sx={{mt: '5%', mb: '2%'}}>
                <Typography variant='h5' component='div' style={{fontFamily: 'OpenSansBold'}} className='text-primary'>
                    FAQ administrácia
                </Typography>
                <button
                    onClick={handleAdd}
                    className='background-primary rounded-borders border-primary mt-2 mb-4'
                    style={{padding: '5px'}}
                >
                    <AddIcon/>
                    <span style={{fontFamily: 'OpenSansBold'}}>
                        Pridať FAQ položku
                    </span>
                </button>
                <DataGrid
                    rowCount={rowCountState}
                    disableRowSelectionOnClick={true}
                    disableColumnMenu={true}
                    columns={columns}
                    loading={isLoading}
                    paginationMode='server'
                    pageSizeOptions={[5, 10, 25]}
                    rows={rows}
                    disableColumnSelector
                    disableDensitySelector
                    hideFooter
                    hideFooterPagination
                    hideFooterSelectedRowCount={true}
                    density={"standard"}
                    slots={{
                        toolbar: GridToolbarQuickFilter,
                        loadingOverlay: Loading,
                    }}
                />
                <Stack direction="row" pt={2}>
                    <Box flex={1}>
                        {faqItems && (
                            <Typography variant="subtitle2">
                                Počet najdených záznamov: {pagination.total}
                            </Typography>
                        )}
                    </Box>
                    <Pagination
                        count={pagination.pagesCount}
                        page={page}
                        siblingCount={3}
                        onChange={(e, value) => setPage(value)}
                    />
                    <Box flex={1}/>
                    <PageSizeSelect
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                    />
                </Stack>
            </Container>
        </React.Fragment>
    )
}
