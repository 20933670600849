import * as React from 'react'
import { PackageInterface } from "../../types/package/PackageInterface"
import { Action, ClientContext } from "react-fetching-library"
import { getApiUrlFromRelativeUrl, request } from "../../utils/api/ApiUtil"
import { FeatureInterface } from "../../types/feature/FeatureInterface"
import PackageCard from "./PackageCard"
import { Grid } from '@mui/material'
import Loading from "../loader/Loading"
import ErrorComponent from "../error/ErrorComponent"

interface PackagesProps {
    advertisementId: number,
    userId: number, // userLogged id
    jwtToken: string | null,
}

export default function Packages(props: PackagesProps): JSX.Element {
    //- constants
    const INTERNAL_SERVER_ERROR_MESSAGE: string = 'Nastala neočakavaná chyba!'
    //- state
    const [packages, setPackages] = React.useState<PackageInterface[]>([])
    const [features, setFeatures] = React.useState<FeatureInterface[]>([])
    const [isLoading, setIsLoading] = React.useState<boolean>(true)
    const [isError, setIsError] = React.useState<boolean>(false)
    // context
    const clientContext = React.useContext(ClientContext)

    //- action-s
    const getPackagesAllAction: Action = {
        method: 'GET',
        endpoint: getApiUrlFromRelativeUrl('/api/package/getAll/notPaginated'),
        headers: {
            Accept: 'application/json'
        }
    }

    const getFeaturesAllAction: Action = {
        method: 'GET',
        endpoint: getApiUrlFromRelativeUrl('/api/feature/getAll/notPaginated/1'),
        headers: {
            Accept: 'application/json'
        }
    }


    React.useEffect(() => {
        const getPackages = async (): Promise<void> => {
            const {payload, error} = await request(getPackagesAllAction, clientContext)
            if (error) {
                setIsError(true)
            } else {
                setPackages(payload)
            }
            // POZOR: loading, nevypiname uz tu lebo este tu nemame vsetky data
        }
        const getFeatures = async (): Promise<void> => {
            const {payload, error} = await request(getFeaturesAllAction, clientContext)
            if (error) {
                setIsError(true)
            } else {
                setFeatures(payload)
            }
            setIsLoading(false)
        }
        getPackages()
        getFeatures()
    }, [])

    //- loading
    if (isLoading) {
        return (
            <Loading/>
        )
    }

    if (isError) {
        return (
            <ErrorComponent
                message={INTERNAL_SERVER_ERROR_MESSAGE}
            />
        )
    }

    return (
        <React.Fragment>
            <Grid container spacing={2}>
                {packages.map((packageItem: PackageInterface, index: number) => (
                    <Grid item xs={12} md={4} key={index}>
                        <PackageCard
                            key={index}
                            packageItem={packageItem}
                            features={features}
                            advertisementId={props.advertisementId}
                            userId={props.userId}
                            jwtToken={props.jwtToken}
                        />
                    </Grid>
                ))}
            </Grid>
        </React.Fragment>
    )
}