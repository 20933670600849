import * as React from 'react'
import { Action, ClientContext } from 'react-fetching-library'
import { toast } from 'react-toastify'
import { useNavigate } from "react-router-dom"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
import { getApiUrlFromRelativeUrl, request } from "../../utils/api/ApiUtil"
import { Card, CardContent, Grid, Tooltip, Typography } from "@mui/material"
import { Visibility, VisibilityOff } from "@mui/icons-material"
import Loading from "../loader/Loading"

interface ChangePasswordProps {
    Action: Action,
    redirectToUrl: string
    errorKey: string,
    isRecaptchaEnabled: boolean
}

export default function ChangePassword(props: ChangePasswordProps): JSX.Element {
    // constants
    const INTERNAL_SERVER_ERROR_MESSAGE: string = 'Nastala neočakavaná chyba!'
    const PASSWORD_CHANGED_MESSAGE: string = 'Heslo bolo úspešne zmenené'
    const PASSWORD_WASNT_CHANGED_ERROR_MESSAGE: string = 'Nepodarilo sa zmeniť heslo'
    const VERIFY_YOU_ARE_NOT_A_ROBOT: string = 'Overte prosím, že nie ste robot'
    // state
    const [password, setPassword] = React.useState<string | null>(null)
    const [passwordRepeat, setPasswordRepeat] = React.useState<string | null>(null)
    const [isPasswordValid, setIsPasswordValid] = React.useState<boolean>(true) // defaultne je null validne
    const [isPasswordMatched, setIsPasswordMatched] = React.useState<boolean>(true)
    const [isLoading, setIsLoading] = React.useState<boolean>(false)
    const [passwordVisible, setPasswordVisible] = React.useState(false)
    const [passwordRepeatVisible, setPasswordRepeatVisible] = React.useState(false)
    //- recaptcha
    const {executeRecaptcha} = useGoogleReCaptcha()
    // context
    const clientContext = React.useContext(ClientContext)
    // init useNavigate
    const navigate = useNavigate()
    // on change
    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.name === 'password') {
            setPassword(event.target.value)
            validatePasswordRegex(event.target.value)
        } else if (event.target.name === 'passwordRepeat') {
            setPasswordRepeat(event.target.value)
            validatePasswordMatch(password, event.target.value)
        }
    }

    const validatePasswordRegex = (password: string | null): void => {
        const passwordValidationRegex: RegExp = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{10,}$/i
        if (password === null) {
            setIsPasswordValid(true) // null je validne
        } else {
            setIsPasswordValid(passwordValidationRegex.test(password))
        }
    }

    const validatePasswordMatch = (password: string | null, passwordRepeat: string | null): void => {
        setIsPasswordMatched(password === passwordRepeat)
    }

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible)
    }

    const togglePasswordRepeatVisibility = (): void => {
        setPasswordRepeatVisible(!passwordRepeatVisible)
    }

    const action = {
        ...props.Action,
        body: {
            ...props.Action.body,
            plainPassword: password,
        }
    }

    const verifyRecaptchaAction: Action = {
        method: 'POST',
        endpoint: getApiUrlFromRelativeUrl('/api/recaptcha/verify'),
    }

    // submit
    const onSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault()
        if (!isPasswordValid || !isPasswordMatched) {
            validatePasswordRegex(password)
            validatePasswordMatch(password, passwordRepeat)
            toast.error(PASSWORD_WASNT_CHANGED_ERROR_MESSAGE)
            return
        }
        setIsLoading(true)
        const recaptchaToken = await executeRecaptcha?.('reset_password')
        if (!props.isRecaptchaEnabled || recaptchaToken) {
            //- verify recaptcha
            const verifyRecaptchaAction2: Action = {
                ...verifyRecaptchaAction,
                body: {
                    recaptchaToken: recaptchaToken,
                }
            }
            let canSubmitForm: boolean = false
            if (props.isRecaptchaEnabled) {
                const {payload, error} = await request(verifyRecaptchaAction2, clientContext)
                if (error) {
                    toast.error(INTERNAL_SERVER_ERROR_MESSAGE)
                    setIsLoading(false)
                    return
                }
                canSubmitForm = payload.success === true
            } else {
                canSubmitForm = true
            }
            if (canSubmitForm) {
                //- ideme na server s datami z formu
                const {payload, error} = await request(action, clientContext)
                if (error) {
                    toast.error(INTERNAL_SERVER_ERROR_MESSAGE)
                    setIsLoading(false)
                    return
                }
                if (payload.success === true) {
                    navigate(props.redirectToUrl)
                    toast.success(PASSWORD_CHANGED_MESSAGE)
                } else if (payload.success === false) {
                    const errors = payload.errors
                    const keys = Object.keys(errors)
                    if (keys.indexOf(props.errorKey) !== -1) {
                        toast.error(errors[props.errorKey])
                    } else if (keys.indexOf('passwordPlain') !== -1) {
                        setIsPasswordValid(false)
                        toast.error(PASSWORD_WASNT_CHANGED_ERROR_MESSAGE)
                    }
                }
            } else {
                toast.error(VERIFY_YOU_ARE_NOT_A_ROBOT)
            }
        } else {
            toast.error(VERIFY_YOU_ARE_NOT_A_ROBOT)
        }
        setIsLoading(false)
    }

    if (isLoading) {
        return (
            <Loading/>
        )
    }

    return (
        <Card sx={{minWidth: 300, border: 'none', boxShadow: 'none'}}>
            <CardContent className='grey-background'
                         style={{borderRadius: '15px'}}>
                <div className="w-100 text-center">
                    <Typography variant='h6' component='div' style={{fontFamily: 'OpenSansBold'}}
                                className='text-primary'>
                        Zabudnuté heslo - zmena hesla
                    </Typography>
                </div>
                <form onSubmit={onSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                            <div className="form-group">
                                <label htmlFor="password" className='required text-primary'>
                                    Heslo
                                </label>
                                <div className='password-input-wrapper'>
                                    <div className='input-container'>
                                        <input
                                            type={passwordVisible ? 'text' : 'password'}
                                            name="password"
                                            id="password"
                                            className='form-control border-primary rounded-borders'
                                            value={password ?? ''}
                                            onChange={onChange}
                                        />
                                        <div className='password-toggle-icon' onClick={togglePasswordVisibility}>
                                            {passwordVisible ? (
                                                <Tooltip title='Skryť heslo'>
                                                    <VisibilityOff className='icon'/>
                                                </Tooltip>
                                            ) : (
                                                <Tooltip title='Zobraziť heslo'>
                                                    <Visibility className='icon'/>
                                                </Tooltip>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    {!isPasswordValid ? (
                                        <p className='error'>
                                            Hodnota nie je platné heslo. Heslo musí obsahovať aspoň 10 znakov, malé
                                            a veľké písmená,
                                            číslo a špeciálny znak
                                        </p>
                                    ) : null}
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <div className="form-group">
                                <label htmlFor="passwordRepeat" className='required text-primary'>
                                    Heslo znova
                                </label>
                                <div className='password-input-wrapper'>
                                    <div className='input-container'>
                                        <input
                                            type={passwordRepeatVisible ? 'text' : 'password'}
                                            name="passwordRepeat"
                                            id="passwordRepeat"
                                            className='form-control border-primary rounded-borders'
                                            value={passwordRepeat ?? ''}
                                            onChange={onChange}
                                        />
                                        <div className='password-toggle-icon' onClick={togglePasswordRepeatVisibility}>
                                            {passwordRepeatVisible ? (
                                                <Tooltip title='Skryť heslo'>
                                                    <VisibilityOff className='icon'/>
                                                </Tooltip>
                                            ) : (
                                                <Tooltip title='Zobraziť heslo'>
                                                    <Visibility className='icon'/>
                                                </Tooltip>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    {!isPasswordMatched ? (
                                        <p className='error'>
                                            Heslá sa nezhodujú!
                                        </p>
                                    ) : null}
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <div className="form-group w-100 text-center">
                                <button
                                    type="submit"
                                    className="background-primary rounded-borders border-primary"
                                    style={{fontFamily: 'OpenSansBold', fontSize: '18px', padding: '10px'}}
                                >
                                    Resetovať heslo
                                </button>
                            </div>
                        </Grid>
                    </Grid>
                </form>
            </CardContent>
        </Card>
    )
}
