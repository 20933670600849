import * as React from 'react'
import {Container, Typography} from "@mui/material"

export default function GeneralTermsPage(): JSX.Element {
    const handleJump = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
        e.preventDefault()
        const href = e.currentTarget.getAttribute('href')
        if (href !== null) {
            const element = document.querySelector(href)
            if (element !== null) {
                element.scrollIntoView({behavior: 'smooth'})
                // add highlight class to the element
                element.classList.add('highlight')
                setTimeout(() => {
                    element.classList.remove('highlight')
                }, 1500)
            }
        }
    }

    return (
        <Container sx={{mt: '2%'}}>
            <div className='text-center'>
                <Typography variant='h6' component='div' style={{fontFamily: 'OpenSansBold'}} className='text-primary'>
                    Podmienky používania portálu drazimto.sk
                </Typography>
                <a href="#jump1" onClick={handleJump}>
                    <strong>
                        I. &Uacute;vodn&eacute; ustanovenia a z&aacute;kladn&eacute; pojmy
                    </strong>
                </a>
                <br/>
                <a href="#jump2" onClick={handleJump}>
                    <strong>
                        II. Z&aacute;kladn&eacute; podmienky použ&iacute;vania
                    </strong>
                </a>
                <br/>
                <a href="#jump3" onClick={handleJump}>
                    <strong>
                        III. Ochrana osobn&yacute;ch &uacute;dajov
                    </strong>
                </a>
                <br/>
                <a href="#jump4" onClick={handleJump}>
                    <strong>
                        IV. Registr&aacute;cia použ&iacute;vateľa
                    </strong>
                </a>
                <br/>
                <a href='#jump5' onClick={handleJump}>
                    <strong>
                        V. &Uacute;prava &uacute;dajov inzerenta
                    </strong>
                </a>
                <br/>
                <a href="#jump6" onClick={handleJump}>
                    <strong>
                        VI. Spr&aacute;vanie sa Použ&iacute;vateľov
                    </strong>
                </a>
                <br/>
                <a href="#jump7" onClick={handleJump}>
                    <strong>
                        VII. Pridávanie inzerátov
                    </strong>
                </a>
                <br/>
                <a href="#jump8" onClick={handleJump}>
                    <strong>
                        VIII. Manuálne pridanie
                    </strong>
                </a>
                <br/>
                <a href="#jump9" onClick={handleJump}>
                    <strong>
                        IX. Automatický import
                    </strong>
                </a>
                <br/>
                <a href="#jump10" onClick={handleJump}>
                    <strong>
                        X. Administrácia inzerátov
                    </strong>
                </a>
                <br/>
                <a href="#jump11" onClick={handleJump}>
                    <strong>
                        XI. Platené služby Portálu – zverejnenie ponuky/inzerátu a doplnkové služby
                    </strong>
                </a>
                <br/>
                <a href="#jump12" onClick={handleJump}>
                    <strong>
                        XII. Služba “Basic”
                    </strong>
                </a>
                <br/>
                <a href="#jump13" onClick={handleJump}>
                    <strong>
                        XIII. Služba “Standard”
                    </strong>
                </a>
                <br/>
                <a href="#jump14" onClick={handleJump}>
                    <strong>
                        XIV. Služba “Na mieru”
                    </strong>
                </a>
                <br/>
                <a href="#jump15" onClick={handleJump}>
                    <strong>
                        XV. Zrušenie konta
                    </strong>
                </a>
                <br/>
                <a href="#jump16" onClick={handleJump}>
                    <strong>
                        XVI. Zodpovednosť za škodu
                    </strong>
                </a>
                <br/>
                <a href="#jump17" onClick={handleJump}>
                    <strong>
                        XVII. Zodpovednosť za vady a Reklamačný poriadok
                    </strong>
                </a>
                <br/>
                <a href="#jump18" onClick={handleJump}>
                    <strong>
                        XVIII. Osobitné ustanovenia týkajúce sa platených služieb
                    </strong>
                </a>
                <br/>
                <a href="#jump19" onClick={handleJump}>
                    <strong>
                        XIX. Záverečné ustanovenia
                    </strong>
                </a>
                <br/>
                <p id='jump1' style={{fontFamily: 'OpenSansBold'}} className='text-primary'>
                    <strong>
                        I. &Uacute;vodn&eacute; ustanovenia a z&aacute;kladn&eacute; pojmy
                    </strong>
                </p>
                <p>Tieto Podmienky použ&iacute;vania port&aacute;lu drazimto.sk (ďalej len &bdquo;
                    <strong>Podmienky</strong>&ldquo;) upravuj&uacute; pr&aacute;va a&nbsp;povinnosti
                    prev&aacute;dzkovateľa a použ&iacute;vateľov pri poskytovan&iacute; a
                    využ&iacute;van&iacute; služieb na port&aacute;li drazimto.sk.
                </p>
                <p>Prev&aacute;dzkovateľom port&aacute;lu sk (ďalej
                    len &bdquo;Port&aacute;l&ldquo; alebo &bdquo;drazimtosk&ldquo;) a poskytovateľom služieb je
                    spoločnosť RDO s.r.o., so s&iacute;dlom Tov&aacute;rensk&aacute; 2590/5, 811 09
                    Bratislava-mestsk&aacute; časť Star&eacute; Mesto, IČO: <strong>55 506 305</strong>,
                    zap&iacute;san&aacute; v Obchodnom registri Mestsk&eacute;ho s&uacute;du Bratislava III, oddiel:
                    Sro, vložka č.: 170399/B (ďalej len &bdquo;Prev&aacute;dzkovateľ&ldquo;). Adresa elektronickej
                    po&scaron;ty: info@drazimto.sk.
                </p>
                <p>Službou sa rozumej&uacute; najm&auml; služby vyhľad&aacute;vania, online inzertn&eacute; služby,
                    ktor&eacute; Prev&aacute;dzkovateľ poskytuje na sprostredkovanie ponuky a dopytu tovarov a
                    služieb medzi Použ&iacute;vateľmi, doplnkov&eacute; platen&eacute; služby, ako aj ďal&scaron;ie
                    služby, ktor&eacute; Prev&aacute;dzkovateľ aktu&aacute;lne poskytuje na Port&aacute;li (ďalej
                    len &bdquo;<strong>Služba</strong>&ldquo;).
                </p>
                <p>Použ&iacute;vateľom Port&aacute;lu je každ&aacute; fyzick&aacute; alebo
                    pr&aacute;vnick&aacute; osoba, ktor&aacute; vstupuje na Port&aacute;l a využ&iacute;va Služby
                    poskytovan&eacute; Prev&aacute;dzkovateľom na Port&aacute;li (ďalej len &bdquo;
                    <strong>Použ&iacute;vateľ</strong>&ldquo; alebo &bdquo;
                    <strong>Použ&iacute;vatelia</strong>&ldquo;). Ak je Použ&iacute;vateľom fyzick&aacute; osoba,
                    t&aacute;to mus&iacute; byť star&scaron;ia ako 16 rokov.
                </p>
                <p>Iinzerentom sa rozumie fyzick&aacute; osoba alebo pr&aacute;vnick&aacute; osoba
                    pred&aacute;vaj&uacute;ca svoj s&uacute;kromn&yacute; majetok, majetok v&nbsp;spr&aacute;ve
                    v&nbsp;zmysle ustanoven&iacute; z&aacute;kona č. 7/2005 Z.z. o&nbsp;konkurze
                    a&nbsp;re&scaron;trukturaliz&aacute;cii alebo majetok speňažovan&yacute; na z&aacute;klade
                    ustanoven&iacute; z&aacute;kona č. 527/2002 Z. z. o&nbsp;dobrovoľn&yacute;ch dražb&aacute;ch,
                    alebo v&nbsp;zmysle ustanoven&iacute; z&aacute;kona č. 233/1995 Z. z. exekučn&yacute; poriadok
                    (ďalej ako &bdquo;<strong>inzerent</strong>&ldquo; alebo taktiež aj ako &bdquo;
                    <strong>predajca</strong>&ldquo;).
                </p>
                <p>Pr&iacute;slu&scaron;n&yacute;m org&aacute;nom kontroly poskytovania t&yacute;mito Podmienkami
                    upraven&yacute;ch a s nimi s&uacute;visiacich Služieb je
                    Slovensk&aacute; obchodn&aacute; in&scaron;pekcia, In&scaron;pektor&aacute;t SOI pre Trenčiansky
                    kraj, Hurbanova 59, 911 01&nbsp; Trenč&iacute;n, tel. č. 032/640 01 09&nbsp;(ďalej len &bdquo;
                    <strong>SOI</strong>&ldquo;).
                </p>
                <p id='jump2' style={{fontFamily: 'OpenSansBold'}} className='text-primary'>
                    <strong>
                        II. Z&aacute;kladn&eacute; podmienky použ&iacute;vania
                    </strong>
                </p>
                <p>Prev&aacute;dzkovateľ prostredn&iacute;ctvom vytvorenia technologick&eacute;ho z&aacute;zemia a
                    komunikačn&yacute;ch prostriedkov vytv&aacute;ra pre Použ&iacute;vateľov možnosť pon&uacute;kať
                    na predaj jednotliv&eacute; kateg&oacute;rie tovaru, na ktor&yacute; je Port&aacute;l
                    zameran&yacute; a možnosť nakupovať tento tovar ďal&scaron;&iacute;m Použ&iacute;vateľom.
                    K&nbsp;uzatv&aacute;raniu obchodu doch&aacute;dza medzi konkr&eacute;tnymi Použ&iacute;vateľmi
                    a&nbsp;to v&nbsp;z&aacute;vislosti od toho o&nbsp;ak&yacute; druh a&nbsp;sp&ocirc;sob
                    speňažovania majetku, ktor&yacute; je inzerovan&yacute; na drazimto.sk, ide. V&scaron;etku
                    zodpovednosť za riadne plnenia medzi konkr&eacute;tnymi Použ&iacute;vateľmi ako aj za
                    pr&iacute;padn&eacute; reklamačn&eacute; konanie a ak&yacute;chkoľvek n&aacute;rokov
                    nes&uacute; dan&iacute; Použ&iacute;vatelia.
                </p>
                <p>Prev&aacute;dzkovateľ nenesie zodpovednosť za obsah inzer&aacute;tov, pr&iacute;spevkov do
                    diskusi&iacute;, hodnoten&iacute; ani za žiadne z&aacute;v&auml;zky medzi Použ&iacute;vateľmi
                    navz&aacute;jom, ktor&eacute; vznikn&uacute; na z&aacute;klade k&uacute;py a predaja
                    jednotliv&yacute;ch inzerovan&yacute;ch tovarov a služieb.
                </p>
                <p>Prev&aacute;dzkovateľ nenesie zodpovednosť za zneužitie &uacute;dajov uverejnen&yacute;ch pri
                    ponuk&aacute;ch inzerentov tret&iacute;mi stranami.
                </p>
                <p>Využ&iacute;vanie služieb Port&aacute;lu je možn&eacute; len po s&uacute;hlase
                    Použ&iacute;vateľa s&nbsp;Podmienkami. Prev&aacute;dzkovateľ neposkytuje vedome služby
                    fyzick&yacute;m osob&aacute;m mlad&scaron;&iacute;m ako 16 rokov.
                </p>
                <p>Prev&aacute;dzkovateľ si vyhradzuje pr&aacute;vo prepojenia Port&aacute;lu aj s in&yacute;mi
                    port&aacute;lmi, ktor&yacute;ch je taktiež prev&aacute;dzkovateľom alebo ktor&eacute; patria
                    jeho matersk&yacute;m alebo dc&eacute;rskym spoločnostiam alebo ku ktor&yacute;m
                    m&aacute; Prev&aacute;dzkovateľ in&eacute; pr&aacute;va.
                </p>
                <p>Služby Port&aacute;lu s&uacute; pre inzerentov spoplatnen&eacute;, cena a podmienky inzercie
                    s&uacute; definovan&eacute; ďalej.
                </p>
                <p>Pokiaľ Použ&iacute;vateľ v s&uacute;vislosti s využ&iacute;van&iacute;m služieb Port&aacute;lu
                    odovzd&aacute; svoje n&aacute;zory, prejavy, podnety, pripomienky, texty, obr&aacute;zky,
                    vide&aacute; alebo in&eacute; pr&iacute;spevky (ďalej len &bdquo;
                    <strong>Pr&iacute;spevky</strong>&ldquo;) elektronicky, po&scaron;tou či in&yacute;m
                    sp&ocirc;sobom, poskytuje t&yacute;m Prev&aacute;dzkovateľovi nasleduj&uacute;ce pr&aacute;va k
                    t&yacute;mto v&yacute;sledkom jeho činnosti a to aj vtedy, pokiaľ s&uacute; dielom v zmysle
                    z&aacute;kona č. 185/2015 Z.z. Autorsk&yacute; z&aacute;kon v
                    znen&iacute; neskor&scaron;&iacute;ch predpisov (ďalej len &bdquo;
                    <strong>Autorsk&yacute; z&aacute;kon</strong>&ldquo;): použ&iacute;vať, upravovať,
                    kop&iacute;rovať, distribuovať, odovzd&aacute;vať, verejne vystavovať, verejne vykon&aacute;vať,
                    reprodukovať, publikovať, sublicencovať, pren&aacute;&scaron;ať alebo odovzd&aacute;vať
                    ak&eacute;koľvek komunik&aacute;cie tohto typu a vytv&aacute;rať z nich odvoden&eacute; diela,
                    sublicencovať tretej strane neobmedzen&eacute; pr&aacute;va k vykon&aacute;vaniu
                    ktor&yacute;chkoľvek predch&aacute;dzaj&uacute;cich pr&aacute;v udelen&yacute;ch vo vzťahu k
                    t&yacute;mto Pr&iacute;spevkom. Na z&aacute;klade odovzdania t&yacute;chto pr&iacute;spevkov
                    nevznik&aacute; Prev&aacute;dzkovateľovi povinnosť tieto pr&iacute;spevky akokoľvek
                    použ&iacute;vať a je opr&aacute;vnen&yacute; tieto pr&iacute;spevky kdekoľvek na z&aacute;klade
                    vlastn&eacute;ho uv&aacute;ženia odstr&aacute;niť z webov&yacute;ch str&aacute;nok. Za
                    poskytnutie opr&aacute;vnenia Prev&aacute;dzkovateľa k pr&iacute;spevkom
                    nen&aacute;lež&iacute; Použ&iacute;vateľovi odplata. Ak d&ocirc;jde
                    k&nbsp;zru&scaron;eniu &uacute;čtu Použ&iacute;vateľa, určit&eacute; Pr&iacute;spevky sa
                    m&ocirc;žu na Port&aacute;li zachovať, av&scaron;ak nie v&nbsp;stave, na z&aacute;klade
                    ktor&eacute;ho by bolo možn&eacute; Použ&iacute;vateľa identifikovať.
                </p>
                <p id="jump3" style={{fontFamily: 'OpenSansBold'}} className='text-primary'>
                    <strong>
                        III. Ochrana osobn&yacute;ch &uacute;dajov a s&uacute;kromia
                    </strong>
                </p>
                <p>Použ&iacute;vateľ poskytuje dobrovoľne Prev&aacute;dzkovateľovi osobn&eacute; &uacute;daje podľa
                    Nariadenia Eur&oacute;pskeho parlamentu a Rady (E&Uacute;) 2016/679 z 27. apr&iacute;la 2016 o
                    ochrane fyzick&yacute;ch os&ocirc;b pri sprac&uacute;van&iacute; osobn&yacute;ch &uacute;dajov a
                    o voľnom pohybe tak&yacute;chto &uacute;dajov, ktor&yacute;m sa zru&scaron;uje smernica 95/46/ES
                    (v&scaron;eobecn&eacute; nariadenie o ochrane &uacute;dajov) (ďalej len &bdquo;<strong>nariadenie
                        GDPR</strong>&ldquo;) a z&aacute;kona č. 18/2018 Z.z. o ochrane
                    osobn&yacute;ch &uacute;dajov a o zmene a doplnen&iacute; niektor&yacute;ch z&aacute;konov
                    (ďalej len &bdquo;<strong>Z&aacute;kon o ochrane osobn&yacute;ch &uacute;dajov</strong>&ldquo;),
                    za &uacute;čelom poskytovania služieb na Port&aacute;li. Prev&aacute;dzkovateľ sprac&uacute;va
                    osobn&eacute; &uacute;daje Použ&iacute;vateľa najm&auml; na &uacute;čely inzercie a s t&yacute;m
                    s&uacute;visiacich služieb na z&aacute;klade zmluvy podľa čl. 6 ods. 1 p&iacute;sm. b)
                    nariadenia GDPR. Neposkytnutie osobn&yacute;ch &uacute;dajov Použ&iacute;vateľom
                    znamen&aacute; nemožnosť uzatvorenia zmluvn&eacute;ho vzťahu a poskytovania služieb,
                    ktor&eacute; s&uacute;visia s inzerciou.
                </p>
                <p>Prev&aacute;dzkovateľ Použ&iacute;vateľa informuje, že v zmysle &sect; 62 ods. 3 z&aacute;kona
                    č. 351/2011 Z.z. o elektronick&yacute;ch komunik&aacute;ci&aacute;ch v
                    znen&iacute; neskor&scaron;&iacute;ch pr&aacute;vnych predpisov (ďalej len &bdquo;
                    <strong>Z&aacute;kon o elektronick&yacute;ch komunik&aacute;ci&aacute;ch</strong>&ldquo;) ho
                    m&ocirc;že oslovovať s volaniami, so zasielan&iacute;m e-mailov a SMS spr&aacute;v na
                    kontaktn&eacute; adresy, ktor&eacute; uviedol pri registr&aacute;cii na &uacute;čely priameho
                    marketingu vlastn&yacute;ch podobn&yacute;ch tovarov a služieb. Volanie, e-mail alebo SMS
                    spr&aacute;va podľa predch&aacute;dzaj&uacute;cej vety m&ocirc;že mať informačn&yacute; alebo
                    reklamn&yacute; charakter a ich p&ocirc;vodcom je Prev&aacute;dzkovateľ. Prev&aacute;dzkovateľ
                    bude sprac&uacute;vať osobn&eacute; &uacute;daje po dobu trvania zmluvn&eacute;ho vzťahu.
                    Nes&uacute;hlas s volan&iacute;m, so zasielan&iacute;m t&yacute;chto e-mailov alebo SMS
                    spr&aacute;v m&ocirc;že Použ&iacute;vateľ kedykoľvek ozn&aacute;miť Prev&aacute;dzkovateľovi
                    zaslan&iacute;m e-mailu na adresu info@drazimto.sk (alebo na in&yacute; kontakt pokiaľ tak
                    určuj&uacute; tieto Podmienky) alebo jednoduch&yacute;m odhl&aacute;sen&iacute;m pri každom
                    doručen&iacute; e-mailovej spr&aacute;vy kliknut&iacute;m na odkaz na konci e-mailovej
                    spr&aacute;vy. Prev&aacute;dzkovateľ bude poskytovať osobn&eacute; &uacute;daje na &uacute;čely
                    priameho marketingu t&yacute;mto pr&iacute;jemcom:
                    Slovensk&aacute; obchodn&aacute; in&scaron;pekcia, s&uacute;d, org&aacute;ny činn&eacute; v
                    trestnom konan&iacute; a ďal&scaron;ie subjekty, ktor&yacute;m je Prev&aacute;dzkovateľ
                    povinn&yacute; poskytn&uacute;ť osobn&eacute; &uacute;daje na z&aacute;klade z&aacute;kona.
                </p>
                <p>Použ&iacute;vateľ si je vedom&yacute; toho, že ide o&nbsp;poskytovanie služieb iba osob&aacute;m
                    star&scaron;&iacute;m ako je 16 rokov. Použ&iacute;vateľ poskytuje svoje
                    osobn&eacute; &uacute;daje dobrovoľne a je si vedom&yacute; toho, že jeho
                    osobn&eacute; &uacute;daje m&ocirc;žu byť v s&uacute;vislosti so službami poskytovan&yacute;mi
                    Port&aacute;li spr&iacute;stupnen&eacute; pr&iacute;jemcom - tret&iacute;m
                    osob&aacute;m &ndash; in&yacute;m použ&iacute;vateľom za &uacute;čelom poskytovania služieb
                    Port&aacute;lu.
                </p>
                <p>Použ&iacute;vateľ, ktor&eacute;ho osobn&eacute; &uacute;daje
                    s&uacute; sprac&uacute;van&eacute; Prev&aacute;dzkovateľom, m&aacute; pr&aacute;vo
                    od&nbsp;Prev&aacute;dzkovateľa požadovať pr&iacute;stup k osobn&yacute;m &uacute;dajom,
                    ktor&eacute; sa ho t&yacute;kaj&uacute;, ako aj pr&aacute;vo na opravu, vymazanie alebo
                    obmedzenie sprac&uacute;vania t&yacute;chto &uacute;dajov. Použ&iacute;vateľ
                    m&aacute; s&uacute;časne pr&aacute;vo namietať proti sprac&uacute;vaniu
                    osobn&yacute;ch &uacute;dajov, ktor&eacute; sa ho t&yacute;kaj&uacute; a&nbsp;pr&aacute;vo
                    na&nbsp;prenosnosť t&yacute;chto &uacute;dajov. Ak s&uacute; žiadosti Použ&iacute;vateľa
                    v&nbsp;postaven&iacute; dotknutej osoby zjavne neopodstatnen&eacute; alebo neprimeran&eacute;,
                    najm&auml; pre&nbsp;ich opakuj&uacute;cu sa povahu, Prev&aacute;dzkovateľ m&ocirc;že požadovať
                    primeran&yacute; poplatok zohľadňuj&uacute;ci administrat&iacute;vne n&aacute;klady na
                    poskytnutie inform&aacute;ci&iacute; alebo odmietnuť konať na z&aacute;klade žiadosti.
                </p>
                <p>&Uacute;daje o Použ&iacute;vateľoch Port&aacute;lu použije Prev&aacute;dzkovateľ iba v medziach
                    Z&aacute;kona o ochrane osobn&yacute;ch &uacute;dajov. Prev&aacute;dzkovateľ uchov&aacute;va
                    už&iacute;vateľsk&eacute; meno (nick), heslo, meno a priezvisko, adresa, kraj, krajina,
                    e-mailov&uacute; adresu a telef&oacute;nne č&iacute;slo Použ&iacute;vateľa, n&aacute;zov
                    spoločnosti, IČO a adresu, resp. ďal&scaron;ie nepovinn&eacute; &uacute;daje a &uacute;daje,
                    ktor&eacute; si Použ&iacute;vateľ zadal s&aacute;m pri prid&aacute;van&iacute; inzer&aacute;tu,
                    resp. zverejnil v inzer&aacute;te alebo svojom použ&iacute;vateľskom konte.
                </p>
                <p>Viac inform&aacute;ci&iacute; o&nbsp;z&aacute;sad&aacute;ch sprac&uacute;vania
                    osobn&yacute;ch &uacute;dajov&nbsp;n&aacute;jdete v&nbsp;samostatnej sekcii GDPR.
                </p>
                <p id="jump4" style={{fontFamily: 'OpenSansBold'}} className='text-primary'>
                    <strong>
                        IV. Registr&aacute;cia Použ&iacute;vateľa
                    </strong>
                </p>
                <p>Využ&iacute;vanie časti služieb Port&aacute;lu nie je podmienen&eacute; registr&aacute;ciou.
                    Pridan&iacute;m inzer&aacute;tu zo strany prev&aacute;dzkovateľa port&aacute;lu sa automaticky
                    za&scaron;le autorovi inzer&aacute;tu odkaz na možnosť registr&aacute;cie sa a&nbsp;vytvorenia
                    použ&iacute;vateľsk&eacute;ho konta. Registr&aacute;cia poskytuje Použ&iacute;vateľovi
                    ďal&scaron;ie možnosti spr&aacute;vy inzer&aacute;tov ako aj možnosť využ&iacute;vať
                    ďal&scaron;ie služby Port&aacute;lu.
                </p>
                <p>Registrovan&iacute; použ&iacute;vatelia m&ocirc;žu pod svojim &uacute;čtom prid&aacute;vať a
                    editovať inzer&aacute;ty a využ&iacute;vať ďal&scaron;ie služby Port&aacute;lu.
                </p>
                <p>Neregistrovan&iacute; použ&iacute;vatelia nemaj&uacute; možnosť prid&aacute;vať
                    inzer&aacute;ty.
                </p>
                <p>Použ&iacute;vateľ registr&aacute;ciou v syst&eacute;me drazimto.sk d&aacute;va s&uacute;hlas na
                    uverejňovanie vlastn&yacute;ch kontaktov a identifikačn&yacute;ch &uacute;dajov pri svojich
                    inzer&aacute;toch, pr&iacute;padne pri využ&iacute;van&iacute; ďal&scaron;&iacute;ch Služieb.
                </p>
                <p>Použ&iacute;vateľ je zodpovedn&yacute; za bezpečn&eacute; uchovanie svojich
                    prihlasovac&iacute;ch a registračn&yacute;ch &uacute;dajov. Použ&iacute;vateľ je
                    zodpovedn&yacute; za v&scaron;etky aktivity vykonan&eacute; prostredn&iacute;ctvom svojho
                    použ&iacute;vateľsk&eacute;ho konta.
                </p>
                <p>Použ&iacute;vateľ sa zav&auml;zuje kontaktovať Prev&aacute;dzkovateľa ihneď po
                    zisten&iacute; neautorizovan&eacute;ho použitia svojho použ&iacute;vateľsk&eacute;ho konta a
                    svojich &uacute;dajov.
                </p>
                <p id="jump5" style={{fontFamily: 'OpenSansBold'}} className='text-primary'>
                    <strong>
                        V. &Uacute;prava &uacute;dajov inzerenta
                    </strong>
                </p>
                <p>Použ&iacute;vateľ sa zav&auml;zuje udržiavať svoje kontaktn&eacute; &uacute;daje
                    pravdiv&eacute;, presn&eacute; a aktu&aacute;lne počas celej doby využ&iacute;vania služieb
                    Port&aacute;lu, v opačnom pr&iacute;pade si Prev&aacute;dzkovateľ vyhradzuje pr&aacute;vo zmazať
                    ponuku Použ&iacute;vateľa, a to bez nutnosti ozn&aacute;menia tejto skutočnosti
                    Použ&iacute;vateľovi.
                </p>
                <p>V pr&iacute;pade nevyplnenia pravdiv&yacute;ch &uacute;dajov m&ocirc;že byť konto
                    Použ&iacute;vateľa pozastaven&eacute; pr&iacute;padne &uacute;plne zru&scaron;en&eacute;.
                </p>
                <p>V pr&iacute;pade, že sa zmenili skutočnosti uv&aacute;dzan&eacute; v registračnom
                    formul&aacute;ri drazimto.sk, je možn&eacute; ich kedykoľvek upraviť, a to v
                    sekcii &bdquo;M&ocirc;j profil&ldquo; už&iacute;vateľsk&eacute;ho menu.
                </p>
                <p>V sekcii Upraviť &uacute;daje sa ukladaj&uacute; z&aacute;kladn&eacute; &uacute;daje
                    o&nbsp;Použ&iacute;vateľovi. Je možn&eacute; nastaviť, aktivovať (deaktivovať) využ&iacute;vanie
                    e-mailovej notifik&aacute;cie spr&aacute;v.
                </p>
                <p id="jump6" style={{fontFamily: 'OpenSansBold'}} className='text-primary'>
                    <strong>
                        VI. Spr&aacute;vanie sa Použ&iacute;vateľov
                    </strong>
                </p>
                <p>Použ&iacute;vateľ plne zodpoved&aacute; za obsah vlastn&yacute;ch uverejnen&yacute;ch
                    pon&uacute;k (inzer&aacute;tov), a to vr&aacute;tane textu a fotografii. Použ&iacute;vateľ je
                    pri prid&aacute;van&iacute; ponuky povinn&yacute; zabezpečiť, aby jej obsah bol v s&uacute;lade
                    s legislat&iacute;vnymi normami SR, najm&auml;:<br/> a) m&ocirc;že inzerovať iba predmety,
                    ktor&yacute;ch predaj je v Slovenskej republike dovolen&yacute; a ich nadobudnutie bolo v
                    s&uacute;lade so z&aacute;konmi SR a Použ&iacute;vateľ k nim
                    m&aacute; vysporiadan&eacute; v&scaron;etky pr&aacute;va (vr&aacute;tane autorsk&yacute;ch
                    pr&aacute;v),<br/> b) je povinn&yacute; obsah vlastnej ponuky upraviť tak, aby t&yacute;m
                    nezasahoval do pr&aacute;v in&yacute;ch Použ&iacute;vateľov a žiadnym sp&ocirc;sobom
                    neznevažoval ich dobr&eacute; meno, nepo&scaron;kodzoval n&aacute;rodn&eacute;,
                    etnick&eacute; či n&aacute;božensk&eacute; c&iacute;tenie a bol v s&uacute;lade so
                    Z&aacute;konom o &scaron;t&aacute;tnom jazyku SR,<br/> c) neuv&aacute;dzať nepravdiv&eacute;,
                    nedoložen&eacute;, ne&uacute;pln&eacute;, nepresn&eacute;, nejasn&eacute; alebo
                    dvojzmyseln&eacute; &uacute;daje ani zamlčať &uacute;daje o vlastnostiach inzerovan&eacute;ho
                    tovaru alebo dodac&iacute;ch podmienkach,<br/> d) nesmie vo vlastnej ponuke pon&uacute;kať
                    in&eacute; produkty alebo služby, okrem t&yacute;ch ktor&eacute; priamo s&uacute;visia s danou
                    ponukou,<br/> e) nesmie niekoľkokr&aacute;t uverejniť t&uacute; ist&uacute; ponuku, pokiaľ by
                    p&ocirc;vodn&aacute; ponuka bola st&aacute;le platn&aacute;,<br/> f) mus&iacute; vždy inzerovať
                    konkr&eacute;tny produkt, nie svoju podnikateľsk&uacute; činnosť ako celok, okrem
                    pr&iacute;padov platen&yacute;ch reklamn&yacute;ch produktov, najm&auml; banerovej reklamy,<br/>
                </p>
                <p>Prev&aacute;dzkovateľ je opr&aacute;vnen&yacute; zmazať ponuku predajcu v pr&iacute;pade, že mu
                    predajca nedok&aacute;že preuk&aacute;zať svoju identitu alebo vlastn&iacute;cky vzťah k
                    inzerovanej veci. a) Pre overenie identity sl&uacute;ži platn&eacute; telef&oacute;nne
                    č&iacute;slo, na ktorom je možn&eacute; predajcu kontaktovať. b) Vlastn&iacute;cky vzťah je
                    možn&eacute; preuk&aacute;zať napr. zaslan&iacute;m scan-u dokladu, z&aacute;ručn&eacute;ho
                    listu, pr&iacute;padne in&yacute;ch nadob&uacute;dac&iacute;ch dokumentov.
                </p>
                <p>Prev&aacute;dzkovateľ je opr&aacute;vnen&yacute; zmazať ponuku predajcu bez upozornenia aj
                    v&nbsp;pr&iacute;pade, ak jeden Použ&iacute;vateľ prid&aacute;va inzer&aacute;ty pod
                    viacer&yacute;mi kontami na drazimto.sk
                </p>
                <p>Použ&iacute;vateľ sa zav&auml;zuje, že jeho činnosť na Port&aacute;li a n&iacute;m
                    prid&aacute;van&eacute; inzer&aacute;ty bud&uacute; v s&uacute;lade s Podmienkami.
                </p>
                <p>Použ&iacute;vateľ nie je opr&aacute;vnen&yacute; využ&iacute;vať služby Port&aacute;lu k
                    ak&yacute;mkoľvek &uacute;čelom odporuj&uacute;cim z&aacute;konom alebo t&yacute;mto Podmienkam.
                    Pož&iacute;vateľ nesmie služby Port&aacute;lu využ&iacute;vať sp&ocirc;sobom, ktor&yacute; by
                    mohol po&scaron;kodiť, znemožniť, preťažiť alebo zhor&scaron;iť funkciu serverov
                    prev&aacute;dzkovan&yacute;ch Prev&aacute;dzkovateľom alebo jeho partnermi alebo ru&scaron;iť
                    použ&iacute;vanie t&yacute;chto serverov alebo služieb Prev&aacute;dzkovateľa in&yacute;mi
                    osobami. Použ&iacute;vateľ nesmie ak&yacute;mkoľvek sp&ocirc;sobom z&iacute;skavať ani sa
                    pok&uacute;&scaron;ať z&iacute;skavať ak&eacute;koľvek materi&aacute;ly či inform&aacute;cie
                    t&yacute;kaj&uacute;ce sa služieb Port&aacute;lu, ktor&eacute; nie s&uacute; (neboli) verejne
                    spr&iacute;stupnen&eacute; alebo poskytnut&eacute; prostredn&iacute;ctvom
                    Prev&aacute;dzkovateľa.
                </p>
                <p>V pr&iacute;pade, ak inzerent pon&uacute;ka svoje produkty, tovary a/alebo služby v r&aacute;mci
                    svojej podnikateľskej činnosti, je povinn&yacute; inzerovať tak&eacute;to produkty, tovary
                    a/alebo služby v&yacute;lučne prostredn&iacute;ctvom svojho konta.
                </p>
                <p>Použ&iacute;vateľ s&aacute;m zodpoved&aacute; za splnenie z&aacute;konn&yacute;ch
                    povinnost&iacute;, vypl&yacute;vaj&uacute;cich z predaja a k&uacute;py tovaru cez Port&aacute;l,
                    najm&auml; pokiaľ ide o z&iacute;skanie podnikateľsk&eacute;ho opr&aacute;vnenia pokiaľ je to
                    potrebn&eacute;, vedenie &uacute;čtovn&iacute;ctva, alebo o priznanie a odvedenie
                    pr&iacute;slu&scaron;n&yacute;ch dan&iacute; a odvodov, ochranu spotrebiteľa, reklamu, a
                    in&eacute; z&aacute;konn&eacute; požiadavky. Prev&aacute;dzkovateľ nevystupuje v poz&iacute;cii
                    predajcu alebo distrib&uacute;tora inzerovan&eacute;ho tovaru.
                </p>
                <p>Použ&iacute;vateľ je povinn&yacute; postupovať tak, aby nedo&scaron;lo
                    k&nbsp;neopr&aacute;vnen&eacute;mu vstupu do jeho použ&iacute;vateľsk&eacute;ho konta treťou
                    stranou, ktor&aacute; na vstup do konta Použ&iacute;vateľa nem&aacute; opr&aacute;vnenie. Ďalej
                    je Použ&iacute;vateľ povinn&yacute; vykonať v&scaron;etky bezpečnostn&eacute; opatrenia,
                    potrebn&eacute; najm&auml; na zamedzenie &uacute;niku prihlasovacieho mena a&nbsp;k&nbsp;nemu
                    prisl&uacute;chaj&uacute;ceho&nbsp;hesla. Použ&iacute;vateľ je povinn&yacute; nahl&aacute;siť
                    Prev&aacute;dzkovateľovi každ&eacute; zneužitie jeho použ&iacute;vateľsk&eacute;ho konta.
                    Použ&iacute;vateľ zodpoved&aacute; za &scaron;kodu, v&nbsp;pr&iacute;pade, ak
                    v&nbsp;d&ocirc;sledku poru&scaron;enia povinnost&iacute; Použ&iacute;vateľa stanoven&yacute;ch
                    v&nbsp;tomto bode vznikne Prev&aacute;dzkovateľovi, in&eacute;mu Použ&iacute;vateľovi alebo
                    tretej osobe &scaron;koda.
                </p>
                <p>V pr&iacute;pade, ak inzerent pri prid&aacute;van&iacute; inzer&aacute;tu alebo pridan&iacute;m
                    inzer&aacute;tu alebo v&nbsp;r&aacute;mci spr&aacute;vy svojho inzer&aacute;tu,
                    poru&scaron;&iacute; ak&uacute;koľvek povinnosť stanoven&uacute; v&nbsp;t&yacute;chto
                    Podmienkach a nevykon&aacute; n&aacute;pravu v&nbsp;lehote určenej
                    v&nbsp;upozornen&iacute; Prev&aacute;dzkovateľa, m&aacute; Prev&aacute;dzkovateľ n&aacute;rok na
                    zmluvn&uacute; pokutu vo v&yacute;&scaron;ke sumy rovnaj&uacute;cej sa alikvotnej časti ceny
                    inzercie vr&aacute;tane pr&iacute;padnej ceny nespotrebovanej doplnkovej služby (ak bola
                    predplaten&aacute; vo vzťahu k&nbsp;dan&eacute;mu inzer&aacute;tu) ku dňu vzniku n&aacute;roku
                    Prev&aacute;dzkovateľa na zmluvn&uacute; pokutu podľa tohto bodu Podmienok.
                </p>
                <p id="jump7" style={{fontFamily: 'OpenSansBold'}} className='text-primary'>
                    <strong>
                        VII. Prid&aacute;vanie inzer&aacute;tov
                    </strong>
                </p>
                <p>Prid&aacute;vanie inzer&aacute;tov sa uskutočňuje tromi z&aacute;kladn&yacute;mi sp&ocirc;sobmi,
                    a to:
                    <span>Manu&aacute;lne pridanie inzer&aacute;tov cez rozhranie &bdquo;Pridať
                        inzer&aacute;t&ldquo; na drazimto.sk,
                    </span>
                    <span>Automatick&yacute;m importom z&nbsp;verejne dostupn&yacute;ch zdrojov.</span>
                </p>
                <p>
                    <strong id="jump8" style={{fontFamily: 'OpenSansBold'}} className='text-primary'>
                        VIII. Manu&aacute;lne pridanie
                    </strong>
                    <br/> Z&aacute;ložka &bdquo;Pridať inzer&aacute;t&ldquo; na
                    hornej li&scaron;te odkazuje na jednoduch&yacute; formul&aacute;r pre pridanie inzer&aacute;tu.
                    Potrebn&eacute; je vyplniť v&scaron;etky povinn&eacute; položky (označen&eacute; grafick&yacute;m
                    symbolom *). Inzer&aacute;t je automaticky platn&yacute; po dobu max. 30 dn&iacute;, pokiaľ ho
                    samotn&yacute; Použ&iacute;vateľ nevymaže alebo nedeaktivuje.<br/> <br/>
                    <strong id="jump9" style={{fontFamily: 'OpenSansBold'}} className='text-primary'>
                        IX. Automatick&yacute; import
                    </strong>
                    <br/> <br/> Automatick&yacute; import je
                    uskutočňovan&yacute; každ&yacute;ch 24 hod&iacute;n &ndash; kv&ocirc;li aktu&aacute;lnosti ponuky,
                    resp. v&nbsp;inom čo najkrat&scaron;om intervale. Tento import uskutočňuje Prev&aacute;dzkovateľ
                    samostatne a&nbsp;na vlastn&eacute; n&aacute;klady.<br/>
                    <br/> Prid&aacute;van&eacute; inzer&aacute;ty m&ocirc;žu prech&aacute;dzať kontrolou
                    administr&aacute;tora, ktor&yacute; kontroluje dodržiavanie Podmienok inzercie.
                    Prev&aacute;dzkovateľ inzertn&eacute;ho Port&aacute;lu si vyhradzuje pr&aacute;vo odstr&aacute;nenia
                    inzercie, ktor&aacute; bude v rozpore so z&aacute;konmi Slovenskej republiky, dobr&yacute;mi mravmi
                    alebo by boli inak nevhodn&eacute; pre zverejnenie a vyhradzuje si pr&aacute;vo zabr&aacute;niť
                    pr&iacute;stupu Použ&iacute;vateľom, ktor&iacute; bud&uacute; z&aacute;kony a dobr&eacute; mravy
                    poru&scaron;ovať. Prev&aacute;dzkovateľ si vyhradzuje pr&aacute;vo kedykoľvek zmeniť zaradenie
                    inzer&aacute;tu do kateg&oacute;rie, ktor&aacute; je vhodnej&scaron;ia pre
                    dan&yacute; inzer&aacute;t a to bez ozn&aacute;menia Použ&iacute;vateľovi. Inzer&aacute;ty,
                    ktor&eacute; t&yacute;mto Podmienkam inzercie odporuj&uacute; m&ocirc;žu byť bez ozn&aacute;menia
                    Použ&iacute;vateľovi zmenen&eacute; alebo odstr&aacute;nen&eacute;, bez ohľadu na
                    spoplatnen&eacute; služby. V pr&iacute;pade, ak bud&uacute; inzer&aacute;ty Použ&iacute;vateľa
                    odstr&aacute;nen&eacute; z d&ocirc;vodu ich rozporu s Podmienkami, považuje sa konanie
                    Použ&iacute;vateľa za poru&scaron;enie jeho povinnosti konať v s&uacute;lade s Podmienkami; v takom
                    pr&iacute;pade sa poskytnut&aacute; služba považuje za ukončen&uacute; a poskytnut&uacute; v celom
                    rozsahu.</p>

                <p>Na Port&aacute;li je zak&aacute;zan&eacute; inzerovať:<br/> <br/> a) inzer&aacute;ty,
                    ktor&yacute;ch obsah poru&scaron;uje z&aacute;kony SR, tak&eacute;to pr&iacute;pady
                    s&uacute; hl&aacute;sen&eacute; pol&iacute;cii SR<br/> b) inzer&aacute;ty v inom ako slovenskom
                    alebo českom jazyku<br/> c) ponuku služieb alebo predmet z&aacute;robkovej činnosti<br/> d)
                    hromadn&uacute; ponuku (v 1 inzer&aacute;te je možn&eacute; inzerovať len 1 konkr&eacute;tny
                    produkt)<br/></p>
                <p>Ďalej je zak&aacute;zan&eacute;:<br/> <br/> a) vytv&aacute;rať duplicitn&yacute; obsah tzn.
                    inzerovať rovnak&yacute; produkt vo viacer&yacute;ch kateg&oacute;ri&aacute;ch,
                    lokalit&aacute;ch atď.<br/> b) inzerovať v&scaron;eobecn&eacute; ponuky
                </p>
                <p>c) prid&aacute;vať inzer&aacute;ty s nere&aacute;lnou cenou<br/> d) p&iacute;sať
                    cel&eacute; slov&aacute; veľk&yacute;mi p&iacute;smenami s v&yacute;nimkou skratiek,<br/> e) do
                    n&aacute;zvu alebo textu inzer&aacute;tu vkladať kontaktn&eacute; &uacute;daje (telef&oacute;nne
                    č&iacute;slo, emailov&uacute; adresu www adresu atď.) alebo &scaron;peci&aacute;lne znaky
                    (@*#$%^&amp;!?)<br/> f) prid&aacute;vať or&aacute;movan&eacute; a inak
                    zv&yacute;raznen&eacute; fotografie<br/> g) uv&aacute;dzať audiotextov&eacute; č&iacute;sla a
                    sms s osobitnou tarifik&aacute;ciou<br/> h) použ&iacute;vať superlat&iacute;vy a
                    neoveriteľn&eacute; tvrdenia (najlep&scaron;ia kvalita, najnov&scaron;ia technol&oacute;gia,
                    najv&auml;č&scaron;ia ponuka, ...)<br/></p>
                <p id="jump10" style={{fontFamily: 'OpenSansBold'}} className='text-primary'>
                    <strong>
                        X. Administr&aacute;cia inzer&aacute;tov
                    </strong>
                </p>
                <p>V syst&eacute;me drazimto.sk m&ocirc;že Použ&iacute;vateľ svoje inzer&aacute;ty meniť, dopĺňať alebo
                    vymazať kedykoľvek počas ich platnosti. V&scaron;etky &uacute;kony možno robiť po
                    prihl&aacute;sen&iacute; do konta Použ&iacute;vateľa.</p>
                <p id="jump11" style={{fontFamily: 'OpenSansBold'}} className='text-primary'>
                    <strong>
                        XI. Platen&eacute; služby Port&aacute;lu &ndash; zverejnenie ponuky/inzer&aacute;tu
                        a&nbsp;doplnkov&eacute; služby
                    </strong>
                </p>
                <p>Služba Port&aacute;lu spoč&iacute;vaj&uacute;ca v zverejnen&iacute; ponuky/inzer&aacute;tu na
                    Port&aacute;li je pre S&uacute;kromn&yacute;ch inzerentov čiastočne spoplatnen&aacute;.
                </p>
                <p>&Uacute;hrada ceny za služby Port&aacute;lu m&ocirc;žu Použ&iacute;vatelia uskutočniť
                    sp&ocirc;sobom podľa charakteru zvolenej služby prostredn&iacute;ctvom platobnou kartou alebo
                    prevodom na &uacute;čet &uacute;hradou vystavenej fakt&uacute;ry od Prev&aacute;dzkovateľa.
                </p>
                <p>Služby Port&aacute;lu pre S&uacute;kromn&yacute;ch aj Firemn&yacute;ch inzerentov
                    s&uacute; spoplatnen&eacute;.
                </p>
                <p>Služby zverejnenia inzer&aacute;tu<br/> <br/>
                    <strong id="jump11" style={{fontFamily: 'OpenSansBold'}} className='text-primary'>
                        XII. Služba &ldquo;Basic&rdquo;
                    </strong><br/>
                    <br/> Ide o službu, pri ktorej má Používateľ po úhrade možnosť pridať k vybranému inzerátu
                    fotografie a príslušné dokumenty na portáli drazimto.sk. Táto možnosť je používateľovi sprístupnená
                    po úhrade automaticky bez nutnosti dodatočnej akcie používateľa.

                    Pre sprístupnenie funkcií služby Basic je potrebné uhradiť stanovený poplatok platobnou kartou
                    prostredníctvom platobného terminálu ThePay alebo uhradiť vystavenú faktúru najneskôr v deň
                    splatnosti. Aktivácia inzerátu prostredníctvom ThePay prebehne ihneď po zaplatení inzerátu. Po
                    úhrade, najneskôr do 2 pracovných dní, bude na emailovú adresu Používateľovi doručený doklad o
                    platbe/faktúra spolu s potvrdením aktivácie vybraného balíka a dátum opakovania predplatného. Cena
                    inzercie pre inzertný balík Basic pre Súkromných inzerentov je spoplatnená sumou 20,00 € s DPH/
                    inzerát, na predplatené obdobie zverejnenia inzerátu v trvaní 30 dní. Služba sa po uplynutí
                    základných 30 dní automaticky predlžuje o ďalších 30 dní a Používateľ súhlasí s predĺžením platnosti
                    inzerátu o ďalších 30 dní a súhlasí aj so spoplatnením balíka na ďalšie obdobie. Používateľ má
                    možnosť sám zrušiť uhradený balík kliknutím na tlačidlo „Zrušiť balík“, ktoré sa nachádza pri každom
                    inzeráte pre ktorý je aktívny (uhradený) balík inzercie. Zrušením sa zastaví spoplatňovanie balíka a
                    poskytovanie služby
                    <br/> <br/> <strong id="jump13"
                                        style={{fontFamily: 'OpenSansBold'}}
                                        className='text-primary'> XIII.
                        Služba &ldquo;Standard&rdquo;</strong><br/> <br/> Ide o službu, pri ktorej má Používateľ po
                    úhrade možnosť pridať k vybranému inzerátu fotografie a príslušné dokumenty na portáli drazimto.sk.
                    Zároveň je k inzerátu aktivovaná služba Topované na obdobie 30 dní. Inzerát je zaradený do slidera
                    na Topované na hlavnej stránke drazimto.sk Táto možnosť je používateľovi sprístupnená po úhrade
                    automaticky bez nutnosti dodatočnej akcie používateľa.
                </p>
                <p>Pre sprístupnenie funkcií služby Standard je potrebné uhradiť stanovený poplatok platobnou kartou
                    prostredníctvom platobného terminálu ThePay alebo uhradiť vystavenú faktúru najneskôr v deň
                    splatnosti. Aktivácia inzerátu prostredníctvom ThePay prebehne ihneď po zaplatení inzerátu. Po
                    úhrade, najneskôr do 2 pracovných dní, bude na emailovú adresu Používateľovi doručený doklad o
                    platbe/faktúra spolu s potvrdením aktivácie vybraného balíka a dátum opakovania predplatného. Cena
                    inzercie pre inzertný balík Standard pre Súkromných inzerentov je spoplatnená sumou 30,00 € s DPH/
                    inzerát, na predplatené obdobie zverejnenia inzerátu v trvaní 30 dní. Služba sa po uplynutí
                    základných 30 dní automaticky predlžuje o ďalších 30 dní a Používateľ súhlasí s predĺžením platnosti
                    inzerátu o ďalších 30 dní a súhlasí aj so spoplatnením balíka na ďalšie obdobie. Používateľ má
                    možnosť sám zrušiť uhradený balík kliknutím na tlačidlo „Zrušiť balík“, ktoré sa nachádza pri každom
                    inzeráte pre ktorý je aktívny (uhradený) balík inzercie. Zrušením sa zastaví spoplatňovanie balíka a
                    poskytovanie služby.<br/> <br/>
                    <strong id="jump14" style={{fontFamily: 'OpenSansBold'}} className='text-primary'> XIV.
                        Služba &ldquo;Na
                        mieru&rdquo;</strong><br/> <br/> Ide o službu, pri ktorej
                    m&aacute; Použ&iacute;vateľ po &uacute;hrade možnosť pridať k&nbsp;vybran&eacute;mu inzer&aacute;tu
                    fotografie a&nbsp;pr&iacute;slu&scaron;n&eacute; dokumenty na port&aacute;li drazimto.sk.
                    Z&aacute;roveň je k&nbsp;inzer&aacute;tu aktivovan&aacute; služba Topovan&eacute; na obdobie 30
                    dn&iacute;. Inzer&aacute;t je zaraden&yacute; do slidera na Topovan&eacute; na hlavnej
                    str&aacute;nke drazimto.sk. Z&aacute;roveň m&aacute; použ&iacute;vateľ na v&yacute;ber r&ocirc;zne
                    reklamn&eacute; a&nbsp;inzertn&eacute; kan&aacute;ly, ktor&eacute; bud&uacute; použit&eacute; na
                    ďal&scaron;ie zverejnenie vybran&eacute;ho inzer&aacute;tu s&nbsp;cieľom distrib&uacute;cie
                    medzi &scaron;ir&scaron;ie publikum.</p>
                <p>Cena bal&iacute;ka Na mieru je stanoven&aacute; podľa rozsahu požadovan&yacute;ch služieb
                    dostupn&yacute;ch v&nbsp;bal&iacute;ku Na mieru a&nbsp;vopred
                    ozn&aacute;men&aacute; použ&iacute;vateľovi. Pre spr&iacute;stupnenie funkci&iacute; a&nbsp;služieb
                    bal&iacute;ka Na mieru je potrebn&eacute; uhradiť fakt&uacute;ru najnesk&ocirc;r v&nbsp;deň
                    splatnosti.</p>
                <p>Daňov&yacute; doklad za platbu kartou je možn&eacute; si vyžiadať najnesk&ocirc;r do 5
                    dn&iacute; e-mailom na <a href="mailto:info@drazimto.sk"><strong>info@drazimto.sk</strong></a>.<br/>
                    <br/></p>
                <p id="jump15" style={{fontFamily: 'OpenSansBold'}} className='text-primary'>
                    <strong>
                        XV. Zru&scaron;enie konta
                    </strong>
                </p>
                <p>Použ&iacute;vateľ s&uacute;hlas&iacute;, že Prev&aacute;dzkovateľ m&ocirc;že jeho
                    použ&iacute;vateľsk&eacute; konto kedykoľvek v s&uacute;lade s Podmienkami zru&scaron;iť alebo
                    nechať zru&scaron;iť, a to aj bez predch&aacute;dzaj&uacute;ceho upozornenia, pokiaľ tieto
                    Podmienky neustanovuj&uacute; inak (čl&aacute;nok XIV. Podmienok).<br/>
                    <br/> Prev&aacute;dzkovateľ tak m&ocirc;že učiniť najm&auml; na z&aacute;klade:<br/> a)
                    poru&scaron;enia z&aacute;konov a pr&aacute;vnych noriem SR a Eur&oacute;pskej &uacute;nie
                    Použ&iacute;vateľom;<br/> b) poru&scaron;enia t&yacute;chto Podmienok;<br/> c) absencie aktivity
                    Použ&iacute;vateľsk&eacute;ho konta po prvej registr&aacute;cii (v pr&iacute;pade, ak nebolo
                    Použ&iacute;vateľsk&eacute; konto použit&eacute; ani raz počas 21 dn&iacute; od prv&eacute;ho
                    odhl&aacute;senia po novej registr&aacute;cii konta);<br/> d) absencie verifik&aacute;cie
                    Použ&iacute;vateľsk&eacute;ho konta prostredn&iacute;ctvom telef&oacute;nneho č&iacute;sla
                    Použ&iacute;vateľa (v pr&iacute;pade, ak Použ&iacute;vateľ neover&iacute; svoje
                    nov&eacute; konto prostredn&iacute;ctvom telef&oacute;nneho č&iacute;sla, nie je
                    možn&eacute; vykonať ani ďal&scaron;ie prihl&aacute;senie sa do konta);<br/> e) dlhodobej
                    absencie aktivity Použ&iacute;vateľsk&eacute;ho konta (v pr&iacute;pade, ak nebolo
                    Použ&iacute;vateľsk&eacute; konto použit&eacute; počas 3 rokov od posledn&eacute;ho
                    odhl&aacute;senia);<br/> f) na vlastn&uacute; žiadosť Použ&iacute;vateľa sp&ocirc;sobom
                    uveden&yacute;m priamo v profile Použ&iacute;vateľa;<br/> g) z d&ocirc;vodu po&scaron;kodzovania
                    Prev&aacute;dzkovateľa alebo in&eacute;ho Použ&iacute;vateľa;<br/> h) Použ&iacute;vateľ
                    neuhradil fakt&uacute;ru za služby Port&aacute;lu v lehote splatnosti;
                </p>
                <p>Prev&aacute;dzkovateľ si vyhradzuje pr&aacute;vo kedykoľvek odoprieť Použ&iacute;vateľovi
                    pr&iacute;stup na str&aacute;nky, alebo odstr&aacute;niť ak&yacute;koľvek obsah dan&eacute;ho
                    Použ&iacute;vateľa (inzer&aacute;ty, hodnotenia, atď.) alebo jeho konto zo syst&eacute;mu, a to
                    hlavne z d&ocirc;vodu po&scaron;kodzovania Poskytovateľa, in&eacute;ho Použ&iacute;vateľa alebo
                    poru&scaron;enia Podmienok.
                </p>
                <p>Prev&aacute;dzkovateľ na žiadosť Použ&iacute;vateľa, ktor&yacute; si riadne vysporiadal svoje
                    z&aacute;v&auml;zky voči Prev&aacute;dzkovateľovi, obnov&iacute; už&iacute;vateľsk&eacute; konto
                    deaktivovan&eacute; podľa ods. 2 tohto ustanovenia. Za op&auml;tovn&uacute; aktiv&aacute;ciu
                    použ&iacute;vateľsk&eacute;ho konta je Prev&aacute;dzkovateľ
                    opr&aacute;vnen&yacute; &uacute;čtovať Použ&iacute;vateľovi, spracovateľsk&yacute; poplatok v
                    sume 54 EUR bez DPH &ndash; resp. vo v&yacute;&scaron;ke najniž&scaron;ieho mesačn&eacute;ho
                    variantu.
                </p>
                <p id="jump16" style={{fontFamily: 'OpenSansBold'}} className='text-primary'>
                    <strong>
                        XVI. Zodpovednosť za &scaron;kodu
                    </strong>
                </p>
                <p>Použ&iacute;vateľ si je vedom&yacute; toho, že využ&iacute;va služby Port&aacute;lu
                    v&yacute;hradne na vlastn&eacute; riziko. Prev&aacute;dzkovateľ nezodpoved&aacute; za činnosť
                    Použ&iacute;vateľov služieb Port&aacute;lu, ani za sp&ocirc;sob ak&yacute;m služby
                    Port&aacute;lu využ&iacute;vaj&uacute;.
                </p>
                <p>Prev&aacute;dzkovateľ nenesie žiadnu zodpovednosť za &scaron;kody, ktor&eacute; by vznikli
                    Použ&iacute;vateľom alebo tret&iacute;m osob&aacute;m priamo, nepriamo či n&aacute;hodne v
                    d&ocirc;sledku alebo v s&uacute;vislosti s využ&iacute;van&iacute;m služieb Port&aacute;lu.
                </p>
                <p>Prev&aacute;dzkovateľ nezodpoved&aacute; za &scaron;kody, ktor&eacute; by vznikli
                    Použ&iacute;vateľom alebo tret&iacute;m osob&aacute;m v d&ocirc;sledku nemožnosti
                    využ&iacute;vania služieb Port&aacute;lu alebo v priamej či nepriamej s&uacute;vislosti s touto
                    skutočnosťou.
                </p>
                <p>Prev&aacute;dzkovateľ Port&aacute;lu nenesie zodpovednosť za obsah uverejnen&yacute;ch
                    inzer&aacute;tov, a to vr&aacute;tane textu aj fotografi&iacute;.
                </p>
                <p>Prev&aacute;dzkovateľ neruč&iacute; za nepretržit&uacute; funkčnosť Port&aacute;lu, jeho
                    bezchybn&uacute; činnosť a zabezpečenie. Z&aacute;roveň m&aacute; pr&aacute;vo odstaviť
                    Port&aacute;l bez ak&eacute;hokoľvek upozornenia a uvedenia d&ocirc;vodu.
                </p>
                <p>Prev&aacute;dzkovateľ nezodpoved&aacute; za ak&uacute;koľvek &scaron;kodu, ktor&aacute; by mohla
                    byť Použ&iacute;vateľovi sp&ocirc;soben&aacute; v s&uacute;vislosti s použ&iacute;van&iacute;m
                    služieb na Port&aacute;li, ako aj sp&ocirc;soben&yacute;ch jeho nefunkčnosťou, chybovosťou,
                    činnosťou použ&iacute;vateľov či z in&yacute;ch d&ocirc;vodov.
                </p>
                <p id="jump17" style={{fontFamily: 'OpenSansBold'}} className='text-primary'>
                    <strong>
                        XVII. Zodpovednosť za vady a Reklamačn&yacute; poriadok
                    </strong>
                </p>
                <p>Prev&aacute;dzkovateľ zodpoved&aacute; za to, že platen&eacute; služby Port&aacute;lu
                    bud&uacute; poskytovan&eacute; riadne a včas. V pr&iacute;pade, vyskytnutia sa v&aacute;d
                    poskytovan&yacute;ch platen&yacute;ch služieb je Prev&aacute;dzkovateľ povinn&yacute; tieto vady
                    odstr&aacute;niť.
                </p>
                <p>Inform&aacute;cie o postupoch pri uplatňovan&iacute; a vybavovan&iacute; reklam&aacute;cii,
                    sťažnost&iacute; a podnetov Použ&iacute;vateľov s&uacute; uveden&eacute; v Reklamačnom poriadku.
                </p>
                <p>V pr&iacute;pade z&aacute;ujmu o reklam&aacute;ciu je Použ&iacute;vateľ
                    opr&aacute;vnen&yacute; p&iacute;somne kontaktovať Prev&aacute;dzkovateľa na e-mailovej adrese
                    info@drazimto.sk.
                </p>
                <p>Reklam&aacute;cia mus&iacute; obsahovať konkr&eacute;tny popis reklamovanej skutočnosti.</p>
                <p>Prev&aacute;dzkovateľ Port&aacute;lu je povinn&yacute; každ&uacute; reklam&aacute;ciu preveriť a
                    v pr&iacute;pade, že t&aacute;to je opodstatnen&aacute;, je povinn&yacute; podnikn&uacute;ť
                    kroky smeruj&uacute;ce k jej n&aacute;prave.
                </p>
                <p>Prev&aacute;dzkovateľ je tiež povinn&yacute; ozn&aacute;miť Použ&iacute;vateľovi svoje
                    stanovisko k predmetu reklam&aacute;cie a v pr&iacute;pade, že jej predmet pos&uacute;di ako
                    relevantn&yacute;, bude Použ&iacute;vateľa bez zbytočn&eacute;ho odkladu informovať o zisteniach
                    a krokoch smeruj&uacute;cich k jeho n&aacute;prave a reklam&aacute;ciu
                    vybav&iacute; bezodkladne, najnesk&ocirc;r v&scaron;ak do 30 dn&iacute; odo dňa doručenia
                    reklam&aacute;cie.
                </p>
                <p>Ak Použ&iacute;vateľ, ktor&yacute; je spotrebiteľ nie je spokojn&yacute; so sp&ocirc;sobom,
                    ktor&yacute;m Prev&aacute;dzkovateľ vybavil jeho reklam&aacute;ciu, alebo ak sa domnieva, že
                    Prev&aacute;dzkovateľ poru&scaron;il jeho pr&aacute;va, m&aacute; možnosť obr&aacute;tiť sa na
                    Prev&aacute;dzkovateľa so žiadosťou o&nbsp;n&aacute;pravu. Ak Prev&aacute;dzkovateľ na žiadosť
                    o&nbsp;n&aacute;pravu odpovie zamietavo alebo ak na ňu neodpovie do 30 dn&iacute; odo dňa jej
                    odoslania, m&aacute; Použ&iacute;vateľ pr&aacute;vo obr&aacute;tiť sa s cieľom ochrany svojich
                    spotrebiteľsk&yacute;ch pr&aacute;v na subjekt alternat&iacute;vneho rie&scaron;enia sporov
                    podľa z&aacute;kona č. &nbsp;391/2015 Z.z.&nbsp;o alternat&iacute;vnom
                    rie&scaron;en&iacute; spotrebiteľsk&yacute;ch sporov a o zmene a
                    doplnen&iacute; niektor&yacute;ch z&aacute;konov v&nbsp;platnom znen&iacute; (ďalej len &bdquo;
                    <strong>Z&aacute;kon o&nbsp;alternat&iacute;vnom
                        rie&scaron;en&iacute; sporov</strong>&ldquo;).&nbsp;Počas alternat&iacute;vneho
                    rie&scaron;enia sporov Použ&iacute;vateľ - spotrebiteľ spolupracuje so subjektom
                    alternat&iacute;vneho rie&scaron;enia sporov v z&aacute;ujme r&yacute;chleho vyrie&scaron;enia
                    sporu. N&aacute;vrh na začatie alternat&iacute;vneho rie&scaron;enia sporu je možn&eacute; podať
                    sp&ocirc;sobom určen&yacute;m podľa &sect; 12 Z&aacute;kona o&nbsp;alternat&iacute;vnom
                    rie&scaron;en&iacute; sporov. Použ&iacute;vateľ, ktor&yacute; je spotrebiteľ, m&ocirc;že začať
                    konanie aj sp&ocirc;sobom uveden&yacute;m niž&scaron;ie. Subjektom alternat&iacute;vneho
                    rie&scaron;enia sporov je &bdquo;Slovensk&aacute; obchodn&aacute; in&scaron;pekcia,
                    Prievozsk&aacute; 32, P.O. Box 5, 820 07 Bratislava 27&ldquo;, resp.
                    in&aacute; pr&iacute;slu&scaron;n&aacute; opr&aacute;vnen&aacute; pr&aacute;vnick&aacute; osoba
                    zap&iacute;san&aacute; v zozname subjektov alternat&iacute;vneho rie&scaron;enia sporov vedenom
                    Ministerstvom hospod&aacute;rstva Slovenskej republiky (zoznam je dostupn&yacute; na
                    str&aacute;nke http://www.mhsr.sk). Použ&iacute;vateľ, ktor&yacute; je spotrebiteľ, je
                    opr&aacute;vnen&yacute; si vybrať subjekt alternat&iacute;vneho rie&scaron;enia
                    spotrebiteľsk&yacute;ch sporov, na ktor&yacute; sa obr&aacute;ti. V pr&iacute;pade
                    cezhraničn&eacute;ho sporu m&aacute; Použ&iacute;vateľ - spotrebiteľ pr&aacute;vo obr&aacute;tiť
                    sa na Eur&oacute;pske spotrebiteľsk&eacute; centrum, ktor&eacute; mu poskytne adresu na
                    doručovanie, elektronick&uacute; adresu alebo telefonick&yacute; kontakt na subjekt
                    alternat&iacute;vneho rie&scaron;enia sporov, ktor&yacute; je pr&iacute;slu&scaron;n&yacute; na
                    rie&scaron;enie jeho sporu.
                </p>
                <p>Podľa čl&aacute;nku 14 Nariadenia Eur&oacute;pskeho parlamentu a&nbsp;Rady (E&Uacute;) č.
                    524/2013 z&nbsp;21.05.2013 o&nbsp;rie&scaron;en&iacute; spotrebiteľsk&yacute;ch sporov online,
                    ktor&yacute;m sa men&iacute; nariadenie (ES) č. 2006/2004 a&nbsp;smernica 2009/22/ES,
                    Použ&iacute;vateľ, ktor&yacute; je spotrebiteľom m&aacute; pr&aacute;vo svoje pr&aacute;va
                    a&nbsp;n&aacute;roky, vypl&yacute;vaj&uacute;ce zo zmluvn&eacute;ho vzťahu (k&uacute;pnej zmluvy
                    alebo zmluvy o&nbsp;služb&aacute;ch online) s&nbsp;Prev&aacute;dzkovateľom, uplatňovať
                    v&nbsp;r&aacute;mci alternat&iacute;vneho online rie&scaron;enia sporov. Rie&scaron;enie sporov
                    online (ďalej len &bdquo;<strong>RSO</strong>&ldquo;) zabezpečuje platforma
                    prev&aacute;dzkovan&aacute; Eur&oacute;pskou Komisiou. Použ&iacute;vateľ, ktor&yacute; je
                    spotrebiteľ, je opr&aacute;vnen&yacute; využiť platformu RSO na rie&scaron;enie sporov,
                    v&nbsp;jazyku, ktor&yacute; si zvol&iacute;. Platforma RSO je pr&iacute;stupn&aacute; online na
                    webovom s&iacute;dle:&nbsp;<a
                        href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&amp;lng=SK"><strong>https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&amp;lng=SK</strong></a>.
                </p>
                <p>Použ&iacute;vateľ m&ocirc;že Prev&aacute;dzkovateľa kontaktovať na e-mailovej adrese:&nbsp;<a
                    href="mailto:info@drazimto.sk"><strong>info@drazimto.sk</strong></a>.
                </p>
                <p id="jump18" style={{fontFamily: 'OpenSansBold'}} className='text-primary'>
                    <strong>
                        XVIII. Osobitné ustanovenia týkajúce sa platených služieb
                    </strong>
                </p>
                <p>Pri platen&yacute;ch Služb&aacute;ch poskytovan&yacute;ch Prev&aacute;dzkovateľom sa na
                    z&aacute;v&auml;zkov&yacute; vzťah medzi Prev&aacute;dzkovateľom a Použ&iacute;vateľom
                    použij&uacute; primerane ustanovenia t&yacute;chto Podmienok s v&yacute;nimkou čl&aacute;nku
                    XII. bod 3 a&nbsp;6 t&yacute;chto Podmienok pri platen&yacute;ch Služb&aacute;ch
                    poskytovan&yacute;ch Prev&aacute;dzkovateľom sa na z&aacute;v&auml;zkov&yacute; vzťah medzi
                    Prev&aacute;dzkovateľom a Použ&iacute;vateľom použij&uacute; primerane ustanovenia t&yacute;chto
                    Podmienok s v&yacute;nimkou čl&aacute;nku XII. bod 3 a&nbsp;6 t&yacute;chto Podmienok.
                </p>
                <p>V pr&iacute;pade, ak d&ocirc;jde zo strany Použ&iacute;vateľa platen&yacute;ch Služieb k
                    poru&scaron;eniu t&yacute;chto Podmienok, Prev&aacute;dzkovateľ je
                    opr&aacute;vnen&yacute; tomuto Použ&iacute;vateľovi obmedziť použ&iacute;vanie takejto platenej
                    Služby.
                </p>
                <p>Z&aacute;v&auml;zkov&yacute; vzťah medzi Prev&aacute;dzkovateľom a Použ&iacute;vateľom
                    m&ocirc;že byť ukončen&yacute;:<br/> b) uplynut&iacute;m doby, na ktor&uacute; bola zmluva
                    uzatvoren&aacute;, ak bola Použ&iacute;vateľovi poskytovan&aacute; platen&aacute; Služba na dobu
                    určit&uacute; bez možnosti automatickej prolong&aacute;cie,<br/> c) p&iacute;somnou dohodou
                    uzatvorenou medzi Prev&aacute;dzkovateľom a Použ&iacute;vateľom,<br/> d)
                    odst&uacute;pen&iacute;m od zmluvy, v s&uacute;lade s ustanoveniami t&yacute;chto Podmienok
                    alebo pr&iacute;slu&scaron;n&yacute;mi ustanoveniami Obchodn&eacute;ho
                    z&aacute;konn&iacute;ka,<br/> e) p&iacute;somnou v&yacute;poveďou podľa t&yacute;chto
                    Podmienok,<br/>
                </p>
                <p>Ak bolo poskytovanie platen&yacute;ch Služieb dojednan&eacute; na určit&uacute; dobu s
                    automatickou prolong&aacute;ciou, Použ&iacute;vateľ je opr&aacute;vnen&yacute; zmluvu
                    p&iacute;somne vypovedať najnesk&ocirc;r 3 pracovn&eacute; dni po doručen&iacute; fakt&uacute;ry
                    za ďal&scaron;ie obdobie, pričom zmluva sa zru&scaron;uje posledn&yacute;m dňom
                    predplaten&eacute;ho obdobia, v ktorom bola v&yacute;poveď doručen&aacute; spoločnosti.
                </p>
                <p>Prev&aacute;dzkovateľ je opr&aacute;vnen&yacute; ukončiť zmluvn&yacute; vzťah v&yacute;poveďou,
                    ak bolo rozhodnut&eacute; o ukončen&iacute; činnosti, ktor&aacute; je predmetom poskytovania
                    Služieb podľa t&yacute;chto v&scaron;eobecn&yacute;ch obchodn&yacute;ch podmienok.
                </p>
                <p>Prev&aacute;dzkovateľ je povinn&yacute; Použ&iacute;vateľa platen&yacute;ch Služieb,
                    ktor&yacute; poru&scaron;il tieto Podmienky informovať o zistenom poru&scaron;en&iacute; a o
                    rozsahu obmedzenia. V pr&iacute;pade, ak d&ocirc;jde zo strany Použ&iacute;vateľa
                    platen&yacute;ch služieb k poru&scaron;eniu Podmienok opakovane počas 6 mesiacov od
                    poru&scaron;enia Podmienok, na ktor&eacute; bol Použ&iacute;vateľ upozornen&yacute; v
                    s&uacute;lade s predch&aacute;dzaj&uacute;cou vetou tohto bodu Podmienok (ďalej
                    len &bdquo;Podstatn&eacute; poru&scaron;enie Podmienok&ldquo;), Prev&aacute;dzkovateľ je
                    opr&aacute;vnen&yacute; odst&uacute;piť od zmluvy. Vzhľadom na povahu poskytovan&yacute;ch
                    Služieb, Prev&aacute;dzkovateľ a Poskytovateľ, ktor&yacute; sa poklad&aacute; za spotrebiteľa v
                    zmysle pr&iacute;slu&scaron;n&yacute;ch pr&aacute;vnych predpisov, t&yacute;mto s&uacute;hlasia
                    v s&uacute;lade s &sect; 48 ods. 2 Občianskeho z&aacute;konn&iacute;ka, že
                    odst&uacute;pen&iacute;m od zmluvy podľa tohto bodu doch&aacute;dza k zru&scaron;eniu zmluvy ku
                    dňu doručenia odst&uacute;penia (t.j. k zru&scaron;eniu registr&aacute;cie). Pre
                    vyl&uacute;čenie pochybnost&iacute;, v pr&iacute;pade ak m&aacute; dan&yacute; Použ&iacute;vateľ
                    vytvoren&eacute; viacer&eacute; použ&iacute;vateľsk&eacute; kont&aacute;, pri Podstatnom
                    poru&scaron;en&iacute; Podmienok a uplatnen&iacute; pr&aacute;va na odst&uacute;penie od zmluvy
                    pri Podstatnom poru&scaron;en&iacute; podmienok zo strany Prev&aacute;dzkovateľa,
                    bud&uacute; zru&scaron;en&eacute; v&scaron;etky kont&aacute; dan&eacute;ho použ&iacute;vateľa.
                </p>
                <p>Odst&uacute;penie od zmluvy z d&ocirc;vodu Podstatn&eacute;ho poru&scaron;enia Podmienok
                    ozn&aacute;mi Prev&aacute;dzkovateľ Použ&iacute;vateľovi e-mailom na e-mailov&uacute; adresu
                    Použ&iacute;vateľa zadan&uacute; pri registr&aacute;cii. Odst&uacute;penie od zmluvy z
                    d&ocirc;vodu Podstatn&eacute;ho poru&scaron;enia Podmienok sa poklad&aacute; za
                    doručen&eacute; na druh&yacute; deň od odoslania e-mailovej spr&aacute;vy obsahuj&uacute;cej
                    odst&uacute;penie od zmluvy z d&ocirc;vodu Podstatn&eacute;ho poru&scaron;enia Podmienok.
                </p>
                <p>Pri platen&yacute;ch Služb&aacute;ch m&ocirc;že byť ich poskytovanie (aktiv&aacute;cie)
                    zabezpečovan&eacute; aj v s&uacute;činnosti s in&yacute;m subjektom. Ide o aktiv&aacute;cie
                    platen&yacute;ch služieb, ktor&eacute; zabezpečuje a sp&ocirc;sob platby sprostredkuje
                    spoločnosť ThePay s.r.o., so s&iacute;dlom Masarykovo n&aacute;měst&iacute; 102/65, 586 01
                    Jihlava, IČ: 28135261.
                </p>
                <p>Vzhľadom na charakter poskytovania Služieb, Použ&iacute;vateľ s&uacute;hlas&iacute; s
                    elektronick&yacute;m zasielan&iacute;m dokladov za platen&eacute; Služby.
                </p>
                <p id="jump19" style={{fontFamily: 'OpenSansBold'}} className='text-primary'>
                    <strong>
                        XIX. Z&aacute;verečn&eacute; ustanovenia
                    </strong>
                </p>
                <p>Podmienky inzerovania na Port&aacute;li nadob&uacute;daj&uacute; platnosť dňom uverejnenia. V
                    pr&iacute;pade ak m&aacute; Použ&iacute;vateľ postavenie spotrebiteľa v zmysle
                    pr&iacute;slu&scaron;n&yacute;ch pr&aacute;vnych predpisov, vz&aacute;jomn&yacute; vzťah medzi
                    Prev&aacute;dzkovateľom a Použ&iacute;vateľom, ktor&yacute; m&aacute; postavenie spotrebiteľa sa
                    spravuje ustanoveniami Občianskeho z&aacute;konn&iacute;ka a ďal&scaron;&iacute;ch
                    v&scaron;eobecne z&aacute;v&auml;zn&yacute;ch pr&aacute;vnych noriem upravuj&uacute;cich ochranu
                    spotrebiteľa v podmienkach Slovenskej republiky; pokiaľ Použ&iacute;vateľ nie je spotrebiteľom,
                    na ot&aacute;zky neupraven&eacute; Podmienkami sa uplatnia
                    pr&iacute;slu&scaron;n&eacute; ustanovenia Obchodn&eacute;ho z&aacute;konn&iacute;ka.
                </p>
                <p>Prev&aacute;dzkovateľ m&aacute; pr&aacute;vo meniť, inovovať a inak modifikovať služby
                    Port&aacute;lu bez predch&aacute;dzaj&uacute;ceho upozornenia Použ&iacute;vateľov ako aj bez ich
                    s&uacute;hlasu, v pr&iacute;pade, že tak&aacute;to zmena nie je poru&scaron;en&iacute;m
                    pr&aacute;v Použ&iacute;vateľov.
                </p>
                <p>Prev&aacute;dzkovateľ je opr&aacute;vnen&yacute; kedykoľvek meniť ustanovenia t&yacute;chto
                    Podmienok z technick&yacute;ch d&ocirc;vodov, legislat&iacute;vnych zmien alebo z d&ocirc;vodu
                    roz&scaron;&iacute;renia, z&uacute;ženia alebo inej zmeny poskytovan&yacute;ch služieb na
                    drazimto.sk alebo z d&ocirc;vodu potrieb Prev&aacute;dzkovateľa. V pr&iacute;pade, že
                    d&ocirc;jde k zmene Podmienok, Použ&iacute;vateľ berie na vedomie a s&uacute;hlas&iacute; s
                    t&yacute;m, že platn&aacute; a &uacute;činn&aacute; je vždy posledn&aacute; verzia Podmienok.
                    Použ&iacute;vateľ je opr&aacute;vnen&yacute; sa kedykoľvek obozn&aacute;miť s aktu&aacute;lnym
                    znen&iacute;m t&yacute;chto Podmienok. Ak Použ&iacute;vateľ pokračuje v
                    použ&iacute;van&iacute; Služieb po preveden&iacute; t&yacute;chto zmien Prev&aacute;dzkovateľom,
                    m&aacute; sa za to, že so zmenami pravidiel bez v&yacute;hrad s&uacute;hlas&iacute;.
                </p>
                <p>V&nbsp;Bratislave 29.09.2023</p>
            </div>
        </Container>
    )
}
