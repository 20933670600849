import * as React from 'react'
import dayjs from "dayjs"
import {Container, Grid, IconButton, Typography, useMediaQuery} from '@mui/material'
import logo_biele from '../../assets/images/logo_biele.png'
import FacebookIcon from '@mui/icons-material/Facebook'
import logoMesarc from '../../assets/icons/logoMesarc.png'
import InstagramIcon from '@mui/icons-material/Instagram'
import {Link} from 'react-router-dom'

export default function Footer(): JSX.Element {
    //- responsive
    const isTabletOrDesktop: boolean = useMediaQuery('(max-width: 900px)')
    return (
        <footer
            style={{
                backgroundColor: '#1851AB',
                width: '100%',
                color: '#FFFFFF',
            }}
        >
            <div style={{minHeight: '25vh', display: 'flex', flexDirection: 'column'}}>
                <div style={{flex: '1'}}>
                    <Container sx={{pl: '20px', pr: '20px'}}>
                        <Grid container justifyContent="center" alignItems="center" style={{minHeight: '20vh'}}>
                            <Grid item md={12} xs={12} alignItems="center">
                                <Grid container>
                                    <Grid item md={12} xs={12} sx={{mb: '-18px'}}>
                                        <div className="text-center">
                                            <img src={logo_biele} alt="logo" width='auto' height='100px'/>
                                        </div>
                                    </Grid>
                                    <Grid item container spacing={1} justifyContent="center">
                                        <Grid item>
                                            <IconButton className="social-icon text-white">
                                                <Link to='https://www.facebook.com/drazimto' target='_blank'
                                                      style={{color: 'white'}}>
                                                    <FacebookIcon/>
                                                </Link>
                                            </IconButton>
                                        </Grid>
                                        <Grid item>
                                            <IconButton className="social-icon text-white">
                                                <Link to='https://www.instagram.com/drazimto/' target='_blank'
                                                      style={{color: 'white'}}>
                                                    <InstagramIcon/>
                                                </Link>
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/*<Grid item md={6} xs={12} container justifyContent="center" alignItems="center">*/}
                            {/*    {!isTabletOrDesktop ? (*/}
                            {/*        <Grid container spacing={2} justifyContent="center">*/}
                            {/*            <Grid item md={6} xs={12} container justifyContent="center">*/}
                            {/*                <Typography variant="h6" component="p">*/}
                            {/*                <span style={{fontSize: '24px', fontFamily: 'OpenSansLight'}}>*/}
                            {/*                    Dražím to s.r.o.*/}
                            {/*                </span>*/}
                            {/*                    <br/>*/}
                            {/*                    <span style={{fontFamily: 'OpenSansLight'}}>Kazanská 6</span>*/}
                            {/*                    <br/>*/}
                            {/*                    <span style={{*/}
                            {/*                        fontSize: '14px',*/}
                            {/*                        fontFamily: 'OpenSansBold'*/}
                            {/*                    }}>Arpád Jelšík</span>*/}
                            {/*                    <br/>*/}
                            {/*                    <span style={{*/}
                            {/*                        fontSize: '14px',*/}
                            {/*                        fontFamily: 'OpenSansBold'*/}
                            {/*                    }}>+421 523 558</span>*/}
                            {/*                </Typography>*/}
                            {/*            </Grid>*/}
                            {/*            <Grid item md={6} xs={12} justifyContent={{md: 'flex-end', xs: 'center'}}*/}
                            {/*                  style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}*/}
                            {/*            >*/}
                            {/*                <Typography variant="h6" component="p"*/}
                            {/*                            style={{fontSize: '14px', fontFamily: 'OpenSansBold'}}>*/}
                            {/*                    IČO: 365854225555*/}
                            {/*                    <br/>*/}
                            {/*                    DIČ: 205524525555221*/}
                            {/*                </Typography>*/}
                            {/*            </Grid>*/}
                            {/*        </Grid>*/}
                            {/*    ) : (*/}
                            {/*        <Grid container spacing={2} justifyContent="center">*/}
                            {/*            <Grid item md={12} xs={12} container justifyContent="center">*/}
                            {/*                <Typography variant="h6" component="p"*/}
                            {/*                            style={{fontSize: '24px', fontFamily: 'OpenSansLight'}}>*/}
                            {/*                    Dražím to s.r.o.*/}
                            {/*                </Typography>*/}
                            {/*            </Grid>*/}
                            {/*            <Grid item md={12} xs={12} container justifyContent="center">*/}
                            {/*                <Typography variant="h6" component="p"*/}
                            {/*                            style={{fontFamily: 'OpenSansLight'}}>*/}
                            {/*                    Kazanská 6*/}
                            {/*                </Typography>*/}
                            {/*            </Grid>*/}
                            {/*            <Grid item md={12} xs={12} container justifyContent="center"*/}
                            {/*                  style={{fontSize: '14px', fontFamily: 'OpenSansBold'}}>*/}
                            {/*                <Typography variant="h6" component="p"*/}
                            {/*                            style={{fontSize: '14px', fontFamily: 'OpenSansBold'}}>*/}
                            {/*                    Arpád Jelšík*/}
                            {/*                </Typography>*/}
                            {/*            </Grid>*/}
                            {/*            <Grid item md={12} xs={12} container justifyContent="center"*/}
                            {/*                  style={{fontSize: '14px', fontFamily: 'OpenSansBold'}}>*/}
                            {/*                <Typography variant="h6" component="p"*/}
                            {/*                            style={{fontSize: '14px', fontFamily: 'OpenSansBold'}}>*/}
                            {/*                    +421 523 558*/}
                            {/*                </Typography>*/}
                            {/*            </Grid>*/}
                            {/*            <Grid item md={12} xs={12} container justifyContent="center"*/}
                            {/*                  style={{fontSize: '14px', fontFamily: 'OpenSansBold'}}>*/}
                            {/*                <Typography variant="h6" component="p"*/}
                            {/*                            style={{fontSize: '14px', fontFamily: 'OpenSansBold'}}>*/}
                            {/*                    IČO: 365854225555*/}
                            {/*                </Typography>*/}
                            {/*            </Grid>*/}
                            {/*            <Grid item md={12} xs={12} container justifyContent="center"*/}
                            {/*                  style={{fontSize: '14px', fontFamily: 'OpenSansBold'}}>*/}
                            {/*                <Typography variant="h6" component="p"*/}
                            {/*                            style={{fontSize: '14px', fontFamily: 'OpenSansBold'}}>*/}
                            {/*                    DIČ: 205524525555221*/}
                            {/*                </Typography>*/}
                            {/*            </Grid>*/}
                            {/*        </Grid>*/}
                            {/*    )}*/}
                            {/*</Grid>*/}
                        </Grid>
                    </Container>
                </div>
                <Grid container spacing={1} justifyContent='center' alignItems='center'>
                    {/* zakomentovane na ziadost M 02.10.2023 - nie je pripravena cookies page */}
                    {/*<Grid item>*/}
                    {/*    <Link to='/cookies' className='text-white no-text-decoration link'>*/}
                    {/*        <span style={{borderRight: '3px solid #FFF'}}>*/}
                    {/*            Cookies&nbsp;&nbsp;*/}
                    {/*        </span>*/}
                    {/*    </Link>*/}
                    {/*</Grid>*/}
                    <Grid item>
                        <Link to='/gdpr' className='text-white no-text-decoration'>
                            <span style={{borderRight: '3px solid #FFF'}}>
                                GDPR&nbsp;&nbsp;
                            </span>
                        </Link>
                    </Grid>
                    <Grid item>
                        <Link to='/general-terms-and-conditions' className='text-white no-text-decoration'>
                            <span>
                                Všeobecné obchodné podmienky
                            </span>
                        </Link>
                    </Grid>
                </Grid>
                <Grid container spacing={1}>
                    <Grid item md={12} sm={12}>
                        <div className="w-100 text-center">
                            <a href="https://www.thepay.eu/" target="_blank" rel="noopener noreferrer">
                                <img
                                    src="https://gate.thepay.cz/img/thepay-v2-220209-white-gpay.svg?pid=2139"
                                    alt='the-pay-banner'
                                />
                            </a>
                        </div>
                    </Grid>
                </Grid>
                <div
                    style={{
                        padding: '10px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'flex-end',
                    }}
                >
                    <Typography variant="body2" color="textSecondary" style={{fontFamily: 'OpenSansRegular'}}
                                className="text-white" align="center">
                        &copy; {dayjs().year()} RDO s.r.o. All rights reserved.
                    </Typography>
                </div>
                <Typography variant="body2" color="textSecondary"
                            style={{fontFamily: 'OpenSansRegular', marginBottom: '7px'}}
                            className="text-white" align="center">
                    Vytvorené <a href="https://www.mesarc.sk/" target='_blank'>
                    <img src={logoMesarc} alt="web mesarc logo" height='25px' className='logo'/>
                </a>
                </Typography>
            </div>
        </footer>
)
}
