import { Action, ClientContext } from "react-fetching-library"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"
import { request } from '../../utils/api/ApiUtil'
import { AuthContext } from "../../App"
import AuthContextType from "../../types/AuthContext/AuthContextType"
import * as React from 'react'
import FaqItemInterface from '../../types/FaqItem/FaqItemInterface'
import CheckIcon from '@mui/icons-material/Check'
import Loading from "../loader/Loading"

export interface AddOrEditFaqItemProps {
    faqItem: FaqItemInterface | null,
    action: Action,
    errorKey: string, // napr 'faq_item_add'
    messageOnSuccess: string,
    messageOnFailure: string,
    urlToNavigateOnSucccess: string,
}


export default function AddOrEditFaqItem(props: AddOrEditFaqItemProps): JSX.Element {
    // constants
    const FIELD_SHOULDNT_BE_NULL: string = 'Táto hodnota by nemala byť prázdna'
    const INTERNAL_SERVER_ERROR_MESSAGE: string = 'Nastala neočakavaná chyba!'
    // state
    const [question, setQuestion] = React.useState<string | null>(props.faqItem?.question ?? null)
    const [answer, setAnswer] = React.useState<string | null>(props.faqItem?.answer ?? null)
    const [isQuestionValid, setIsQuestionValid] = React.useState<boolean>(true)
    const [isAnswerValid, setIsAnswerValid] = React.useState<boolean>(true)
    const [questionErrors, setQuestionErrors] = React.useState<string[]>([])
    const [answerErrors, setAnswerErrors] = React.useState<string[]>([])
    const [isLoading, setIsLoading] = React.useState<boolean>(false)
    // context
    const clientContext = React.useContext(ClientContext)
    const authContext: AuthContextType = React.useContext(AuthContext)
    const navigate = useNavigate()


    const validateQuestion = (question: string | null): boolean => {
        const isQuestionValidInternal: boolean = question !== null && question !== ''
        if (!isQuestionValidInternal) {
            setIsQuestionValid(false)
            setQuestionErrors([FIELD_SHOULDNT_BE_NULL])
        } else {
            setIsQuestionValid(true)
            setQuestionErrors([])
        }
        return isQuestionValidInternal
    }

    const validateAnswer = (answer: string | null): boolean => {
        const isAnswerValidInternal: boolean = answer !== null && answer !== ''
        if (!isAnswerValidInternal) {
            setIsAnswerValid(false)
            setAnswerErrors([FIELD_SHOULDNT_BE_NULL])
        } else {
            setIsAnswerValid(true)
            setAnswerErrors([])
        }
        return isAnswerValidInternal
    }

    const onSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault()
        if (!validateQuestion(question) || !validateAnswer(answer)) {
            validateQuestion(question)
            validateAnswer(answer)
            return // nepustime usera dalej ak nema validne vyplnene polia
        }
        setIsLoading(true)
        // submit form
        const action2: Action = {
            ...props.action,
            body: {
                question,
                answer,
            }
        }
        const {payload, error, status} = await request(action2, clientContext)
        if (error) {
            toast.error(INTERNAL_SERVER_ERROR_MESSAGE)
            setIsLoading(false)
            return
        }
        if (status === 401) {
            authContext.logout()
        }
        if (status === 200 || status === 201) {
            toast.success(props.messageOnSuccess)
            navigate(props.urlToNavigateOnSucccess) // presmerujeme usera na url
        } else {
            if (payload.errors.length > 0) {
                const errors = payload.errors
                if (Object.keys(errors).indexOf(props.errorKey) !== -1) {
                    toast.error(errors[props.errorKey])
                } else if (errors.hasOwnProperty('question')) {
                    setQuestionErrors([...errors.question])
                } else if (errors.hasOwnProperty('answer')) {
                    setAnswerErrors([...errors.answer])
                }
            }
            toast.error(props.messageOnFailure)
        }
        setIsLoading(false)
    }

    if (isLoading) {
        return (
            <Loading/>
        )
    }

    return (
        <React.Fragment>
            <form onSubmit={onSubmit}>
                <div className="form-group mt-2">
                    <label
                        htmlFor="question"
                        className='required text-primary'
                        style={{fontFamily: 'OpenSansBold'}}
                    >
                        Otázka
                    </label>
                    <input type="text"
                           className={(!isQuestionValid ? 'error' : '') + ' form-control rounded-borders border-primary'}
                           name="question"
                           id="question" placeholder="Otázka"
                           value={question || ''}
                           onChange={(event: React.ChangeEvent<HTMLInputElement>) => setQuestion(event.target.value)}
                    />
                    <div>
                        {!isQuestionValid && (
                            <React.Fragment>
                                {questionErrors.map((error: string, index: number) => (
                                    <p className='error' key={index}>
                                        {error}
                                    </p>
                                ))}
                            </React.Fragment>
                        )}
                    </div>
                </div>
                <div className="form-group mt-2">
                    <label
                        htmlFor="answer"
                        className='required text-primary'
                        style={{fontFamily: 'OpenSansBold'}}
                    >
                        Odpoveď
                    </label>
                    <textarea
                        className={(!isAnswerValid ? 'error' : '') + ' form-control rounded-borders border-primary'}
                        name="answer" id="answer"
                        placeholder="Odpoveď"
                        value={answer || ''}
                        onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => setAnswer(event.target.value)}
                    />
                    <div>
                        {!isAnswerValid && (
                            <React.Fragment>
                                {answerErrors.map((error: string, index: number) => (
                                    <p className='error' key={index}>
                                        {error}
                                    </p>
                                ))}
                            </React.Fragment>
                        )}
                    </div>
                </div>
                <div className="text-center mt-2">
                    <button
                        type="submit"
                        className="background-primary rounded-borders border-primary"
                        style={{fontFamily: 'OpenSansRegular'}}
                    >
                        <CheckIcon/>
                        <span>
                            Uložiť
                        </span>
                    </button>
                </div>
            </form>
        </React.Fragment>
    )
}
