import React, {useState} from 'react'
import {useDropzone} from 'react-dropzone'
import {toast} from 'react-toastify'
import {AuthContext} from '../../App'
import {Card, CardContent, CircularProgress, Grid, Tooltip, Typography} from "@mui/material"
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'
import {FaCheck, FaTimes} from "react-icons/fa"


interface FileDropzoneProps {
    files: File[],
    setFiles: Function,
    maxAllowedFilesCount: number,
    apiUrl: string,
    canUpload: boolean,
    acceptedExtensions: string[],
    callback: Function,
    checkMaxSize: boolean,
    maxSize: number, // v bitoch
    uploadFilesMessage: string,
    dragAndDropMessage: string,
    dropMessage: string,
    advertisementId: number,
    documentType: number | null,
    documentId: number | null,
}

interface FileUploadError {
    fileName: string,
    errors: string[],
}

export default function FileUpload(props: FileDropzoneProps) {
    const authContext = React.useContext(AuthContext)
    const [files, setFiles] = useState<File[]>(props.files)
    const [filesToUpload, setFilesToUpload] = useState<File[]>([])
    const [uploadedFiles, setUploadedFiles] = useState<File[]>([])
    const [failedFiles, setFailedFiles] = useState<File[]>([])
    const [uploadingFileName, setUploadingFileName] = useState<string>('')
    const [errors, setErrors] = useState<FileUploadError[]>([])
    React.useEffect(() => {
        if (props.canUpload) {
            sendFiles()
        }
    }, [props.canUpload])

    React.useEffect(() => {
        if (props.files.length < props.maxAllowedFilesCount) {
            setFiles(props.files)
            setFilesToUpload(props.files)
        } else {
            toast.error(`Maximálny počet súborov je ${props.maxAllowedFilesCount}!`)
        }
    }, [props.files])

    const handleDrop = (droppedFiles: File[]) => {
        let filteredFiles2 = droppedFiles.filter((droppedFile: File) => {
            const extension = droppedFile.name.split('.').pop()
            const doesExtensionMatch = props.acceptedExtensions.includes(extension as string)
            if (!doesExtensionMatch) {
                toast.error(`Súbor '${droppedFile.name}' má nepodporovanú príponu! Podporované prípony sú: ${props.acceptedExtensions.join(', ')}`)
            }
            return doesExtensionMatch
        })

        if (props.checkMaxSize) {
            filteredFiles2 = filteredFiles2.filter((droppedFile: File) => {
                const doesSizeMatch = droppedFile.size <= props.maxSize
                if (!doesSizeMatch) {
                    toast.error(`Súbor '${droppedFile.name}' je príliš veľký! Maximálna veľkosť súboru je ${props.maxSize / 1000000} MB`)
                }
                return doesSizeMatch
            })
        }
        if (filteredFiles2.length < props.maxAllowedFilesCount) {
            props.setFiles(filteredFiles2, false)
            setFilesToUpload(filteredFiles2)
        } else {
            toast.error(`Maximálny počet súborov je ${props.maxAllowedFilesCount}!`)
        }
    }

    const handleRemove = (e: React.MouseEvent<HTMLButtonElement>, file: File) => {
        e.stopPropagation() // Prevent file explorer from opening
        e.preventDefault()
        const updatedFiles = files.filter((f) => f !== file)
        if (updatedFiles.length < props.maxAllowedFilesCount) {
            setFiles(updatedFiles)
            setFilesToUpload(updatedFiles)
            props.setFiles(updatedFiles, true)
        } else {
            toast.error(`Maximálny počet súborov je ${props.maxAllowedFilesCount}!`)
        }
    }

    const sendFiles = () => {
        if (files.length > 0) {
            for (let file of files) {
                sendFile(file)
            }
        }
    }

    React.useEffect(() => {
        if (props.canUpload && filesToUpload.length === 0) {
            props.callback()
        }
    }, [filesToUpload])

    const sendFile = (file: File) => {
        if (uploadedFiles.includes(file)) {
            props.callback()
            return
        }
        setUploadingFileName(file.name)
        const formData = new FormData()
        formData.append('file', file) // Append the file directly
        //-- advertisementId
        formData.append('advertisementId', props.advertisementId.toString())
        //-- documentId
        if (props.documentId !== null) {
            formData.append('documentId', props.documentId.toString())
        }
        //-- documentType
        if (props.documentType !== null) {
            formData.append('documentType', props.documentType.toString())
        }
        const headers = new Headers()
        headers.append('Authorization', 'Bearer ' + authContext.jwtToken)
        fetch(props.apiUrl, {
            method: 'POST',
            headers: headers,
            body: formData,
        })
            .then((response) => {
                if (response.ok) {
                    toast.success(`Súbor "${file.name}" bol úspešne nahraný!`)
                    const responseJson = response.json()
                    responseJson.then((data) => {
                        if (data.success === false) {
                            toast.error(`Súbor "${file.name}" sa nepodarilo nahrať!`)
                            if (data.errors.length !== 0) {
                                const error = {
                                    fileName: file.name,
                                    errors: data.errors
                                }
                                setErrors((prevErrors) => [...prevErrors, error])
                                setFailedFiles((prevFiles) => [...prevFiles, file]) // Add the failed file to the failed files array
                            }
                        } else {
                            // pridame subor do uploaded suborov
                            setUploadedFiles((prevUploadedFiles) => [...prevUploadedFiles, file])
                        }

                    })
                } else {
                    if (response.status === 402) { // 'Payment Required' status code
                        toast.error(`Súbor "${file.name}" sa nepodarilo nahrať! Musíte si zakúpiť balík!`)
                    } else {
                        toast.error(`Súbor "${file.name}" sa nepodarilo nahrať!`)
                    }
                }
                setFilesToUpload((prevFiles) => prevFiles.filter((f) => f !== file)) // Remove the uploaded file from the files to upload array
                setUploadingFileName('')

            })
            .catch((error) => {
                toast.error(`Súbor "${file.name}" sa nepodarilo nahrať!`)
                setFilesToUpload((prevFiles) => prevFiles.filter((f) => f !== file)) // Remove the failed file from the files to upload array
                setFailedFiles((prevFiles) => [...prevFiles, file]) // Add the failed file to the failed files array
                setUploadingFileName('')
            })
    }

    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop: handleDrop})

    return (
        <React.Fragment>
            <Card {...getRootProps()} className={`dropzone ${isDragActive ? 'active' : ''}`}
                  sx={{minWidth: 300, border: 'none', boxShadow: 'none', marginTop: '5%', cursor: 'pointer'}}>
                <CardContent className='background-primary'
                             style={{borderRadius: '15px'}}>
                    <Grid container justifyContent="center" alignItems="center" style={{minHeight: '20vh'}}>
                        <Grid item md={12} container direction="column" alignItems="center">
                            <AddRoundedIcon sx={{fontSize: '15rem'}}/>
                            <Typography variant="h5" component="div"
                                        sx={{marginTop: '2%', fontFamily: 'OpenSansSemiBold'}}>
                                {props.uploadFilesMessage}
                            </Typography>
                            <input {...getInputProps()} />
                            {isDragActive ? (
                                <p>
                                    {props.dropMessage}
                                </p>
                            ) : (
                                <p>
                                    {props.dragAndDropMessage}
                                </p>
                            )}
                        </Grid>
                    </Grid>
                    {files.length > 0 && (
                        <Grid container spacing={2}>
                            {files.map((file, index) => (
                                <Grid item key={index} xs={12} sm={6}>
                                    <div className="file">
                                        {file.type.includes('image') ? (
                                            <div className="thumbnail-container">
                                                <img
                                                    src={URL.createObjectURL(file)}
                                                    alt="Thumbnail"
                                                    className="thumbnail"
                                                />
                                            </div>
                                        ) : (
                                            <div className="file-icon-container">
                                                <InsertDriveFileIcon className="file-icon"/>
                                            </div>
                                        )}
                                        <div className="file-info">
                                            <div className="file-details">
                                                <span className="file-name">{file.name}</span>
                                                {uploadedFiles.includes(file) && (
                                                    <span style={{marginLeft: '1%'}}>
                                                        <Tooltip title='Súbor bol úspešne nahraný'>
                                                            <span>
                                                                <FaCheck
                                                                    className='success-icon'
                                                                />
                                                            </span>
                                                        </Tooltip>
                                                    </span>
                                                )}
                                                {failedFiles.includes(file) && (
                                                    <span style={{marginLeft: '1%'}}>
                                                        <Tooltip title='Nahrávanie súboru sa nepodarilo'>
                                                            <span>
                                                                <FaTimes
                                                                    className='error-icon'
                                                                />
                                                            </span>
                                                        </Tooltip>
                                                    </span>
                                                )}
                                                {uploadingFileName === file.name && (
                                                    <span className="icon uploading-icon" style={{color: 'white'}}>
                                                        <CircularProgress
                                                            color='inherit'
                                                        />
                                                    </span>
                                                )}
                                            </div>
                                            {filesToUpload.length !== 0 && (
                                                <div className="remove-btn-container">
                                                    <button
                                                        className="btn btn-danger"
                                                        style={{fontFamily: 'OpenSansRegular'}}
                                                        onClick={(event) => handleRemove(event, file)}
                                                    >
                                                        Zmazať súbor
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </Grid>
                            ))}
                        </Grid>
                    )}
                </CardContent>
            </Card>
        </React.Fragment>
    )
}
