import * as React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Action, ClientContext } from "react-fetching-library"
import { getApiUrlFromRelativeUrl } from "../../utils/api/ApiUtil"
import OrderInterface from "../../types/Order/OrderInterface"
import { Container, Typography } from '@mui/material'
import ErrorComponent from "../../components/error/ErrorComponent"
import Loading from "../../components/loader/Loading"

export default function OrderCreatedPage(): JSX.Element {
    // constants
    const INTERNAL_SERVER_ERROR_MESSAGE: string = 'Nastala neočakavaná chyba!'
    const APP_EMAIL: string = process.env.REACT_APP_APP_EMAIL || 'info@drazimto.sk' // email z env - default je info@drazimto.sk
    // state
    const [isLoading, setIsLoading] = React.useState<boolean>(true)
    const [order, setOrder] = React.useState<OrderInterface | null>(null)
    const [isError, setIsError] = React.useState<boolean>(false)
    // init navigate, params
    const navigate = useNavigate()
    const params = useParams()
    const {orderId, advertisementId} = params
    // context
    const clientContext = React.useContext(ClientContext)
    // action
    const getOrderAction: Action = {
        method: 'GET',
        endpoint: getApiUrlFromRelativeUrl('/api/order/get/' + orderId),
        headers: {
            Accept: 'application/json'
        },
    }

    React.useEffect(() => {
        const getOrder = async (): Promise<void> => {
            const {error, payload, status} = await clientContext.query(getOrderAction)
            if (error) {
                setIsError(true)
                setIsLoading(false)
                return
            }
            if (status === 200) {
                setOrder(payload)
            }
            setIsLoading(false)
        }
        getOrder()
    }, [])

    if (isLoading) {
        return (
            <Loading/>
        )
    }

    if (isError) {
        return (
            <ErrorComponent
                message={INTERNAL_SERVER_ERROR_MESSAGE}
            />
        )
    }

    return (
        <Container sx={{mt: '2%', mb: '2%'}}>
            <div className="text-center">
                <Typography variant='h5' component='div' style={{fontFamily: 'OpenSansBold'}} className='text-primary'>
                    Objednávka s číslom {order?.number} bola úspešne vytvorená, ale ohľadom aktivovania balíka sa Vám ozveme na Váš email.
                </Typography>
            </div>
            <div className="text-center">
                <button
                    onClick={() => navigate(`/advertisement/view/${advertisementId}`)}
                    className="background-primary rounded-borders primary-border"
                    style={{fontFamily: 'OpenSansSemiBold', fontSize: '20px', padding: '5px'}}
                >
                    Zobraziť inzerát
                </button>
            </div>
        </Container>
    )
}
