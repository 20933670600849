import * as React from 'react'
import { Container, Typography } from "@mui/material"
import { Link, useParams } from "react-router-dom"

export default function PaymentCancelledPage(): JSX.Element {
    const params = useParams()
    const {paymentId, advertisementId} = params

    return (
        <Container>
            <div className="text-center">
                <Typography variant='h5' component='div' style={{fontFamily: 'OpenSansBold'}} className='text-primary'>
                    Platba bola zrušená!
                </Typography>
                <Link to={`/advertisement/view/${advertisementId}`}
                      style={{fontFamily: 'OpenSansBold', padding: '5px 15px', textDecoration: 'none'}}
                      className='background-primary rounded-borders border-primary'>
                    Zobraziť inzerát
                </Link>
            </div>
        </Container>
    )
}
