export const getDocumentName = (name: string | null): string => {
    if (name === null) {
        return ''
    }
    // ak ma name viac znakov ako 15 tak ho skratime, ak sa da tak ukoncime na prvej moznej medzere po 15 znakoch
    if (name.length > 15) {
        const spaceIndex = name.indexOf(' ', 15)
        if (spaceIndex !== -1) {
            return name.slice(0, spaceIndex) + '...'
        }
        return name.slice(0, 15) + '...'
    }
    return name
}