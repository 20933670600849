import * as React from 'react'
import { Container, Grid, Typography } from '@mui/material'
import { AdvertisementInterface } from "../../types/Advertisement/AdvertisementInterface"
import { Action, ClientContext } from "react-fetching-library"
import { getApiUrlFromRelativeUrl, request } from "../../utils/api/ApiUtil"
import ToppedAdvertisementCarouselItem from "./ToppedAdvertisementCarouselItem"
import Carousel from "react-multi-carousel"
import Loading from "../loader/Loading"
import ErrorComponent from "../error/ErrorComponent"

interface ToppedAdvertisementsProps {
    marginBottom: string,
}

export default function ToppedAdvertisements(props: ToppedAdvertisementsProps): JSX.Element {
    // constants
    const INTERNAL_SERVER_ERROR_MESSAGE: string = 'Nastala neočakavaná chyba!'
    //- context
    const clientContext = React.useContext(ClientContext)

    //- state
    const [isLoading, setIsLoading] = React.useState<boolean>(true)
    const [toppedAdvertisements, setToppedAdvertisements] = React.useState<AdvertisementInterface[]>([])
    const [isError, setIsError] = React.useState<boolean>(false)

    //- responsive carousel settings
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4,
            slidesToSlide: 4 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    }

    const carouselItemStyle = {
        marginRight: '15px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '250px', // Set the height to match the carousel height
        width: '200px',
        overflow: 'visible',
    }

    //- actions
    const getToppedAdvertisementsAction: Action = {
        method: 'GET',
        endpoint: getApiUrlFromRelativeUrl('/api/advertisements/allNotPaginated/topped'),
        headers: {
            Accept: 'application/json'
        }
    }

    //- effect
    React.useEffect(() => {
        const getToppedAdvertisements = async () => {
            const {payload, error} = await request(getToppedAdvertisementsAction, clientContext)
            if (error) {
                setIsError(true)
            }
            if (payload.success) {
                setToppedAdvertisements(payload.data)
            }
            setIsLoading(false)
        }
        getToppedAdvertisements()
    }, [])

    if (isError) {
        return (
            <ErrorComponent
                message={INTERNAL_SERVER_ERROR_MESSAGE}
            />
        )
    }

    return (
        <React.Fragment>
            {isLoading && <Loading/>}
            <Grid container spacing={2} sx={{mt: '5%', mb: props.marginBottom}}>
                <Grid item xs={12} display='flex' justifyContent='center' alignItems='center' className='background-primary'>
                    <Typography variant='h5' component='div' style={{fontFamily: 'OpenSansBold', color: 'white', padding: '15px'}}>
                        TOP
                    </Typography>
                </Grid>
                <Grid item xs={12} className='grey-background' sx={{minHeight: '400px'}}>
                    <Container sx={{mt: '3%', height: '100%'}}>
                        {toppedAdvertisements.length > 0 ? (
                            <Carousel
                                swipeable={true}
                                draggable={true}
                                infinite={false}
                                responsive={responsive}
                            >
                                {toppedAdvertisements.map((advertisement: AdvertisementInterface) => (
                                    <div style={carouselItemStyle}  key={advertisement.id}>
                                        <ToppedAdvertisementCarouselItem
                                            advertisement={advertisement}
                                            urlToRedirect={`/advertisement/view/${advertisement.id}`}
                                        />
                                    </div>
                                ))}
                            </Carousel>
                        ) : (
                            <div className='text-center w-100 d-flex justify-content-center align-items-center' style={{ marginTop: '13%' }}>
                                <Typography variant='h5' component='div' style={{fontFamily: 'OpenSansBold'}} className='text-primary'>
                                    Žiadne TOP inzeráty
                                </Typography>
                            </div>
                        )}
                    </Container>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}
