import * as React from 'react'
import {AdvertisementInterface} from "../../types/Advertisement/AdvertisementInterface"
import {Box, Card, CardContent, Grid, Tooltip, Typography, useMediaQuery} from "@mui/material"
import {DocumentInterface} from "../../types/Document/DocumentInterface"
import RoomIcon from '@mui/icons-material/Room'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import dayjs from "dayjs"
import auta_kategoria_biele from '../../assets/icons/auta_kategoria_biele.svg'
import nehnutelne_veci_biele from '../../assets/icons/nehnutelne_veci_biele.svg'
import iny_majetok_biele from '../../assets/icons/iny_majetok_biele.png'
import {getApiUrlFromRelativeUrl} from "../../utils/api/ApiUtil";

interface ShowAdvertisementsPageListCardProps {
    advertisement: AdvertisementInterface,
    onCardClick: () => void, // navigate na advertisement
}

enum AdvertisementThingType {
    MOVABLE = 1,
    IMMOVABLE = 2,
    OTHER = 3,
}

const getSubject = (subject: string): string => {
    // max 35 chars then add '...' and end on first possible space
    if (subject.length > 30) {
        return subject.substr(0, 30).concat('...')
    }
    return subject
}

const getDescription = (description: string): string => {
    // max 100 chars then add '...' and end on first possible space
    const regex = /<(?!\/?(strong|hr|br|p|i))[^>]+>/g;

    // Use the replace method to remove unwanted HTML tags and their contents
    description = description.replace(regex, '');

    if (description.length > 182) {
        return description.substr(0, 182).concat('...')
    }
    return description
}
export default function ShowAdvertisementsPageListCard(props: ShowAdvertisementsPageListCardProps): JSX.Element {
    const hasProtograph: boolean = props.advertisement.primaryPhotograph !== null && props.advertisement.primaryPhotograph !== undefined
    const protograph: DocumentInterface | null = hasProtograph ? props.advertisement.primaryPhotograph : null

    const isMobileOrTablet: boolean = useMediaQuery('(max-width: 655px)')
    return (
        <Card
            sx={{minWidth: 300, border: 'none', boxShadow: 'none'}}
            className='clickable'
            onClick={() => props.onCardClick()}
        >
            {!isMobileOrTablet ? (
                <Box display='flex'>
                    {(hasProtograph && protograph !== null) && (
                        <div style={{
                            position: 'relative',
                            height: `400px`,
                            maxWidth: '300px'
                        }}>
                            <img
                                className='image-thumbnail'
                                style={{width: '300px'}}
                                src={protograph.url !== null && protograph.url !== undefined ? getApiUrlFromRelativeUrl(protograph.url) : ''}
                                alt={protograph.name || ''}
                            />
                        </div>
                    )}
                    {(!hasProtograph || protograph === null) && (
                        <div style={{position: 'relative', height: '400px', maxWidth: '400px'}}>
                            <div style={{width: '300px', height: `400px`}}
                                 className='background-primary'>
                                <div className="text-center" style={{height: '100%'}}>
                                    <img
                                        src={
                                            props.advertisement.thingType === AdvertisementThingType.MOVABLE ? auta_kategoria_biele : props.advertisement.thingType === AdvertisementThingType.IMMOVABLE ? nehnutelne_veci_biele : iny_majetok_biele
                                        }
                                        style={{width: '300px'}}
                                        className={`${props.advertisement.thingType === AdvertisementThingType.OTHER ? 'isIne' : 'isMovableOrImmovable'}`}
                                        alt='Žiadne fotografie'
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                    <Box className='w-100' sx={{mt: '3%'}}>
                        {props.advertisement.isTopped && (
                            <div className='background-primary'
                                 style={{
                                     borderRadius: '0px 90px 80px 39px / 0px 83px 85px 0px',
                                     textTransform: 'uppercase',
                                     maxWidth: 'min-content',
                                     padding: '5px 10px',
                                     marginLeft: '2%',
                                     marginTop: '5%'
                                 }}
                            >
                                TOP
                            </div>
                        )}
                        <div style={{
                            marginTop: props.advertisement.isTopped ? '2%' : '15%'
                        }}>
                            <p>
                                <RoomIcon style={{
                                    color: '#1851AB'
                                }}/> {props.advertisement.city?.name}
                            </p>
                            <CardContent className='grey-background w-100'
                                         style={{
                                             borderRadius: '0px 90px 80px 39px / 0px 83px 85px 0px',
                                         }}
                            >
                                <Typography
                                    variant='h5'
                                    component='div'
                                    style={{fontFamily: 'OpenSansBold'}}
                                    className='text-primary'
                                >
                                    {getSubject(`${props.advertisement.subject ?? ''}`).includes('...') ? (
                                        <Tooltip title={`${props.advertisement.subject ?? ''}`}>
                                            <span style={{fontFamily: 'OpenSansBold'}}>
                                                {getSubject(`${props.advertisement.subject ?? ''}`)}
                                            </span>
                                        </Tooltip>
                                    ) : (
                                        <span style={{fontFamily: 'OpenSansBold'}}>
                                            {getSubject(`${props.advertisement.subject ?? ''}`)}
                                        </span>
                                    )}
                                </Typography>
                                <Typography
                                    variant='body1'
                                    component='div'>
                                    {getDescription(`${props.advertisement.descriptionWithoutTablesHtml ?? ''}`).includes('...') ? (
                                        <Tooltip title={`${props.advertisement.descriptionWithoutAnyHtml ?? ''}`}>
                                            <span dangerouslySetInnerHTML={{__html: getDescription(`${props.advertisement.descriptionWithoutTablesHtml ?? ''}`)}}></span>
                                        </Tooltip>
                                    ) : (
                                        <span dangerouslySetInnerHTML={{__html: getDescription(`${props.advertisement.descriptionWithoutTablesHtml ?? ''}`)}}></span>
                                    )}
                                </Typography>
                            </CardContent>
                        </div>
                        <div
                            className='rounded-borders background-primary border-primary'
                            style={{padding: '5px 10px', width: 'max-content', marginLeft: '2%', marginTop: '2%'}}
                        >
                            {props.advertisement.isVPK ? 'Vyvolávacia cena' : 'Najnižšie podanie'}:&nbsp;
                            <span style={{fontFamily: 'OpenSansBold'}}>
                                {(props.advertisement.startingPrice !== null && props.advertisement.startingPrice !== undefined) ? (
                                    <span style={{fontFamily: 'OpenSansBold'}}>
                                        {props.advertisement.startingPrice?.price?.toFixed(2)} {props.advertisement.startingPrice?.currency}
                                    </span>
                                ) : (
                                    <span style={{fontFamily: 'OpenSansBold'}}>
                                        Na vyžiadanie
                                    </span>
                                )}
                            </span>
                        </div>
                        <div className="w-100">
                            <p style={{
                                marginLeft: '2%',
                                marginTop: '2%',
                            }}>
                                <CalendarMonthIcon style={{color: '#1851AB'}}/> &nbsp;
                                <span>
                                    {props.advertisement?.isVPK ? 'Lehota na predkladanie ponúk' : 'Dátum dražby'}: &nbsp;
                                </span>
                                {props.advertisement.dateOfProceeding !== null && (
                                    <span style={{fontFamily: 'OpenSansBold'}} className='text-primary'>
                                        {dayjs(props.advertisement.dateOfProceeding, 'DD.MM.YYYY HH:mm').format('DD.MM.YYYY HH:mm')}
                                    </span>
                                )}
                            </p>
                        </div>
                    </Box>
                </Box>
            ) : (
                <Grid container spacing={1}>
                    <Grid item xs={12} md={12}>
                        {(hasProtograph && protograph !== null) && (
                            <div style={{position: 'relative', width: '100%', maxWidth: '100%', height: '300px'}}>
                                <img
                                    style={{objectFit: 'cover', width: '100%', maxWidth: '100%', height: '100%'}}
                                    src={protograph.url !== null && protograph.url !== undefined ? getApiUrlFromRelativeUrl(protograph.url) : ''}
                                    alt={protograph.name || ''}
                                />
                                {props.advertisement.isTopped && (
                                    <div className='background-primary'
                                         style={{
                                             borderRadius: '50px',
                                             textTransform: 'uppercase',
                                             maxWidth: 'min-content',
                                             padding: '5px 10px',
                                             marginTop: '2%',
                                             position: 'absolute',
                                             top: '1%',
                                             right: '10px',
                                             zIndex: 0,
                                         }}
                                    >
                                        TOP
                                    </div>
                                )}
                            </div>
                        )}
                        {(!hasProtograph || protograph === null) && (
                            <div style={{position: 'relative', height: '100%', maxWidth: '100%'}}>
                                <div style={{width: '100%', height: '300px'}} className='background-primary'>
                                    <div className="text-center">
                                        <img
                                            src={
                                                props.advertisement.thingType === AdvertisementThingType.MOVABLE ? auta_kategoria_biele : props.advertisement.thingType === AdvertisementThingType.IMMOVABLE ? nehnutelne_veci_biele : iny_majetok_biele
                                            }
                                            style={{width: '300px'}}
                                            alt='Žiadne fotografie'
                                        />
                                    </div>
                                </div>
                                {props.advertisement.isTopped && (
                                    <div className='background-primary'
                                         style={{
                                             borderRadius: '50px',
                                             textTransform: 'uppercase',
                                             maxWidth: 'min-content',
                                             padding: '5px 10px',
                                             marginTop: '2%',
                                             position: 'absolute',
                                             top: '1%',
                                             right: '10px',
                                             zIndex: 0,
                                         }}
                                    >
                                        TOP
                                    </div>
                                )}
                            </div>
                        )}
                    </Grid>
                    <Grid item xs={12} md={12} style={{paddingTop: '0px'}}>
                        <CardContent style={{
                            borderRight: '1px solid lightgrey',
                            borderLeft: '1px solid lightgrey',
                            borderBottom: '1px solid lightgrey',
                            borderBottomRightRadius: '15px',
                            borderBottomLeftRadius: '15px'
                        }}>
                            <Typography
                                variant='h5'
                                component='div'
                                style={{fontFamily: 'OpenSansBold'}}
                                className='text-primary'
                            >
                                {props.advertisement.subject}
                            </Typography>
                            {props.advertisement.startingPrice !== null && (
                                <Typography
                                    variant='h5'
                                    component='div'
                                    style={{fontFamily: 'OpenSansBold'}}
                                    className='text-primary'
                                >
                                    {props.advertisement.startingPrice?.price?.toFixed(2)} {props.advertisement.startingPrice?.currency}
                                </Typography>
                            )}
                            <Typography
                                variant='body1'
                                component='div'>
                                {getDescription(`${props.advertisement.descriptionWithoutTablesHtml ?? ''}`).includes('...') ? (
                                    <Tooltip title={`${props.advertisement.descriptionWithoutAnyHtml ?? ''}`}>
                                        <span dangerouslySetInnerHTML={{__html: getDescription(`${props.advertisement.descriptionWithoutTablesHtml ?? ''}`)}}></span>
                                    </Tooltip>
                                ) : (
                                    <span dangerouslySetInnerHTML={{__html: getDescription(`${props.advertisement.descriptionWithoutTablesHtml ?? ''}`)}}></span>
                                )}
                            </Typography>
                            <p style={{fontSize: '15px'}}>
                                <RoomIcon style={{
                                    fontSize: '15px',
                                    color: '#1851AB'
                                }}/> {props.advertisement.city?.name}
                            </p>
                            <p style={{fontSize: '15px'}}>
                                <CalendarMonthIcon style={{fontSize: '15px', color: '#1851AB'}}/> &nbsp;
                                <span className='text-primary' style={{fontFamily: 'OpenSansBold'}}>
                                    {props.advertisement?.isVPK ? 'Lehota na predkladanie ponúk' : 'Dátum dražby'}: &nbsp;
                                </span>
                                {props.advertisement.dateOfProceeding !== null && (
                                    <span>
                                        {dayjs(props.advertisement.dateOfProceeding, 'DD.MM.YYYY HH:mm').format('DD.MM.YYYY HH:mm')}
                                    </span>
                                )}
                            </p>
                        </CardContent>
                    </Grid>
                </Grid>
            )}
        </Card>
    )
}
