import * as React from 'react'
import {AdvertisementCategoryInterface} from "../../types/AdvertisementCategoryInterface/AdvertisementCategoryInterface"
import {getApiUrlFromRelativeUrl, request} from "../../utils/api/ApiUtil"
import {Action, ClientContext} from "react-fetching-library"
import {DistrictInterface} from '../../types/District/DistrictInterface'
import {CityInterface} from '../../types/City/CityInterface'
import {toast} from 'react-toastify'
import Select from 'react-select'
import {DateTimePicker} from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import {dividePriceString, getPriceString, validatePriceString} from "../../utils/priceObject/PriceObjectUtil"
import {useNavigate} from "react-router-dom"
import {AdvertisementInterface} from "../../types/Advertisement/AdvertisementInterface"
import {AdvertisementAddOrEditFormProps} from "./types/AddOrEditFormProps"
import FileUpload from "../fileUpload/FileUpload"
import {Card, CardContent, Grid, Tooltip, Typography} from '@mui/material'
import {AuthContext} from "../../App"
import UserInterface from "../../types/User/UserInterface"
import Loading from "../loader/Loading"
import {ArrowDropDown} from "@mui/icons-material"
import ErrorComponent from "../error/ErrorComponent"
import {CKEditor} from '@ckeditor/ckeditor5-react'
import Editor from 'ckeditor5-custom-build/build/ckeditor'
import InfoIcon from '@mui/icons-material/Info'

enum AdvertisementThingType2 {
    MOVABLE = 1,
    IMMOVABLE = 2,
    OTHER = 3
}

interface Props {
    isAdd: boolean,
    canAddDocuments: boolean,
    canAddPhotographs: boolean,
    canAddYoutubeVideoEmbed: boolean,
    action: Action,
    advertisementId: number | null,
    advertisement: AdvertisementInterface | null,
    messageOnError: string,
    messageOnSuccess: string,
}

export default function AddOrEditAdvertisement(props: Props): JSX.Element {
    //- constants
    const INTERNAL_SERVER_ERROR_MESSAGE: string = 'Nastala neočakavaná chyba!'
    //- state
    const [advertisementCategories, setAdvertisementCategories] = React.useState<AdvertisementCategoryInterface[]>([])
    const [isAdvertisementCategoryRequired, setIsAdvertisementCategoryRequired] = React.useState<boolean>(true)
    const [districts, setDistricts] = React.useState<DistrictInterface[]>([])
    const [citys, setCitys] = React.useState<CityInterface[]>([])
    const [cityOptions, setCityOptions] = React.useState<{ value: number, label: string }[]>([])
    const [isLoading, setIsLoading] = React.useState<boolean>(true)
    const [isVPK, setIsVPK] = React.useState<boolean>(false)
    const [showAdvertisementUserData, setShowAdvertisementUserData] = React.useState<boolean>(false)
    const [formData, setFormData] = React.useState<AdvertisementAddOrEditFormProps>({
        type: AdvertisementThingType2.IMMOVABLE,
        subject: null,
        description: null,
        category: null,
        district: null,
        city: null,
        address: null,
        houseNumber: null,
        startingPrice: null,
        deposit: null,
        firstInspection: null,
        secondInspection: null,
        dateOfProceeding: null,
        placeOfProceeding: null,
        placeOfProceedingString: null,
        youtubeVideoEmbed: null,
        userId: 0,
        sendEmail: false,
        subjectOfAdvertisement: null,
    })
    const [selectedCity, setSelectedCity] = React.useState<{ value: number, label: string } | null>(null)
    const selectRefs = React.useRef<(HTMLSelectElement | null)[]>([])
    const selectContainers = React.useRef<(HTMLDivElement | null)[]>([])
    const [advertisement, setAdvertisement] = React.useState<AdvertisementInterface | null>(props.advertisement)
    const [canUploadPhotographs, setCanUploadPhotographs] = React.useState<boolean>(false)
    const [arePhotographsUploaded, setArePhotographsUploaded] = React.useState<boolean>(false)
    const [canUploadDocuments, setCanUploadDocuments] = React.useState<boolean>(false)
    const [areDocumentsUploaded, setAreDocumentsUploaded] = React.useState<boolean>(false)
    const [documents, setDocuments] = React.useState<File[]>([])
    const [photographs, setPhotographs] = React.useState<File[]>([])
    const [users, setUsers] = React.useState<UserInterface[]>([])
    const errorsInitialState: AdvertisementAddOrEditFormErrorProps = {
        typeErrors: [],
        subjectErrors: [],
        descriptionErrors: [],
        subjectOfAdvertisementErrors: [],
        categoryErrors: [],
        districtErrors: [],
        cityErrors: [],
        addressErrors: [],
        houseNumberErrors: [],
        startingPriceErrors: [],
        depositErrors: [],
        firstInspectionErrors: [],
        secondInspectionErrors: [],
        dateOfProceedingErrors: [],
        placeOfProceedingErrors: [],
        youtubeVideoEmbedErrors: [],
        userIdErrors: [],
    }

    const [errors, setErrors] = React.useState<AdvertisementAddOrEditFormErrorProps>(errorsInitialState)
    const [isError, setIsError] = React.useState<boolean>(false)

    const {
        typeErrors,
        subjectErrors,
        descriptionErrors,
        subjectOfAdvertisementErrors,
        categoryErrors,
        districtErrors,
        cityErrors,
        addressErrors,
        houseNumberErrors,
        startingPriceErrors,
        depositErrors,
        firstInspectionErrors,
        secondInspectionErrors,
        dateOfProceedingErrors,
        placeOfProceedingErrors,
        youtubeVideoEmbedErrors,
        userIdErrors
    } = errors

    const {
        type,
        subject,
        description,
        category,
        district,
        city,
        address,
        houseNumber,
        startingPrice,
        deposit,
        firstInspection,
        secondInspection,
        dateOfProceeding,
        placeOfProceeding,
        placeOfProceedingString,
        youtubeVideoEmbed,
        subjectOfAdvertisement,
        userId,
        sendEmail
    } = formData

    //- context
    const clientContext = React.useContext(ClientContext)
    const authContext = React.useContext(AuthContext)
    const navigate = useNavigate()
    //- actions
    const getCategoriesAction: Action = {
        endpoint: getApiUrlFromRelativeUrl(`/api/advertisement/category/get/all/notPaginated/${type}`), // zeby podla toho co mas vybrate ako 'type' tak by ti nacitalo ine kategorie? - ako keby recalc
        method: 'GET',
        headers: {
            Accept: 'application/json'
        },
    }

    const getAllLocationsAction: Action = {
        endpoint: getApiUrlFromRelativeUrl('/api/locations/all'),
        method: 'GET',
        headers: {
            Accept: 'application/json'
        }
    }

    const getUsersAllAction: Action = {
        endpoint: getApiUrlFromRelativeUrl('/api/user/notPaginated/all'),
        method: 'GET',
        headers: {
            Accept: 'application/json'
        }
    }

    const getAdvertisementAction: Action = {
        endpoint: getApiUrlFromRelativeUrl(`/api/advertisement/${props.advertisementId ?? 0}`),
        method: 'GET',
        headers: {
            Accept: 'application/json'
        }
    }

    const handleSelectContainerClick = (index: number) => {
        if (selectRefs.current[index]) {
            // Simulate a click event on the select element to open the dropdown
            const clickEvent = new MouseEvent('mousedown', {bubbles: true})
            selectRefs.current[index]!.dispatchEvent(clickEvent)
        }
    }

    const handleCityChange = (selectedCity: { value: number, label: string } | null) => {
        setSelectedCity(selectedCity)
    }

    React.useEffect(() => {
        const getAdvertisement = async () => {
            if (!props.isAdd) {
                const {payload, status, error} = await request(getAdvertisementAction, clientContext)
                if (error) {
                    setIsError(true)
                }
                if (status === 401) {
                    authContext.logout()
                }
                if (status === 200) {
                    setFormData({
                        ...formData,
                        type: payload.thingType ?? AdvertisementThingType2.IMMOVABLE,
                        subject: payload?.subject ?? null,
                        description: payload?.description ?? null,
                        category: payload?.advertisementCategory?.id ?? null,
                        district: payload?.district?.id ?? null,
                        city: payload?.city,
                        address: payload?.address ?? null,
                        houseNumber: payload?.houseNumber ?? null,
                        startingPrice: getPriceString(payload?.startingPrice ?? null, false) ?? null,
                        deposit: getPriceString(payload?.depositPrice ?? null, false) ?? payload.data?.depositPriceString,
                        firstInspection: payload?.firstInspection?.dateFrom !== null ? payload?.firstInspection?.dateFrom : null,
                        secondInspection: payload?.secondInspection?.dateFrom !== null ? payload?.secondInspection?.dateFrom : null,
                        dateOfProceeding: payload?.dateOfProceeding !== null ? payload.dateOfProceeding : null,
                        placeOfProceeding: payload?.placeOfProceeding?.id ?? null,
                        placeOfProceedingString: payload?.placeOfProceedingString ?? null,
                        youtubeVideoEmbed: payload?.youtubeVideoEmbed ?? null,
                        subjectOfAdvertisement: payload?.subjectOfAdvertisement ?? null
                    })
                    const newSelectedCity: { value: number, label: string } = {
                        value: payload?.city?.id ?? 0,
                        label: payload?.city?.name ?? ''
                    }
                    setSelectedCity(newSelectedCity)
                    setIsVPK(payload?.isVPK)
                    setShowAdvertisementUserData(payload?.showAdvertisementUserData)
                }
            }
        }
        getAdvertisement()
    }, [])

    //- effect
    React.useEffect(() => {
        const getUsers = async () => {
            setIsLoading(true)
            const {error, payload} = await request(getUsersAllAction, clientContext)
            //- handle error
            if (error) {
                setIsError(true)
            }
            //- set payload
            if (payload) {
                setUsers(payload.data.pagination)
            }
            setIsLoading(false)
        }
        if (authContext.userLogged?.canAssignAdvertisementToUser) { // right
            getUsers()
        }
    }, [authContext.userLogged])

    //- effect
    React.useEffect(() => {
        //- locations
        const getLocationsAll = async () => {
            setIsLoading(true)

            const {error, status, payload} = await request(getAllLocationsAction, clientContext)
            if (error) {
                setIsError(true)
            }
            if (payload) {
                setDistricts(payload.districts)
                setCitys(payload.cities)
                const formattedOptions = payload.cities.map((city: CityInterface) => ({
                    value: city.id,
                    label: city.name,
                }));
                setCityOptions(formattedOptions)
            }
            setIsLoading(false)
        }

        //- request to api
        getLocationsAll()
    }, [])

    //- effect - recalc categories podla type
    React.useEffect(() => {
        //- categories
        const getCategories = async () => {
            setIsLoading(true)
            const {error, payload, status} = await request(getCategoriesAction, clientContext)
            // handle error
            if (error) {
                setIsError(true)
            }
            if (status === 200) {
                setAdvertisementCategories(payload)
                setIsAdvertisementCategoryRequired(payload.length > 0)
            }
            setIsLoading(false)
        }

        if (type === AdvertisementThingType2.IMMOVABLE || type === AdvertisementThingType2.MOVABLE || type === AdvertisementThingType2.OTHER) {
            getCategories()
        }
    }, [type])

    //-- effects - files
    React.useEffect(() => {
        if (areDocumentsUploaded) {
            setCanUploadDocuments(false)
            if (photographs.length > 0) {
                setCanUploadPhotographs(true)
            } else {
                setArePhotographsUploaded(true)
            }
        }
    }, [areDocumentsUploaded])

    React.useEffect(() => {
        if (arePhotographsUploaded) {
            setCanUploadPhotographs(false)
            if (props.isAdd) {
                navigate(`/advertisement/created/pickPackage/${advertisement!.id}`)
            } else {
                navigate(`/advertisement/view/${advertisement!.id}`)
            }
            toast.success(props.messageOnSuccess)
        }
    }, [arePhotographsUploaded])

    // handlers
    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        //- recalc kategorii podla type
        if (e.target.name === 'type') {
            //- ak je to nehnuteľnosť alebo pohyblivá vec tak zmenime kategorie
            if (parseInt(e.target.value) === AdvertisementThingType2.IMMOVABLE || parseInt(e.target.value) === AdvertisementThingType2.MOVABLE) {
                setFormData({...formData, [e.target.name]: parseInt(e.target.value), category: null})
            } else {
                //- ak je to ine tak nechame kategoriu na tom co je nasetovane
                setFormData({...formData, [e.target.name]: parseInt(e.target.value)})
            }
        } else {
            //- ostatne inputy
            setFormData({...formData, [e.target.name]: e.target.value})
        }
    }

    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        const startingPriceObject = dividePriceString(startingPrice)
        const depositPriceObject = dividePriceString(deposit)
        setIsLoading(true)
        const startingPriceErrorsNew: string[] = []
        const depositErrorsNew: string[] = []
        // validate
        if (validatePriceString(startingPrice, isVPK, false) !== true || validatePriceString(deposit, isVPK, true) !== true) {
            if (validatePriceString(startingPrice, isVPK, false) !== true) {
                startingPriceErrorsNew.push(`${validatePriceString(startingPrice, isVPK, false)}`)
            }
            if (validatePriceString(deposit, isVPK, true) !== true) {
                depositErrorsNew.push(`${validatePriceString(deposit, isVPK, true)}`)
            }
            //-- vypiseme errory
            setErrors({
                ...errors,
                startingPriceErrors: startingPriceErrorsNew,
                depositErrors: depositErrorsNew
            })
            setIsLoading(false)
            toast.error(props.messageOnError)
            return
        }
        //-- vycistime errory
        setErrors({
            ...errors,
            startingPriceErrors: startingPriceErrorsNew,
            depositErrors: depositErrorsNew
        })
        const action2 = {
            ...props.action,
            body: {
                subject,
                description,
                districtId: district !== null ? parseInt(`${district ?? ''}`) : null,
                address,
                houseNumber,
                thingType: type,
                dateOfProceeding: null,
                advertisementCategoryId: type !== AdvertisementThingType2.OTHER ? parseInt(`${category !== null ? category : ''}`) : null,
                placeOfProceedingString: placeOfProceedingString,
                assignAdvertisementToUser: userId !== 0,
                userId: userId !== 0 ? userId : null,
                sendEmailToUserAboutAdvertisementAssigment: sendEmail,
                isVPK: isVPK,
                showAdvertisementUserData: showAdvertisementUserData,
                youtubeVideoEmbed: youtubeVideoEmbed,
                subjectOfAdvertisement: subjectOfAdvertisement,
            }
        }
        //-- city id
        if (selectedCity !== null) {
            // @ts-ignore
            action2.body.cityId = selectedCity.value ?? null
        }
        //- first inspection
        if (firstInspection !== null && firstInspection !== undefined) {
            // @ts-ignore
            action2.body.firstInspection = {
                dateFrom: dayjs(firstInspection, 'DD.MM.YYYY HH:mm:ss').format('DD.MM.YYYY HH:mm:ss'),
                dateTill: dayjs(firstInspection, 'DD.MM.YYYY HH:mm:ss').format('DD.MM.YYYY HH:mm:ss'),
            }
        }
        //- second inspection
        if (secondInspection !== null && secondInspection !== undefined) {
            // @ts-ignore
            action2.body.secondInspection = {
                dateFrom: dayjs(secondInspection, 'DD.MM.YYYY HH:mm:ss').format('DD.MM.YYYY HH:mm:ss'),
                dateTill: dayjs(secondInspection, 'DD.MM.YYYY HH:mm:ss').format('DD.MM.YYYY HH:mm:ss')
            }
        }
        //- date of proceeding
        if (dateOfProceeding !== null) {
            // @ts-ignore
            action2.body.dateOfProceeding = dayjs(dateOfProceeding, 'DD.MM.YYYY HH:mm:ss').format('DD.MM.YYYY HH:mm:ss')
        }
        if (startingPriceObject.price !== null && startingPriceObject.currency !== null) {
            // @ts-ignore
            action2.body.startingPrice = {
                price: parseFloat(startingPriceObject.price.toFixed(2)),
                currency: startingPriceObject.currency
            }
        }
        if (depositPriceObject.price !== null && depositPriceObject.currency !== null) {
            // @ts-ignore
            action2.body.depositPrice = {
                price: parseFloat(depositPriceObject.price.toFixed(2)),
                currency: depositPriceObject.currency
            }
        }
        if (isVPK && (depositPriceObject.price === null && depositPriceObject.currency === null)) {
            // @ts-ignore
            action2.body.depositPriceString = deposit
        }
        const {error, status, payload} = await request(action2, clientContext)
        // handle error
        if (error && status === 500) {
            setIsError(true)
        }
        if (status === 401) {
            //- ak prisla odpoved zo servera, ze user nie je autorizovany, tak ho odhlasime
            authContext.logout()
        }
        if (payload.success) {
            setAdvertisement(payload.data)
            setIsLoading(false)
            if (documents.length > 0) {
                setCanUploadDocuments(true)
            } else {
                setAreDocumentsUploaded(true)
            }
        } else {
            toast.error(props.messageOnError)
            // vycistime errory
            const typeErrorsNew: string[] = []
            const subjectErrorsNew: string[] = []
            const descriptionErrorsNew: string[] = []
            const categoryErrorsNew: string[] = []
            const districtErrorsNew: string[] = []
            const cityErrorsNew: string[] = []
            const addressErrorsNew: string[] = []
            const houseNumberErrorsNew: string[] = []
            const startingPriceErrorsNew: string[] = []
            const depositErrorsNew: string[] = []
            const placeOfProceedingErrorsNew: string[] = []
            const firstInspectionErrorsNew: string[] = []
            const secondInspectionErrorsNew: string[] = []
            const dateOfProceedingErrorsNew: string[] = []
            const youtubeVideoEmbedErrorsNew: string[] = []
            const userIdErrorsNew: string[] = []
            const subjectOfAdvertisementErrorsNew: string[] = []
            //- zistime ktore errory su
            for (let key in payload.errors) {
                switch (key) { // takyto krepy switch zatial, mozno v buducnosti prepisat na nieco rozumnejsie
                    case 'type':
                        typeErrorsNew.push(payload.errors[key])
                        break
                    case 'subject':
                        subjectErrorsNew.push(payload.errors[key])
                        break
                    case 'description':
                        descriptionErrorsNew.push(payload.errors[key])
                        break
                    case 'category':
                        categoryErrorsNew.push(payload.errors[key])
                        break
                    case 'district':
                        districtErrorsNew.push(payload.errors[key])
                        break
                    case 'city':
                        cityErrorsNew.push(payload.errors[key])
                        break
                    case 'address':
                        addressErrorsNew.push(payload.errors[key])
                        break
                    case 'houseNumber':
                        houseNumberErrorsNew.push(payload.errors[key])
                        break
                    case 'startingPrice':
                        startingPriceErrorsNew.push(payload.errors[key])
                        break
                    case 'depositPrice':
                        depositErrorsNew.push(payload.errors[key])
                        break
                    case 'firstInspection':
                        firstInspectionErrorsNew.push(payload.errors[key])
                        break
                    case 'secondInspection':
                        secondInspectionErrorsNew.push(payload.errors[key])
                        break
                    case 'dateOfProceeding':
                        dateOfProceedingErrorsNew.push(payload.errors[key])
                        break
                    case 'placeOfProceedingString':
                        placeOfProceedingErrorsNew.push(payload.errors[key])
                        break
                    case 'userToAssign':
                        userIdErrorsNew.push(payload.errors[key])
                        break
                    case 'youtubeVideoEmbed':
                        youtubeVideoEmbedErrorsNew.push(payload.errors[key])
                        break
                    case 'subjectOfAdvertisement':
                        subjectOfAdvertisementErrorsNew.push(payload.errors[key])
                        break
                    default:
                        break
                }
                //- nastavime errory do state
                setErrors({
                    typeErrors: typeErrorsNew,
                    subjectErrors: subjectErrorsNew,
                    descriptionErrors: descriptionErrorsNew,
                    subjectOfAdvertisementErrors: subjectOfAdvertisementErrorsNew,
                    categoryErrors: categoryErrorsNew,
                    districtErrors: districtErrorsNew,
                    cityErrors: cityErrorsNew,
                    addressErrors: addressErrorsNew,
                    houseNumberErrors: houseNumberErrorsNew,
                    startingPriceErrors: startingPriceErrorsNew,
                    depositErrors: depositErrorsNew,
                    firstInspectionErrors: firstInspectionErrorsNew,
                    secondInspectionErrors: secondInspectionErrorsNew,
                    dateOfProceedingErrors: dateOfProceedingErrorsNew,
                    placeOfProceedingErrors: placeOfProceedingErrorsNew,
                    youtubeVideoEmbedErrors: youtubeVideoEmbedErrorsNew,
                    userIdErrors: userIdErrorsNew,
                })
            }
        }
        setIsLoading(false)
    }


    //- nemozme usera pustit dalej, ak padol niektory request na server
    if (isError) {
        return (
            <ErrorComponent
                message={INTERNAL_SERVER_ERROR_MESSAGE}
            />
        )
    }

    return (
        <React.Fragment>
            {isLoading && <Loading/>}
            <Typography variant='h4' style={{fontFamily: 'OpenSansSemiBold'}} className='text-primary'>
                {subject}
            </Typography>
            <div className="w-100">
                <div className="form-group">
                    <label htmlFor="isVPK" className='text-primary' style={{fontFamily: 'OpenSansBold'}}>
                        <input type="checkbox" name="isVPK" id="isVPK" checked={isVPK}
                               onChange={(e: React.ChangeEvent<HTMLInputElement>) => setIsVPK(e.target.checked)}/>
                        &nbsp;Inzerát je verejné ponukové konanie
                    </label>
                </div>
                <div className="form-group">
                    <label htmlFor="showAdvertisementUserData" className='text-primary'
                           style={{fontFamily: 'OpenSansBold'}}>
                        <input type="checkbox" name="showAdvertisementUserData" id="showAdvertisementUserData"
                               checked={showAdvertisementUserData}
                               onChange={(e: React.ChangeEvent<HTMLInputElement>) => setShowAdvertisementUserData(e.target.checked)}/>
                        &nbsp;Zobraziť údaje o organizátorovi {isVPK ? 'verejného ponukového konania' : 'dražby'} pri
                        zobrazení
                    </label>
                </div>
            </div>
            <form onSubmit={onSubmit}>
                <Card sx={{minWidth: 300, border: 'none', boxShadow: 'none', overflow: 'visible'}}>
                    <CardContent className='grey-background'
                                 style={{borderRadius: '15px'}}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={3}>
                                <div className="form-group">
                                    <label htmlFor="subject" style={{fontFamily: 'OpenSansBold'}}
                                           className='text-primary required'>
                                        Nadpis inzerátu
                                    </label>
                                    <input
                                        type="text"
                                        name="subject"
                                        id="subject"
                                        value={subject ?? ''}
                                        className={`${subjectErrors.length > 0 ? 'error' : ''} form-control rounded-borders border-primary`}
                                        onChange={onChange}
                                    />
                                    <div>
                                        <div>
                                            {subjectErrors.map((error: string, index: number) => (
                                                <p key={index} className='error'>
                                                    {error}
                                                </p>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <div className="form-group">
                                    <label htmlFor="type" style={{fontFamily: 'OpenSansBold'}}
                                           className='text-primary required'>
                                        Druh
                                    </label>
                                    <div className='select-container'
                                         ref={(el) => (selectContainers.current[0] = el)}
                                         onClick={() => handleSelectContainerClick(0)}
                                    >
                                        <select
                                            name="type"
                                            id="type"
                                            className={`${typeErrors.length > 0 ? 'error' : ''} form-control rounded-borders border-primary`}
                                            value={type ?? AdvertisementThingType2.IMMOVABLE}
                                            onChange={onChange}
                                        >
                                            <option value="0"></option>
                                            <option value={AdvertisementThingType2.IMMOVABLE}>
                                                Nehnuteľnosti
                                            </option>
                                            <option value={AdvertisementThingType2.MOVABLE}>
                                                Hnuteľnosti
                                            </option>
                                            <option value={AdvertisementThingType2.OTHER}>
                                                Iný majetok
                                            </option>
                                        </select>
                                        <ArrowDropDown className='arrow-icon icon'/>
                                    </div>
                                    <div>
                                        <div>
                                            {typeErrors.map((error: string, index: number) => (
                                                <p key={index} className='error'>
                                                    {error}
                                                </p>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <div className="form-group">
                                    <label htmlFor="category" style={{fontFamily: 'OpenSansBold'}}
                                           className={`text-primary ${isAdvertisementCategoryRequired ? 'required' : ''}`}>
                                        Typ
                                    </label>
                                    <div className='select-container'
                                         ref={(el) => (selectContainers.current[1] = el)}
                                         onClick={() => handleSelectContainerClick(1)}
                                    >
                                        <select
                                            name="category"
                                            id="category"
                                            className={`${categoryErrors.length > 0 ? 'error' : ''} form-control rounded-borders border-primary`}
                                            value={category ?? 0}
                                            onChange={onChange}
                                        >
                                            <option value="0"></option>
                                            {advertisementCategories.map((advertisementCategory: AdvertisementCategoryInterface) => (
                                                <option value={advertisementCategory.id} key={advertisementCategory.id}>
                                                    {advertisementCategory.name}
                                                </option>
                                            ))}
                                        </select>
                                        <ArrowDropDown className='arrow-icon icon'/>
                                    </div>
                                    <div>
                                        <div>
                                            {categoryErrors.map((error: string, index: number) => (
                                                <p key={index} className='error'>
                                                    {error}
                                                </p>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <div className="form-group">
                                    <label htmlFor="district" style={{fontFamily: 'OpenSansBold'}}
                                           className='text-primary required'>
                                        Okres
                                    </label>
                                    <div className='select-container'
                                         ref={(el) => (selectContainers.current[2] = el)}
                                         onClick={() => handleSelectContainerClick(2)}
                                    >
                                        <select name="district"
                                                id="district"
                                                className={`${districtErrors.length > 0 ? 'error' : ''} form-control rounded-borders border-primary `}
                                                value={district ?? 0}
                                                onChange={onChange}>
                                            <option value="0"></option>
                                            {districts.map((district: DistrictInterface) => (
                                                <option value={district.id} key={district.id}>
                                                    {district.name}
                                                </option>
                                            ))}
                                        </select>
                                        <ArrowDropDown className='arrow-icon icon'/>
                                    </div>
                                    <div>
                                        <div>
                                            {districtErrors.map((error: string, index: number) => (
                                                <p key={index} className='error'>
                                                    {error}
                                                </p>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <div className="form-group">
                                    <label htmlFor="city" style={{fontFamily: 'OpenSansBold'}}
                                           className='text-primary required'>
                                        Mesto/obec
                                    </label>
                                    <Select
                                        className="basic-single"
                                        classNamePrefix="select"
                                        isDisabled={false}
                                        onChange={handleCityChange}
                                        isMulti={false}
                                        isLoading={isLoading}
                                        isClearable={true}
                                        isSearchable={true}
                                        name="city"
                                        options={[
                                            {
                                                label: 'Mestá',
                                                options: [
                                                    ...cityOptions
                                                ]
                                            }
                                        ]}
                                        value={selectedCity}
                                        placeholder='Vyberte mesto'
                                    />
                                    <div>
                                        <div>
                                            {cityErrors.map((error: string, index: number) => (
                                                <p key={index} className='error'>
                                                    {error}
                                                </p>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <div className="form-group">
                                    <label htmlFor="address" style={{fontFamily: 'OpenSansBold'}}
                                           className='text-primary'>
                                        Ulica
                                    </label>
                                    <input type="text" name="address" id="address"
                                           className={`${addressErrors.length > 0 ? 'error' : ''} form-control rounded-borders border-primary`}
                                           value={address ?? ''}
                                           onChange={onChange}/>
                                    <div>
                                        <div>
                                            {addressErrors.map((error: string, index: number) => (
                                                <p key={index} className='error'>
                                                    {error}
                                                </p>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <div className="form-group">
                                    <label htmlFor="houseNumber" style={{fontFamily: 'OpenSansBold'}}
                                           className='text-primary'>
                                        Súpisné číslo
                                    </label>
                                    <input type="text" name="houseNumber" id="houseNumber"
                                           className={`${houseNumberErrors.length > 0 ? 'error' : ''} form-control rounded-borders border-primary`}
                                           value={houseNumber ?? ''} onChange={onChange}/>
                                    <div>
                                        <div>
                                            {houseNumberErrors.map((error: string, index: number) => (
                                                <p key={index} className='error'>
                                                    {error}
                                                </p>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
                <Card sx={{minWidth: 300, border: 'none', boxShadow: 'none', marginTop: '5%'}}>
                    <CardContent className='grey-background'
                                 style={{borderRadius: '15px'}}>
                        <Grid container spacing={2}>
                            <Grid item md={6} xs={12}>
                                <div className="form-group">
                                    <label htmlFor="startingPrice" style={{fontFamily: 'OpenSansBold'}}
                                           className={`text-primary ${isVPK ? '' : 'required'}`}>
                                        {isVPK ? 'Vyvolávacia cena' : 'Najnižšie podanie'}
                                    </label>
                                    <input type="text" name="startingPrice" id="startingPrice"
                                           className={`${startingPriceErrors.length > 0 ? 'error' : ''} form-control rounded-borders border-primary`}
                                           value={startingPrice ?? ''} onChange={onChange}/>
                                    <div>
                                        <div>
                                            {startingPriceErrors.map((error: string, index: number) => (
                                                <p key={index} className='error'>
                                                    {error}
                                                </p>
                                            ))}
                                        </div>
                                        <div>
                                            <small>
                                                Cenu zadavajte bez medzery a bez meny
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <div className="form-group" style={{marginLeft: 'auto'}}>
                                    <label htmlFor="deposit" style={{fontFamily: 'OpenSansBold'}}
                                           className={`text-primary ${isVPK ? '' : 'required'}`}>
                                        Zábezpeka
                                    </label>
                                    <input type="text" name="deposit" id="deposit"
                                           className={`${depositErrors.length > 0 ? 'error' : ''} form-control rounded-borders border-primary `}
                                           value={deposit ?? ''}
                                           onChange={onChange}/>
                                    <div>
                                        <div>
                                            {depositErrors.map((error: string, index: number) => (
                                                <p key={index} className='error'>
                                                    {error}
                                                </p>
                                            ))}
                                        </div>
                                        <div>
                                            <small>
                                                Cenu zadavajte bez medzery a bez meny
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
                <Card sx={{minWidth: 300, border: 'none', boxShadow: 'none', marginTop: '5%'}}>
                    <CardContent className='grey-background'
                                 style={{borderRadius: '15px'}}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={3}>
                                <div className="form-group">
                                    <label htmlFor="firstInspection" style={{fontFamily: 'OpenSansBold'}}
                                           className={`text-primary ${isVPK ? '' : 'required'}`}>
                                        1. Obhliadka
                                    </label>
                                    <DateTimePicker
                                        value={dayjs(firstInspection, 'DD.MM.YYYY')}
                                        className={`${firstInspectionErrors.length > 0 ? 'error' : ''} form-control rounded-borders border-primary`}
                                        onChange={(newDate) => setFormData({...formData, ['firstInspection']: newDate})}
                                        slotProps={{
                                            textField: {
                                                size: 'small',
                                                variant: 'standard',
                                                InputProps: {
                                                    disableUnderline: true,
                                                }
                                            }
                                        }}
                                    />
                                    <div>
                                        <div>
                                            {firstInspectionErrors.map((error: string, index: number) => (
                                                <p key={index} className='error'>
                                                    {error}
                                                </p>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <div className="form-group" style={{marginLeft: 'auto'}}>
                                    <label htmlFor="secondInspection" style={{fontFamily: 'OpenSansBold'}}
                                           className='text-primary'>
                                        2. Obhliadka
                                    </label>
                                    <DateTimePicker
                                        value={dayjs(secondInspection, 'DD.MM.YYYY')}
                                        className={`${secondInspectionErrors.length > 0 ? 'error' : ''} form-control rounded-borders border-primary`}
                                        onChange={(newDate) => setFormData({
                                            ...formData,
                                            ['secondInspection']: newDate
                                        })}
                                        slotProps={{
                                            textField: {
                                                size: 'small',
                                                variant: 'standard',
                                                InputProps: {
                                                    disableUnderline: true,
                                                }
                                            }
                                        }}
                                    />
                                    <div>
                                        <div>
                                            {secondInspectionErrors.map((error: string, index: number) => (
                                                <p key={index} className='error'>
                                                    {error}
                                                </p>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <div className="form-group">
                                    <label htmlFor="dateOfProceeding" style={{fontFamily: 'OpenSansBold'}}
                                           className='text-primary required'>
                                        {isVPK ? 'Lehota na predkladanie ponúk' : 'Dátum dražby'}
                                    </label>
                                    <DateTimePicker
                                        value={dayjs(dateOfProceeding, 'DD.MM.YYYY HH:mm:ss')}
                                        className={`${dateOfProceedingErrors.length > 0 ? 'error' : ''} form-control rounded-borders border-primary`}
                                        onChange={(newDate) => setFormData({
                                            ...formData,
                                            ['dateOfProceeding']: newDate
                                        })}
                                        slotProps={{
                                            textField: {
                                                variant: 'standard',
                                                InputProps: {
                                                    disableUnderline: true,
                                                }
                                            }
                                        }}
                                    />
                                    <div>
                                        <div>
                                            {dateOfProceedingErrors.map((error: string, index: number) => (
                                                <p key={index} className='error'>
                                                    {error}
                                                </p>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <div className="form-group">
                                    <label htmlFor="placeOfProceedingString" style={{fontFamily: 'OpenSansBold'}}
                                           className='text-primary required'>
                                        {isVPK ? 'Predkladanie ponúk' : 'Miesto dražby'}
                                    </label>
                                    <input
                                        type="text"
                                        value={placeOfProceedingString ?? ''}
                                        className={`${placeOfProceedingErrors.length > 0 ? 'error' : ''} form-control rounded-borders border-primary`}
                                        onChange={(e) => setFormData({
                                            ...formData,
                                            ['placeOfProceedingString']: e.target.value
                                        })}
                                    />
                                    <div>
                                        <div>
                                            {placeOfProceedingErrors.map((error: string, index: number) => (
                                                <p key={index} className='error'>
                                                    {error}
                                                </p>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{mt: 1}}>
                            <Grid item xs={12} md={12}>
                                <div className="form-group">
                                    <label htmlFor="description" style={{fontFamily: 'OpenSansBold'}}
                                           className='text-primary required'>
                                        Predmet {isVPK ? 'verejného ponukového konania' : 'dražby'}
                                    </label>
                                    <CKEditor
                                        editor={Editor}
                                        data={subjectOfAdvertisement ?? ''}
                                        onChange={(event, editor): void => {
                                            setFormData((prevFormData) => ({
                                                ...prevFormData,
                                                ['subjectOfAdvertisement']: editor.getData()
                                            }))
                                        }}
                                    />
                                    <div>
                                        <div>
                                            {subjectOfAdvertisementErrors.map((error: string, index: number) => (
                                                <p key={index} className='error'>
                                                    {error}
                                                </p>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
                <Card sx={{minWidth: 300, border: 'none', boxShadow: 'none', marginTop: '5%'}}>
                    <CardContent className='grey-background'
                                 style={{borderRadius: '15px', minHeight: '300px'}}>
                        <div className="form-group">
                            <label htmlFor="description" style={{fontFamily: 'OpenSansBold'}}
                                   className='text-primary required'>
                                Pridať popis
                            </label>
                            <CKEditor
                                editor={Editor}
                                data={description ?? ''}
                                onChange={(event, editor): void => {
                                    setFormData((prevFormData) => ({
                                        ...prevFormData,
                                        ['description']: editor.getData()
                                    }))
                                }}
                            />
                            <div>
                                <div>
                                    {descriptionErrors.map((error: string, index: number) => (
                                        <p key={index} className='error'>
                                            {error}
                                        </p>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </CardContent>
                </Card>
                {props.canAddYoutubeVideoEmbed && (
                    <Card sx={{minWidth: 300, border: 'none', boxShadow: 'none', marginTop: '5%'}}>
                        <CardContent className='grey-background'
                                     style={{borderRadius: '15px'}}>
                            <div className="form-group">
                                <label htmlFor="youtubeVideoEmbed" style={{fontFamily: 'OpenSansBold'}}
                                       className='text-primary mb-2'>
                                    Vložiť youtube video
                                    <Tooltip
                                        title="Vyberte si video na youtube, ktoré sem chcete vložiť, kliknite naňho,
                                         tam bude tlačidlo 'Zdielať', po kliknutí naň uvidíte 'Vložiť'
                                          po kliknutí naň uvidíte kód, ktorý treba vložiť sem"
                                    >
                                        <InfoIcon className='icon ml-2'/>
                                    </Tooltip>
                                </label>
                                <textarea name="youtubeVideoEmbed" id="youtubeVideoEmbed"
                                          className={`${youtubeVideoEmbedErrors.length > 0 ? 'error' : ''} form-control rounded-borders border-primary`}
                                          value={youtubeVideoEmbed ?? ''}
                                          rows={5}
                                          placeholder={'Vložte youtube video'}
                                          onChange={onChange}/>
                                <div>
                                    <div>
                                        {youtubeVideoEmbedErrors.map((error: string, index: number) => (
                                            <p key={index} className='error'>
                                                {error}
                                            </p>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                )}
                {(props.isAdd && authContext.userLogged?.canAssignAdvertisementToUser) && (
                    <Card sx={{minWidth: 300, border: 'none', boxShadow: 'none', marginTop: '5%'}}>
                        <CardContent className='grey-background'
                                     style={{borderRadius: '15px'}}>
                            <div className="form-group">
                                <label htmlFor="description" style={{fontFamily: 'OpenSansBold'}}
                                       className='text-primary'>
                                    Priradiť inzerát použivateľovi
                                </label>
                                <div className='select-container'
                                     ref={(el) => (selectContainers.current[5] = el)}
                                     onClick={() => handleSelectContainerClick(5)}
                                >
                                    <select
                                        name="userId"
                                        id="userId"
                                        className={`${userIdErrors.length > 0 ? 'error' : ''} form-control rounded-borders border-primary`}
                                        value={userId}
                                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setFormData({
                                            ...formData,
                                            ['userId']: parseInt(e.target.value)
                                        })}
                                    >
                                        <option value="0"></option>
                                        {users.map((user: UserInterface) => (
                                            <option key={user.id}
                                                    value={user.id}>{user.title} {user.firstName} {user.lastName}</option>
                                        ))}
                                    </select>
                                    <ArrowDropDown className='arrow-icon icon'/>
                                </div>
                                <div>
                                    <div>
                                        {typeErrors.map((error: string, index: number) => (
                                            <p key={index} className='error'>
                                                {error}
                                            </p>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                )}

                <Grid container spacing={2}>
                    {props.canAddDocuments && (
                        <Grid item xs={12} md={6}>
                            <div className="form-group">
                                {/* nahrat dokumenty - prilohy */}
                                <FileUpload
                                    maxAllowedFilesCount={100}
                                    acceptedExtensions={['pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx']}
                                    checkMaxSize={true}
                                    maxSize={parseInt(process.env.REACT_APP_DOCUMENTS_FILE_MAX_SIZE ?? '0')}
                                    files={documents}
                                    setFiles={(files: File[], isRemove: boolean) => isRemove ? setDocuments(files) : setDocuments([...documents, ...files])}
                                    canUpload={canUploadDocuments}
                                    apiUrl={getApiUrlFromRelativeUrl(`/api/secured/advertisement/document/add`)}
                                    advertisementId={advertisement?.id ?? 0}
                                    documentType={3}
                                    documentId={null}
                                    callback={() => setAreDocumentsUploaded(true)}
                                    uploadFilesMessage='Pridať prílohy'
                                    dragAndDropMessage='Pretiahnutím, alebo klíknutim v tomto stvorci nahrajte prílohy'
                                    dropMessage='Pre nahratie príloh sem pretiahnite súbory'
                                />
                            </div>

                        </Grid>
                    )}
                    <Grid item xs={12} md={6}>
                        {props.canAddPhotographs && (
                            <div className="form-group">
                                {/* nahrat fotografie */}
                                <FileUpload
                                    maxAllowedFilesCount={100}
                                    acceptedExtensions={['jpg', 'jpeg', 'png']}
                                    checkMaxSize={true}
                                    maxSize={parseInt(process.env.REACT_APP_PHOTOGRAPHS_FILE_MAX_SIZE ?? '0')}
                                    files={photographs}
                                    setFiles={(files: File[], isRemove: boolean) => isRemove ? setPhotographs(files) : setPhotographs([...photographs, ...files])}
                                    canUpload={canUploadPhotographs}
                                    apiUrl={getApiUrlFromRelativeUrl(`/api/secured/advertisement/document/add`)}
                                    advertisementId={advertisement?.id ?? 0}
                                    documentType={1}
                                    documentId={null}
                                    callback={() => setArePhotographsUploaded(true)}
                                    uploadFilesMessage='Pridať fotky'
                                    dragAndDropMessage='Pretiahnutím, alebo klíknutim v tomto stvorci nahrajte fotky'
                                    dropMessage='Pre nahratie fotiek sem pretiahnite súbory'
                                />
                            </div>
                        )}
                    </Grid>
                    {(props.isAdd && authContext.userLogged?.canAssignAdvertisementToUser) && (
                        <Grid item xs={12} md={12}>
                            <div className="form-group">
                                <label htmlFor="sendEmail" className='text-primary'
                                       style={{fontFamily: 'OpenSansBold'}}>
                                    <input type="checkbox" name="sendEmail" id="sendEmail" checked={sendEmail}
                                           onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFormData({
                                               ...formData,
                                               ['sendEmail']: e.target.checked
                                           })}/>
                                    Odoslať email použivateľovi, ktorému priradzujem inzerát o tom, že mu bol priradený
                                    inzerát a ak nemá zapnutý účet, ako si ho zapnúť.
                                </label>
                            </div>
                        </Grid>
                    )}
                </Grid>
                <div className="text-center mt-4 mb-4">
                    <div className="form-group">
                        <button type="submit" className="btn btn-primary" style={{
                            fontFamily: 'OpenSansSemiBold',
                            fontSize: '25px',
                            borderRadius: '50px',
                            padding: '15px'
                        }}>
                            {props.isAdd ? 'Pridať' : 'Upraviť'} inzerát
                        </button>
                    </div>
                </div>
            </form>
        </React.Fragment>
    )
}
