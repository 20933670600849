import * as React from 'react'
import UserLoggedInterface from "../types/User/UserLoggedInterface"
import { AuthContext } from '../App'
import AuthContextType from "../types/AuthContext/AuthContextType"
import { Action, ClientContext } from "react-fetching-library"
import { toast } from "react-toastify"
import Response from "../types/Response/Response"
import { getApiUrlFromRelativeUrl, request } from "../utils/api/ApiUtil"

interface Props {
    children?: React.ReactNode,
}


export default function AuthProvider(props: Props): JSX.Element {
    // constants
    const INTERNAL_SERVER_ERROR_MESSAGE: string = 'Nastala neočakavaná chyba!'
    // state
    const [userLogged, setUserLogged] = React.useState<UserLoggedInterface | null>(null)
    const [jwtToken, setJwtToken] = React.useState<string | null>(localStorage.getItem('jwt'))
    // client context
    const clientContext = React.useContext(ClientContext)

    // actions
    const validateJWTTokenAction: Action = {
        method: 'GET',
        endpoint: getApiUrlFromRelativeUrl('/api/secured/validate-jwt-token'),
        headers: {
            Authorization: 'Bearer ' + jwtToken,
            Accept: 'application/json'
        }
    }

    const getUserLoggedAction: Action = {
        method: 'GET',
        endpoint: getApiUrlFromRelativeUrl('/api/secured/user-info'),
        headers: {
            Authorization: 'Bearer ' + jwtToken,
            Accept: 'application/json'
        }
    }

    const signIn = (token: string | null): void => {
        if (token === null) {
            return
        }
        localStorage.setItem('jwt', token)
        setJwtToken(token)
    }

    const isUserSignedIn = async (): Promise<boolean> => {
        return (jwtToken !== null) && await isJWTValid(jwtToken)
    }

    const isJWTValid = async (jwt: string | null): Promise<boolean> => {
        if (jwt === null) {
            return false
        }
        const data = await validateJWT(jwt)
        return data.success
    }

    const validateJWT = async (jwt: string | null): Promise<Response> => {
        if (jwt === null) {
            return {
                success: false,
                errors: ['JWT token is not valid'],
                data: [],
            }
        }
        const {payload, error, status} = await request(validateJWTTokenAction, clientContext)
        if (error) {
            toast.error(INTERNAL_SERVER_ERROR_MESSAGE)
        }
        if (status === 401) {
            return {
                success: false,
                errors: ['JWT token is not valid'],
                data: [],
            }
        }
        if (payload.isValid) {
            return {
                success: true,
                errors: [],
                data: [],
            }
        }
        return {
            success: false,
            errors: ['JWT token is not valid'],
            data: [],
        }
    }

    // update userLogged
    const updateUserLogged = async (): Promise<void> => {
        const data = await getUserLogged()
        setUserLogged(data)
    }

    const logout = (): void => {
        // clear window location history - to prevent user from going back to secured pages
        window.location.replace('/')
        // logout user
        localStorage.removeItem('jwt')
        setJwtToken(null)
        setUserLogged(null)
    }

    const getUserLogged = async (): Promise<UserLoggedInterface | null> => {
        if (jwtToken === null) {
            return null
        }
        const {payload, error, status} = await request(getUserLoggedAction, clientContext)

        if (error) {
            logout()
        } else {
            setUserLogged(payload)
        }
        if (status === 401) {
            logout()
        }
        return payload
    }

    React.useEffect(() => {
        if (jwtToken === null) {
            return
        }
        getUserLogged()
    }, [jwtToken])

    // auth context
    const authContext: AuthContextType = {
        userLogged,
        setUserLogged,
        jwtToken,
        setJwtToken,
        signIn,
        isUserSignedIn,
        isJWTValid,
        getUserLogged,
        logout,
        updateUserLogged,
    }

    // render
    return (
        <AuthContext.Provider value={authContext}>
            {props.children}
        </AuthContext.Provider>
    )
}
