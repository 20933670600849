import { Box, FormControl, Select, OutlinedInput, MenuItem } from "@mui/material"

interface Props {
    pageSize: number,
    setPageSize: (pageSize: number) => void
}

export default function PageSizeSelect(props: Props): JSX.Element {
    return (
        <Box sx={{ minWidth: 75 }}>
            <FormControl fullWidth>
                <Select
                    size={"small"}
                    value={props.pageSize}
                    onChange={(event) => props.setPageSize(event.target.value as number)}
                    input={<OutlinedInput />}
                    inputProps={{ 'aria-label': 'Without label' }}
                >
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={25}>25</MenuItem>
                </Select>
            </FormControl>
        </Box>
    )
}