import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Card, CardContent, Container, Grid, Typography } from '@mui/material'
import { AdvertisementCategoryInterface } from "../../types/AdvertisementCategoryInterface/AdvertisementCategoryInterface"
import { getApiUrlFromRelativeUrl, request } from '../../utils/api/ApiUtil'
import { Action, ClientContext } from 'react-fetching-library'
import { DistrictInterface } from "../../types/District/DistrictInterface"
import { AdvertisementFilterContext } from "../../App"
import SearchIcon from '@mui/icons-material/Search'
import { ArrowDropDown } from '@mui/icons-material'
import ToppedAdvertisements from "../../components/toppedAdvertisement/ToppedAdvertisements"
import Loading from '../../components/loader/Loading'
import ErrorComponent from "../../components/error/ErrorComponent"
import auta_kategoria from '../../assets/icons/auta_kategoria.svg'
import nehnutelne_veci from '../../assets/icons/nehnutelne_veci.svg'
import kladivko from '../../assets/icons/kladivko.svg'
import auta_kategoria_biele from '../../assets/icons/auta_kategoria_biele.svg'
import nehnutelne_veci_biele from '../../assets/icons/nehnutelne_veci_biele.svg'
import iny_majetok_biele from '../../assets/icons/iny_majetok_biele.png'
import iny_majetok_modre from '../../assets/icons/iny_majetok_modre.png'


enum AdvertisementThingType {
    MOVABLE = 1,
    IMMOVABLE = 2,
    OTHER = 3,
}


export default function AdvertisementMainPage(): JSX.Element {
    // constants
    const INTERNAL_SERVER_ERROR_MESSAGE: string = 'Nastala neočakavaná chyba!'
    //- context
    const advertisementFilterContext = React.useContext(AdvertisementFilterContext)
    const clientContext = React.useContext(ClientContext)

    // init navigate
    const navigate = useNavigate()
    const advertisementThingType = AdvertisementThingType
    const [isLoading, setIsLoading] = React.useState<boolean>(false)
    const [advertisementThingTypeSelected, setAdvertisementThingTypeSelected] = React.useState<AdvertisementThingType>(advertisementThingType.IMMOVABLE)
    const [categories, setCategories] = React.useState<AdvertisementCategoryInterface[]>([])
    const [districtIdSelected, setDistrictIdSelected] = React.useState<number>(0)
    const [selectedCategoryId, setSelectedCategoryId] = React.useState<number>(0)
    const [districts, setDistricts] = React.useState<DistrictInterface[]>([])
    const [isError, setIsError] = React.useState<boolean>(false)
    const selectRefs = React.useRef<(HTMLSelectElement | null)[]>([])
    const selectContainers = React.useRef<(HTMLDivElement | null)[]>([])

    const handleSelectContainerClick = (index: number) => {
        if (selectRefs.current[index]) {
            // Simulate a click event on the select element to open the dropdown
            const clickEvent = new MouseEvent('mousedown', { bubbles: true })
            selectRefs.current[index]!.dispatchEvent(clickEvent)
        }
    }

    //- actions
    const getCategoriesAction: Action = {
        endpoint: getApiUrlFromRelativeUrl('/api/advertisement/category/get/all/notPaginated/' + advertisementThingTypeSelected),
        method: 'GET',
        headers: {
            Accept: 'application/json'
        }
    }

    const getAllLocationsAction: Action = {
        endpoint: getApiUrlFromRelativeUrl('/api/locations/all'),
        method: 'GET',
        headers: {
            Accept: 'application/json'
        }
    }

    //- effect
    React.useEffect(() => {
        const getCategories = async () => {
            setIsLoading(true)
            const { error, payload } = await request(getCategoriesAction, clientContext)
            if (error) {
                setIsError(true)
            }
            setCategories(payload)
            setSelectedCategoryId(0) // vycistime vybranu kategoriu - lebo advertisementThingTypeSelected sa zmenil
            setIsLoading(false)
        }
        getCategories()
    }, [advertisementThingTypeSelected])

    React.useEffect(() => {
        const getLocations = async () => {
            const { error, payload } = await request(getAllLocationsAction, clientContext)
            if (error) {
                setIsError(true)
            }
            setDistricts(payload.districts)
        }
        getLocations()
    }, [])


    //-- handlers - search
    const handleSearch = (): void => {
        //- set filter
        advertisementFilterContext.setFilterToStateAndSessionStorage({
            thingType: advertisementThingTypeSelected,
            districtIds: districtIdSelected !== 0 && districtIdSelected !== null ? [districtIdSelected] : [],
            categoryIds: selectedCategoryId !== 0 && selectedCategoryId !== null ? [selectedCategoryId] : [],
            cityIds: [], // poznamka: na homepage nie je input na mesto
            priceFrom: 0, // poznamka: na homepage nie je input na cenu
            priceTo: 0, // poznamka: na homepage nie je input na cenu
            sortBy: null, // poznamka: na homepage nie je input na cenu
        })
        //- navigate
        navigate('/advertisements')
    }

    if (isError) {
        return (
            <ErrorComponent
                message={INTERNAL_SERVER_ERROR_MESSAGE}
            />
        )
    }

    // render
    return (
        <React.Fragment>
            {isLoading && <Loading/>}
            <Container sx={{mt: '5%'}}>
                <Grid container spacing={2}>
                    <Grid item xs={12} display='flex' justifyContent='center' alignItems='center'>
                        <Typography variant='h5' component='div' style={{fontFamily: 'OpenSansLight'}}
                                    className='main-page-heading'>
                            Kupuj a predávaj <span className='text-primary'
                                                   style={{fontWeight: 'bold'}}>bez obmedzení</span>
                        </Typography>
                    </Grid>
                </Grid>
                <Card sx={{border: 'none', boxShadow: 'none', marginTop: '5%'}}>
                    <CardContent className='grey-background main-page-card-content'>
                        <Container>
                            <Grid container spacing={2}>
                                <Grid item sm={12} md={4} style={{width: '100%'}}>
                                    <Box
                                        className={`rounded-borders clickable ${advertisementThingTypeSelected === advertisementThingType.IMMOVABLE ? 'background-primary' : ''}`}
                                        onClick={() => setAdvertisementThingTypeSelected(advertisementThingType.IMMOVABLE)}
                                        display='flex'
                                        alignItems='center'
                                        justifyContent='space-between'
                                        padding="10px"
                                        style={advertisementThingTypeSelected !== advertisementThingType.IMMOVABLE ? {backgroundColor: 'white'} : {}}
                                    >
                                        <Box display="flex" alignItems="center">
                                            <img
                                                src={advertisementThingTypeSelected === advertisementThingType.IMMOVABLE ? nehnutelne_veci_biele : nehnutelne_veci}
                                                alt='Nehnuteľnosti'
                                                style={
                                                    advertisementThingTypeSelected !== advertisementThingType.IMMOVABLE ?
                                                        {marginRight: '10px'} : {marginRight: '10px', color: 'white'}
                                                }
                                                className='category-icon'
                                            />
                                            <Typography
                                                variant='h5'
                                                component='div'
                                                style={{
                                                    fontFamily: 'OpenSansSemiBold',
                                                    maxWidth: '70%',
                                                    fontSize: '22px'
                                                }}
                                                className={`${advertisementThingTypeSelected !== advertisementThingType.IMMOVABLE ? 'text-primary' : ''}`}
                                            >
                                                Nehnuteľnosti
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item sm={12} md={4} style={{width: '100%'}}>
                                    <Box
                                        className={`rounded-borders clickable ${advertisementThingTypeSelected === advertisementThingType.MOVABLE ? 'background-primary' : ''}`}
                                        onClick={() => setAdvertisementThingTypeSelected(advertisementThingType.MOVABLE)}
                                        display='flex'
                                        alignItems='center'
                                        justifyContent='space-between'
                                        padding="10px"
                                        style={advertisementThingTypeSelected !== advertisementThingType.MOVABLE ? {backgroundColor: 'white'} : {}}
                                    >
                                        <Box display="flex" alignItems="center">
                                            <img
                                                src={advertisementThingTypeSelected === advertisementThingType.MOVABLE ? auta_kategoria_biele : auta_kategoria}
                                                alt='Hnuteľnosti'
                                                style={
                                                    advertisementThingTypeSelected === advertisementThingType.MOVABLE ?
                                                        {color: 'white', marginRight: '10px'} : {marginRight: '10px'}
                                                }
                                                className='category-icon'
                                            />
                                            <Typography
                                                variant='h5'
                                                component='div'
                                                style={{
                                                    fontFamily: 'OpenSansSemiBold',
                                                    maxWidth: '70%',
                                                    fontSize: '22px'
                                                }}
                                                className={`${advertisementThingTypeSelected !== advertisementThingType.MOVABLE ? 'text-primary' : ''}`}
                                            >
                                                Hnuteľnosti
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item sm={12} md={4} style={{width: '100%'}}>
                                    <Box
                                        className={`rounded-borders clickable ${advertisementThingTypeSelected === advertisementThingType.OTHER ? 'background-primary' : ''}`}
                                        onClick={() => setAdvertisementThingTypeSelected(advertisementThingType.OTHER)}
                                        display='flex'
                                        alignItems='center'
                                        justifyContent='space-between'
                                        padding="10px"
                                        style={advertisementThingTypeSelected !== advertisementThingType.OTHER ? {backgroundColor: 'white'} : {}}
                                    >
                                        <Box display="flex" alignItems="center">
                                            <img
                                                src={advertisementThingTypeSelected === advertisementThingType.OTHER ? iny_majetok_biele : iny_majetok_modre}
                                                alt='Iný majetok'
                                                style={
                                                    advertisementThingTypeSelected === advertisementThingType.OTHER ?
                                                        {color: 'white', marginRight: '10px'} : {marginRight: '10px'}
                                                }
                                                className='category-icon-ine'
                                            />
                                            <Typography
                                                variant='h5'
                                                component='div'
                                                style={{
                                                    fontFamily: 'OpenSansSemiBold',
                                                    maxWidth: '70%',
                                                    fontSize: '22px'
                                                }}
                                                className={`${advertisementThingTypeSelected !== advertisementThingType.OTHER ? 'text-primary' : ''}`}
                                            >
                                                Iný majetok
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Container>
                    </CardContent>
                </Card>
                <Card sx={{border: 'none', boxShadow: 'none', marginTop: '5%'}}>
                    <CardContent className='grey-background main-page-filter-card-content'>
                        <Container>
                            <Grid container spacing={4}>
                                <Grid item xs={12} sm={4}>
                                    <div className="form-group">
                                        <label htmlFor="district" style={{fontFamily: 'OpenSansBold'}}
                                               className='text-primary'>
                                            Lokalita
                                        </label>
                                        <div className='select-container'
                                             ref={(el) => (selectContainers.current[0] = el)}
                                             onClick={() => handleSelectContainerClick(0)}
                                        >
                                            <select name="district"
                                                    id="district"
                                                    className='form-control rounded-borders clickable'
                                                    value={districtIdSelected}
                                                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setDistrictIdSelected(parseInt(e.target.value))}
                                                    ref={(el) => (selectRefs.current[0] = el)}
                                            >
                                                <option value="0"></option>
                                                {districts.map((district: DistrictInterface) => (
                                                    <option value={district.id} key={district.id}>
                                                        {district.name}
                                                    </option>
                                                ))}
                                            </select>
                                            <ArrowDropDown className='arrow-icon icon clickable'/>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <div className="form-group">
                                        <label htmlFor="category" style={{fontFamily: 'OpenSansBold'}}
                                               className='text-primary'>
                                            Druh
                                        </label>
                                        <div className='select-container'
                                             ref={(el) => (selectContainers.current[1] = el)}
                                             onClick={() => handleSelectContainerClick(1)}
                                        >
                                            <select
                                                name="category"
                                                id="category"
                                                className='form-control rounded-borders clickable'
                                                value={selectedCategoryId}
                                                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setSelectedCategoryId(parseInt(e.target.value))}
                                                ref={(el) => (selectRefs.current[1] = el)}
                                            >
                                                <option value="0"></option>
                                                {categories.map((category: AdvertisementCategoryInterface) => (
                                                    <option value={category.id} key={category.id}>
                                                        {category.name}
                                                    </option>
                                                ))}
                                            </select>
                                            <ArrowDropDown className='arrow-icon icon clickable'/>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Box
                                        display='flex'
                                        alignItems='center'
                                        justifyContent='center'
                                        padding="10px"
                                        style={{marginTop: '5%', paddingTop: '5%'}}
                                    >
                                        <div className="form-group w-100" style={{marginTop: '-5px'}}>
                                            <button className='btn-primary w-100 rounded-borders border-primary'
                                                    onClick={handleSearch}
                                                    style={{
                                                        fontFamily: 'OpenSansLight',
                                                        padding: '5px',
                                                        fontSize: '20px'
                                                    }}
                                            >
                                                HĽADAŤ
                                                <SearchIcon/>
                                            </button>
                                        </div>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Container>
                    </CardContent>
                </Card>
                <Card sx={{minWidth: 300, maxWidth: 1000, border: 'none', boxShadow: 'none', mt: '5%', ml: '5%'}}>
                    <CardContent style={{backgroundColor: 'white'}}>
                        <Container>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={2}>
                                    <Box>
                                        <Typography variant="h6">
                                            <img src={kladivko} alt='Nehnueľnosti' height='150px'/>
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={10}>
                                    <Box sx={{ml: '2%'}}>
                                        <p>
                                            DražímTo je platforma kde sa zhromaždujú najkomplexnejšie údaje o všetkých
                                            dražbách, ponukových konaniach či iných formách predaja majetku v rámci
                                            konkurzných a exekučných konaní a predajov majetku v dobrovoľných dražbách.
                                            Záujemcovia o kúpu si na drazimto.sk môžu v prehľadnej databáze na jednom
                                            mieste prezerať inzeráty a získavať najdôležitejšie informácie o majetku, o
                                            ktorý majú záujem. Na druhej strane pre tých, ktorý predaj majetku
                                            uskutočňujú je tu vytvorený priestor na lepšiu propagáciu, poskytnutie
                                            doplnujúcich informácií ci iných podkladov, ktoré môžu pomôcť lepšiemu
                                            speňaženiu majetku.
                                        </p>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Container>
                    </CardContent>
                </Card>
            </Container>
            <ToppedAdvertisements marginBottom='3%'/>
        </React.Fragment>
    )
}
