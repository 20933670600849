import * as React from 'react'
import { ToastContainer } from "react-toastify"
import AuthContextType from "./types/AuthContext/AuthContextType"
import AuthProvider from './context/AuthContext'
import IndexPage from "./pages/index/IndexPage"
import { createTheme, ThemeProvider } from '@mui/material/styles'
import MenuProvider from "./context/MenuContext"
import MenuContextType from "./types/MenuContext/MenuContextType"
import { AdvertisementFilterContextType } from './types/AdvertisementFilterContext/AdvertisementFilterContextType'
import AdvertisementFilterProvider from './context/AdvertisementFilterContext'
import { skSK as datePickersSK } from '@mui/x-date-pickers/locales'
import { skSK as dataGridSk } from '@mui/x-data-grid'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
import { CookieConsent } from "react-cookie-consent"
import dayjs from 'dayjs'
import 'dayjs/locale/sk'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-toastify/dist/ReactToastify.css'
import 'react-multi-carousel/lib/styles.css'
import { ClientContextProvider, createClient } from "react-fetching-library"

// client
const client = createClient()


// auth context
export const AuthContext = React.createContext<AuthContextType>(null!)
// menu context
export const MenuContext = React.createContext<MenuContextType>(null!)
// advertisementFilter context
export const AdvertisementFilterContext = React.createContext<AdvertisementFilterContextType>(null!)

const theme = createTheme({
        components: {
            MuiInputBase: {
                styleOverrides: {
                    input: {
                        borderRadius: '50px', // Adjust the border radius as needed
                        padding: '10px', // Adjust the padding as needed
                    },
                },
            },
        },
    },
    {
        palette: {
            primary: {
                main: '#1976d2'
            },
        },
    },
    datePickersSK,
    dataGridSk
)

dayjs.locale('sk')

export default function App(): JSX.Element {
    return (
        <ClientContextProvider client={client}>
            <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_V3_SITE_KEY || ''}>
                <LocalizationProvider
                    localeText={datePickersSK.components.MuiLocalizationProvider.defaultProps.localeText}
                    dateAdapter={AdapterDayjs}
                    adapterLocale='sk'
                >
                    <ThemeProvider theme={theme}>
                        <AuthProvider>
                            <MenuProvider>
                                <AdvertisementFilterProvider>
                                    <ToastContainer
                                        position="bottom-right"
                                        autoClose={5000}
                                        hideProgressBar={false}
                                        newestOnTop={false}
                                        closeOnClick
                                        rtl={false}
                                        pauseOnFocusLoss
                                        draggable
                                        pauseOnHover
                                        theme="light"
                                    />
                                    <IndexPage/>
                                    <CookieConsent
                                        location='bottom'
                                        buttonText='Rozumiem'
                                        style={{background: '#1851AB', color: 'white', fontFamily: 'OpenSansSemiBold'}}
                                        buttonStyle={{
                                            borderRadius: '50px',
                                            background: 'white',
                                            color: '#1851AB',
                                            fontFamily: 'OpenSansSemiBold'
                                        }}
                                        expires={150}
                                    >
                                        Tento web používa súbory cookies na zlepšenie užívateľského zážitku.
                                    </CookieConsent>
                                </AdvertisementFilterProvider>
                            </MenuProvider>
                        </AuthProvider>
                    </ThemeProvider>
                </LocalizationProvider>
            </GoogleReCaptchaProvider>
        </ClientContextProvider>
    )
}
