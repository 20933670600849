/**
 * @return string - vygenerovane validne heslo, ktore spĺňa nasledujúce podmienky:
 * - dlzka hesla je minimalne 10 znakov
 * - heslo obsahuje aspon 1 velke pismeno
 * - heslo obsahuje aspon 1 male pismeno
 * - heslo obsahuje aspon 1 cislicu
 * - heslo obsahuje aspon 1 specialny znak
 */
export const  generateValidPassword = (): string  => {
    const uppercaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
    const lowercaseLetters = 'abcdefghijklmnopqrstuvwxyz'
    const digits = '0123456789'
    const specialChars = '#?!@$_+%^&*-'

    const allCharacters: string = uppercaseLetters + lowercaseLetters + digits + specialChars;

    const minLength: number = 10;
    const maxLength: number = 15; // max dlzka hesla je 15 znakov - ak by chceli tak mozme to v buducnosti zvysit

    const randomLength: number = Math.floor(Math.random() * (maxLength - minLength + 1)) + minLength;

    let password: string = '';

    // Vygenerujeme aspon 1 znak z kazdej skupiny - t.j. 4 znaky
    password += uppercaseLetters[Math.floor(Math.random() * uppercaseLetters.length)];
    password += lowercaseLetters[Math.floor(Math.random() * lowercaseLetters.length)];
    password += digits[Math.floor(Math.random() * digits.length)];
    password += specialChars[Math.floor(Math.random() * specialChars.length)];

    // Vygenerujeme zvysok chybajucej dlzky
    for (let i = 4; i < randomLength; i++) {
        password += allCharacters[Math.floor(Math.random() * allCharacters.length)];
    }

    return password;
}