import {Box, Card, CardContent, CardMedia, Container, Grid, IconButton, Tooltip} from '@mui/material'
import * as React from 'react'
import {Action, ClientContext} from "react-fetching-library"
import {AuthContext} from '../../App'
import {getApiUrlFromRelativeUrl, request} from "../../utils/api/ApiUtil"
import {toast} from "react-toastify"
import {
    UserAddOrEditAddressErrorProps,
    UserAddOrEditAddressProps,
    UserAddOrEditErrorProps,
    UserAddOrEditProps
} from "./types/UserAddOrEditProps"
import {useNavigate} from "react-router-dom"
import my_profile_photo from '../../assets/icons/my_profile_photo.svg'
import Loading from '../loader/Loading'
import ErrorComponent from '../error/ErrorComponent'
import DeleteIcon from "@mui/icons-material/Delete"
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import ConfirmModal from "../modal/ConfirmModal"
import {DocumentInterface} from "../../types/Document/DocumentInterface"

interface UserViewProfileOrEditProfileComponentProps {
    userId: number,
    isView: boolean,
    action: Action,
    messageOnError: string,
    messageOnSuccess: string,
    urlToRedirectToOnSuccess: string
}

export default function UserViewProfileOrEditProfile(props: UserViewProfileOrEditProfileComponentProps): JSX.Element {
    // constants
    const INTERNAL_SERVER_ERROR_MESSAGE: string = 'Nastala neočakavaná chyba!'
    const EMAIL_NOT_VALID_MESSAGE: string = 'Hodnota nie je platná emailová adresa'
    // state
    const [isLoading, setIsLoading] = React.useState<boolean>(true)
    const [formData, setFormData] = React.useState<UserAddOrEditProps>({
        title: null,
        firstName: null,
        lastName: null,
        email: null,
        passwordPlain: null,
        passwordPlainRepeat: null,
        telephoneNumber: null,
        addressString: null,
        urlAddress: null,
        isFO: false,
        ico: null,
        dic: null,
        icDph: null,
        nameOfCompany: null,
        address: {
            street: null,
            houseNumber: null,
            city: null,
            zipCode: null,
            country: null,
        },
        sendEmail: false,
    })
    const [isError, setIsError] = React.useState<boolean>(false)
    const [isEmailValid, setIsEmailValid] = React.useState<boolean>(true)
    const [isAddressAsString] = React.useState<boolean>(false)
    const [showDeleteAvatarModal, setShowDeleteAvatarModal] = React.useState<boolean>(false)
    const [avatar, setAvatar] = React.useState<DocumentInterface | null | undefined>(null)
    const [file, setFile] = React.useState<File | null>(null)
    // errors
    const [errors, setErrors] = React.useState<UserAddOrEditErrorProps>({
        titleErrors: [],
        firstNameErrors: [],
        lastNameErrors: [],
        emailErrors: [],
        passwordPlainErrors: [],
        passwordPlainRepeatErrors: [],
        telephoneNumberErrors: [],
        icoErrors: [],
        nameOfCompanyErrors: [],
        dicErrors: [],
        icDphErrors: [],
        addressErrors: {
            streetErrors: [],
            houseNumberErrors: [],
            cityErrors: [],
            zipCodeErrors: [],
            countryErrors: [],
        },
        addressErrorsString: [],
        urlAddressErrors: [],
    })
    const {
        titleErrors,
        firstNameErrors,
        lastNameErrors,
        emailErrors,
        passwordPlainErrors,
        telephoneNumberErrors,
        addressErrors,
        addressErrorsString,
        urlAddressErrors,
        nameOfCompanyErrors,
        dicErrors,
        icDphErrors,
        icoErrors,
        passwordPlainRepeatErrors
    } = errors

    // destructure from formData
    const {
        title,
        firstName,
        lastName,
        email,
        passwordPlain,
        telephoneNumber,
        addressString,
        address,
        isFO,
        ico,
        dic,
        icDph,
        nameOfCompany,
        urlAddress,
        sendEmail,
    } = formData
    // context
    const clientContext = React.useContext(ClientContext)
    const authContext = React.useContext(AuthContext)
    //- init navigate
    const navigate = useNavigate()

    const getUserAction: Action = {
        method: 'GET',
        endpoint: getApiUrlFromRelativeUrl('/api/secured/user/get/' + props.userId),
        headers: {
            Authorization: 'Bearer ' + authContext.jwtToken,
            Accept: 'application/json',
        }
    }

    const deleteAvatarAction: Action = {
        method: 'DELETE',
        endpoint: getApiUrlFromRelativeUrl('/api/secured/user/removeAvatar/'),
        headers: {
            Authorization: 'Bearer ' + authContext.jwtToken,
            Accept: 'application/json',
        }
    }

    const setAvatarAction: Action = {
        method: 'POST',
        endpoint: getApiUrlFromRelativeUrl(`/api/secured/user/setAvatar`),
        headers: {
            Authorization: 'Bearer ' + authContext.jwtToken,
            Accept: 'application/json',
        }
    }

    const getUser = async (): Promise<void> => {
        const {payload, error, status} = await request(getUserAction, clientContext)
        if (error && status === 500) {
            setIsError(true)
        }
        if (status === 401) {
            // ak prisla 401, takze sa user nedokazal authorizovat tokenom v api - presmerujeme na login page
            authContext.logout()
        }
        if (status === 200) {
            setFormData({
                ...formData,
                title: payload.title,
                firstName: payload.firstName,
                lastName: payload.lastName,
                nameOfCompany: payload.nameOfCompany,
                ico: payload.ico,
                dic: payload.dic,
                icDph: payload.icDph,
                isFO: payload.isFO,
                email: payload.email,
                telephoneNumber: payload.telephoneNumber,
                addressString: payload.addressString,
                urlAddress: payload.url,
                address: {
                    street: payload.address?.street ?? null,
                    houseNumber: payload.address?.houseNumber ?? null,
                    city: payload.address?.city ?? null,
                    zipCode: payload.address?.zipCode ?? null,
                    country: payload.address?.country ?? null,
                },
            })
            setAvatar(payload.avatar)
        }
        setIsLoading(false)
    }

    React.useEffect(() => {
        getUser()
    }, [])

    const validateEmail = (email: string | null): void => {
        emailErrors.splice(0, emailErrors.length)
        const emailValidationRegex: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
        if (email === null) {
            const index: number = emailErrors.indexOf(EMAIL_NOT_VALID_MESSAGE)
            if (index === -1) {
                emailErrors.push(EMAIL_NOT_VALID_MESSAGE)
            }
        } else {
            const isEmailValid: boolean = emailValidationRegex.test(email)

            if (!isEmailValid) {
                const index: number = emailErrors.indexOf(EMAIL_NOT_VALID_MESSAGE)
                if (index === -1) {
                    emailErrors.push(EMAIL_NOT_VALID_MESSAGE)
                }
            } else {
                const index: number = emailErrors.indexOf(EMAIL_NOT_VALID_MESSAGE)
                if (index > -1) {
                    emailErrors.splice(index, 1)
                }
            }
        }
        // update state
        setErrors({
            ...errors,
            emailErrors: emailErrors
        })
        setIsEmailValid(emailErrors.length === 0)
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (event.target.name === 'email') {
            validateEmail(event.target.value)
        }
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        })
    }

    const handleChangeAddress = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setFormData({
            ...formData,
            address: {
                ...address,
                [event.target.name]: event.target.value
            }
        })
    }

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault()
        // pre istotu validacia znovu
        validateEmail(email)
        if (!isEmailValid) {
            toast.error(props.messageOnError)
            return
        }
        setIsLoading(true)


        const action2 = {
            ...props.action,
            body: {
                title: isFO ? title : null,
                firstName: isFO ? firstName : null,
                lastName: isFO ? lastName : null,
                email: email,
                passwordPlain: passwordPlain,
                telephoneNumber: telephoneNumber,
                addressString: isAddressAsString ? addressString : null,
                ico: !isFO ? ico : null,
                dic: !isFO ? dic : null,
                icDph: !isFO ? icDph : null,
                url: urlAddress,
                address: isAddressAsString ? null : address,
                isFO: isFO,
                nameOfCompany: !isFO ? nameOfCompany : null,
                sendEmailIfCreated: sendEmail,
                didAcceptProcessingOfPersonalData: true,
            }
        }
        const {payload, error, status} = await request(action2, clientContext)
        await handleFileUpload()
        if (error && status === 500) {
            setIsError(true)
        }
        if (status === 401) {
            // ak prisla 401 - to znamena, ze sa user nedokazal authorizovat tokenom v api - presmerujeme na login page
            authContext.logout()
        }
        const titleErrorsNew: string[] = []
        const firstNameErrorsNew: string[] = []
        const lastNameErrorsNew: string[] = []
        const emailErrorsNew: string[] = []
        const telephoneNumberErrorsNew: string[] = []
        const addressErrorsStringNew: string[] = []
        const passwordPlainErrors: string[] = []
        const icoErrorsNew: string[] = []
        const nameOfCompanyErrorsNew: string[] = []
        const urlAddressErrorsNew: string[] = []
        const addressErrorsNew: UserAddOrEditAddressErrorProps = {
            streetErrors: [],
            houseNumberErrors: [],
            cityErrors: [],
            zipCodeErrors: [],
            countryErrors: [],
        }
        if (!payload.success) {
            toast.error(props.messageOnError)
            for (let key in payload.errors) {
                switch (key) { // takyto krepy switch zatial, mozno v buducnosti prepisat na nieco rozumnejsie
                    case 'title':
                        titleErrorsNew.push(payload.errors[key])
                        break
                    case 'firstName':
                        firstNameErrorsNew.push(payload.errors[key])
                        break
                    case 'lastName':
                        lastNameErrorsNew.push(payload.errors[key])
                        break
                    case 'email':
                        emailErrorsNew.push(payload.errors[key])
                        setIsEmailValid(false)
                        break
                    case 'telephoneNumber':
                        telephoneNumberErrorsNew.push(payload.errors[key])
                        break
                    case 'ico':
                        icoErrorsNew.push(payload.errors[key])
                        break
                    case 'nameOfCompany':
                        nameOfCompanyErrorsNew.push(payload.errors[key])
                        break
                    case 'address':
                        addressErrorsStringNew.push(payload.errors[key])
                        break
                    case 'url':
                        urlAddressErrorsNew.push(payload.errors[key])
                        break
                    case 'street':
                        addressErrorsNew.streetErrors.push(payload.errors[key])
                        break
                    case 'houseNumber':
                        addressErrorsNew.houseNumberErrors.push(payload.errors[key])
                        break
                    case 'city':
                        addressErrorsNew.cityErrors.push(payload.errors[key])
                        break
                    case 'zipCode':
                        addressErrorsNew.zipCodeErrors.push(payload.errors[key])
                        break
                    case 'country':
                        addressErrorsNew.countryErrors.push(payload.errors[key])
                        break
                    default:
                        break
                }
            }
        } else if (status === 200 || status === 201) {
            toast.success(props.messageOnSuccess)
            await authContext.updateUserLogged()
            navigate(props.urlToRedirectToOnSuccess)
        }
        setErrors({
            titleErrors: titleErrorsNew,
            firstNameErrors: firstNameErrorsNew,
            lastNameErrors: lastNameErrorsNew,
            emailErrors: emailErrorsNew,
            passwordPlainErrors: passwordPlainErrors,
            telephoneNumberErrors: telephoneNumberErrorsNew,
            addressErrorsString: addressErrorsString,
            urlAddressErrors: urlAddressErrorsNew,
            icDphErrors: [],
            dicErrors: [],
            icoErrors: icoErrorsNew,
            nameOfCompanyErrors: nameOfCompanyErrorsNew,
            passwordPlainRepeatErrors: [],
            addressErrors: addressErrorsNew,
        })
        setIsLoading(false)
        if (props.userId === authContext.userLogged?.id && payload.success) {
            await getUser()
            setFile(null)
        }
    }

    const handleFileUpload = async (): Promise<void> => {
        if (file === null) {
            return
        }
        const formData = new FormData()
        formData.append('file', file)
        formData.append('userId', authContext.userLogged?.id !== undefined ? authContext.userLogged?.id.toString() : '')
        const action2 = {
            ...setAvatarAction,
            body: formData,
        }
        const {payload, error, status} = await request(action2, clientContext)
        if (error && status === 500) {
            setIsError(true)
        }
        if (status === 401) {
            // ak prisla 401 - to znamena, ze sa user nedokazal authorizovat tokenom v api - presmerujeme na login page
            authContext.logout()
        }
        if (payload.success) {
            toast.success('Profilová fotka bola nastavená!')
            setAvatar(payload.data)
        } else {
            toast.error(payload.user)
        }
    }

    const handleSetProfilePicture = (): void => {
        // open file dialog
        const input = document.createElement('input')
        input.type = 'file'
        input.accept = 'image/*'
        input.onchange = (e: any): void => {
            const file = e.target.files[0]
            const maxSize = parseInt(process.env.REACT_APP_DOCUMENTS_FILE_MAX_SIZE ?? '0')
            if (file) {
                if (file.size <= maxSize) {
                    setFile(file)
                } else {
                    toast.error(`Maximálna veľkosť súboru je ${maxSize / 1000000} MB!`)
                }
            }
        }
        input.click()
    }

    const handleDeleteProfilePicture2 = async (): Promise<void> => {
        setIsLoading(true)
        if (avatar === null || avatar === undefined) {
            toast.error('Profilová fotka nie je nastavená!')
            setIsLoading(false)
            return
        }
        const deleteUserAvatarAction2 = {
            ...deleteAvatarAction,
            endpoint: deleteAvatarAction.endpoint + avatar.id
        }
        const {payload, error, status} = await request(deleteUserAvatarAction2, clientContext)
        if (error && status === 500) {
            setIsError(true)
        }
        if (status === 401) {
            // ak prisla 401 - to znamena, ze sa user nedokazal authorizovat tokenom v api - presmerujeme na login page
            authContext.logout()
        }
        if (status === 204) {
            toast.success('Profilová fotka bola zmazaná!')
            setAvatar(null)
        } else {
            toast.error(payload.user)
        }
        setIsLoading(false)
        setShowDeleteAvatarModal(false)
        await authContext.updateUserLogged()
    }

    const handleDeleteProfilePicture = (): void => {
        setShowDeleteAvatarModal(true) // zobrazime modal
    }

    if (isError) {
        return (
            <ErrorComponent
                message={INTERNAL_SERVER_ERROR_MESSAGE}
            />
        )
    }

    return (
        <React.Fragment>
            {isLoading && <Loading/>}
            <ConfirmModal
                title='Zmazať profilovú fotku'
                message='Naozaj chcete zmazať profilovú fotku?'
                show={showDeleteAvatarModal}
                onSuccessCallback={() => handleDeleteProfilePicture2()}
                onCloseCallback={() => setShowDeleteAvatarModal(false)}
            />
            <Box display='flex' justifyContent='center' alignItems='center'>
                <Card sx={{maxWidth: 500, border: 'none', boxShadow: 'none'}}>
                    <Container>
                        <Box display='flex' justifyContent='center' alignItems='center'>
                            <CardMedia src={
                                file !== null ? URL.createObjectURL(file) :
                                    (avatar !== null && avatar !== undefined) ? avatar.url !== null && avatar.url !== undefined ? getApiUrlFromRelativeUrl(avatar.url) : my_profile_photo :
                                        my_profile_photo
                            }
                                       component='img'
                                       style={{objectFit: 'cover', width: 'auto', height: '180px'}}/>
                        </Box>
                        <Box display='flex' justifyContent='center' alignItems='center'>
                            <Tooltip
                                title="Vybrať profilovú fotku (profilová fotka sa uloží po kliknutí na tlačidlo 'Uložiť zmeny')">
                                <IconButton
                                    aria-label="Vybrať profilovú fotku (profilová fotka sa uloží po kliknutí na tlačidlo 'Uložiť zmeny')"
                                    onClick={() => handleSetProfilePicture()}
                                >
                                    <PersonAddIcon className='icon'/>
                                </IconButton>
                            </Tooltip>
                            {avatar !== null && (
                                <Tooltip title="Zmazať profilovú fotku">
                                    <IconButton aria-label="Zmazať profilovú fotku"
                                                onClick={() => handleDeleteProfilePicture()}
                                    >
                                        <DeleteIcon className='icon'/>
                                    </IconButton>
                                </Tooltip>
                            )}
                        </Box>
                    </Container>
                    <CardContent className='grey-background'
                                 style={{borderRadius: '15px'}}>
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={2}>
                                {!isFO ? (
                                    <React.Fragment>
                                        <Grid item xs={12} md={12}>
                                            <div className='form-group'>
                                                <label htmlFor='nameOfCompany' className='text-primary required'
                                                       style={{fontFamily: 'OpenSansBold'}}>
                                                    Obchodné meno
                                                </label>
                                                <input type='text' name='nameOfCompany' id='nameOfCompany'
                                                       className={(nameOfCompanyErrors.length > 0 ? 'error' : '') + ' form-control rounded-borders border-primary'}
                                                       value={nameOfCompany ?? ''}
                                                       onChange={handleChange}
                                                       disabled={props.isView}
                                                />
                                                <div>
                                                    <div>
                                                        {nameOfCompanyErrors.map((error: string, index: number) => (
                                                            <p key={index} className='error'>
                                                                {error}
                                                            </p>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <div className='form-group'>
                                                <label htmlFor='ico' className='required text-primary'
                                                       style={{fontFamily: 'OpenSansBold'}}>
                                                    IČO
                                                </label>
                                                <input type='text' name='ico' id='ico'
                                                       className={(icoErrors.length > 0 ? 'error' : '') + ' form-control rounded-borders border-primary'}
                                                       value={ico ?? ''}
                                                       onChange={handleChange}
                                                       disabled={props.isView}
                                                />
                                                <div>
                                                    {icoErrors.map((error: string, index: number) => (
                                                        <p key={index} className='error'>
                                                            {error}
                                                        </p>
                                                    ))}
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <div className='form-group'>
                                                <label htmlFor='dic' className='text-primary'
                                                       style={{fontFamily: 'OpenSansBold'}}>
                                                    DIČ
                                                </label>
                                                <input type='text' name='dic' id='dic'
                                                       className={(dicErrors.length > 0 ? 'error' : '') + ' form-control rounded-borders border-primary'}
                                                       value={dic ?? ''}
                                                       onChange={handleChange}
                                                       disabled={props.isView}
                                                />
                                                <div>
                                                    {dicErrors.map((error: string, index: number) => (
                                                        <p key={index} className='error'>
                                                            {error}
                                                        </p>
                                                    ))}
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <div className='form-group'>
                                                <label htmlFor='icDph' className='text-primary'
                                                       style={{fontFamily: 'OpenSansBold'}}>
                                                    IČ DPH
                                                </label>
                                                <input type='text' name='icDph' id='icDph'
                                                       className={(icDphErrors.length > 0 ? 'error' : '') + ' form-control rounded-borders border-primary'}
                                                       value={icDph ?? ''}
                                                       onChange={handleChange}
                                                       disabled={props.isView}
                                                />
                                                <div>
                                                    {icDphErrors.map((error: string, index: number) => (
                                                        <p key={index} className='error'>
                                                            {error}
                                                        </p>
                                                    ))}
                                                </div>
                                            </div>
                                        </Grid>
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <Grid item xs={12} md={12}>
                                            <div className='form-group'>
                                                <label htmlFor='title' className='text-primary'
                                                       style={{fontFamily: 'OpenSansBold'}}>
                                                    Titul
                                                </label>
                                                <input type='text' name='title' id='title'
                                                       className={(titleErrors.length > 0 ? 'error' : '') + ' form-control rounded-borders border-primary'}
                                                       value={title ?? ''}
                                                       onChange={handleChange}
                                                       disabled={props.isView}
                                                />
                                                <div>
                                                    <div>
                                                        {titleErrors.map((error: string, index: number) => (
                                                            <p key={index} className='error'>
                                                                {error}
                                                            </p>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <div className='form-group'>
                                                <label htmlFor='firstName' className='required text-primary'
                                                       style={{fontFamily: 'OpenSansBold'}}>
                                                    Meno
                                                </label>
                                                <input type='text' name='firstName' id='firstName'
                                                       className={(firstNameErrors.length > 0 ? 'error' : '') + ' form-control rounded-borders border-primary'}
                                                       value={firstName ?? ''}
                                                       onChange={handleChange}
                                                       disabled={props.isView}
                                                />
                                                <div>
                                                    {firstNameErrors.map((error: string, index: number) => (
                                                        <p key={index} className='error'>
                                                            {error}
                                                        </p>
                                                    ))}
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <div className='form-group'>
                                                <label htmlFor='lastName' className='required text-primary'
                                                       style={{fontFamily: 'OpenSansBold'}}>
                                                    Priezvisko
                                                </label>
                                                <input type='text' name='lastName' id='lastName'
                                                       className={(lastNameErrors.length > 0 ? 'error' : '') + ' form-control rounded-borders border-primary'}
                                                       value={lastName ?? ''}
                                                       onChange={handleChange}
                                                       disabled={props.isView}
                                                />
                                                <div>
                                                    {lastNameErrors.map((error: string, index: number) => (
                                                        <p key={index} className='error'>
                                                            {error}
                                                        </p>
                                                    ))}
                                                </div>
                                            </div>
                                        </Grid>
                                    </React.Fragment>
                                )}
                                <Grid item xs={12} md={12}>
                                    <div className='form-group'>
                                        <label htmlFor='email' className='required text-primary'
                                               style={{fontFamily: 'OpenSansBold'}}>
                                            Email
                                        </label>
                                        <input type='email' name='email' id='email'
                                               className={(!isEmailValid && emailErrors.length > 0 ? 'error' : '') + ' form-control rounded-borders border-primary'}
                                               value={email ?? ''}
                                               onChange={handleChange}
                                               disabled={props.isView}
                                        />
                                        <div>
                                            {!isEmailValid && emailErrors.map((error: string, index: number) => (
                                                <p key={index} className='error'>
                                                    {error}
                                                </p>
                                            ))}
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <div className='form-group'>
                                        <label htmlFor='telephoneNumber' className='text-primary required'
                                               style={{fontFamily: 'OpenSansBold'}}>
                                            Telefónne číslo
                                        </label>
                                        <input type='text' name='telephoneNumber' id='telephoneNumber'
                                               className={(telephoneNumberErrors.length > 0 ? 'error' : '') + ' form-control rounded-borders border-primary'}
                                               value={telephoneNumber ?? ''} onChange={handleChange}
                                               disabled={props.isView}
                                        />
                                        <div>
                                            {telephoneNumberErrors.map((error: string, index: number) => (
                                                <p key={index} className='error'>
                                                    {error}
                                                </p>
                                            ))}
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <div className='form-group'>
                                        <label htmlFor='street' className='text-primary required'
                                               style={{fontFamily: 'OpenSansBold'}}>
                                            Ulica
                                        </label>
                                        <input type='text' name='street' id='street'
                                               value={address.street ?? ''}
                                               onChange={handleChangeAddress}
                                               className={(addressErrors.streetErrors.length > 0 ? 'error' : '') + ' form-control rounded-borders border-primary'}
                                               disabled={props.isView}
                                        />
                                        <div>
                                            {addressErrors.streetErrors.map((error: string, index: number) => (
                                                <p key={index} className='error'>
                                                    {error}
                                                </p>
                                            ))}
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <div className='form-group'>
                                        <label htmlFor='houseNumber' className='text-primary required'
                                               style={{fontFamily: 'OpenSansBold'}}>
                                            Súpisne číslo
                                        </label>
                                        <input type='number' name='houseNumber' id='houseNumber'
                                               value={address.houseNumber ?? ''}
                                               onChange={handleChangeAddress}
                                               className={(addressErrors.houseNumberErrors.length > 0 ? 'error' : '') + ' form-control rounded-borders border-primary'}
                                               disabled={props.isView}
                                        />
                                        <div>
                                            {addressErrors.houseNumberErrors.map((error: string, index: number) => (
                                                <p key={index} className='error'>
                                                    {error}
                                                </p>
                                            ))}
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <div className='form-group'>
                                        <label htmlFor='city' className='text-primary required'
                                               style={{fontFamily: 'OpenSansBold'}}>
                                            Mesto
                                        </label>
                                        <input type='text' name='city' id='city' value={address.city ?? ''}
                                               onChange={handleChangeAddress}
                                               className={(addressErrors.cityErrors.length > 0 ? 'error' : '') + ' form-control rounded-borders border-primary'}
                                               disabled={props.isView}
                                        />
                                        <div>
                                            {addressErrors.cityErrors.map((error: string, index: number) => (
                                                <p key={index} className='error'>
                                                    {error}
                                                </p>
                                            ))}
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <div className='form-group'>
                                        <label htmlFor='zipCode' className='text-primary required'
                                               style={{fontFamily: 'OpenSansBold'}}>
                                            PSČ
                                        </label>
                                        <input type='text' name='zipCode' id='zipCode'
                                               value={address.zipCode ?? ''}
                                               onChange={handleChangeAddress}
                                               className={(addressErrors.zipCodeErrors.length > 0 ? 'error' : '') + ' form-control rounded-borders border-primary'}
                                               disabled={props.isView}
                                        />
                                        <div>
                                            {addressErrors.zipCodeErrors.map((error: string, index: number) => (
                                                <p key={index} className='error'>
                                                    {error}
                                                </p>
                                            ))}
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <div className='form-group'>
                                        <label htmlFor='country' className='text-primary'
                                               style={{fontFamily: 'OpenSansBold'}}>
                                            Krajina
                                        </label>
                                        <input type='text' name='country' id='country'
                                               value={address.country ?? ''}
                                               onChange={handleChangeAddress}
                                               className={(addressErrors.countryErrors.length > 0 ? 'error' : '') + ' form-control rounded-borders border-primary'}
                                               disabled={props.isView}
                                        />
                                        <div>
                                            {addressErrors.countryErrors.map((error: string, index: number) => (
                                                <p key={index} className='error'>
                                                    {error}
                                                </p>
                                            ))}
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <div className='form-group'>
                                        <label htmlFor='country' className='text-primary'
                                               style={{fontFamily: 'OpenSansBold'}}>
                                            Url adresa webu
                                        </label>
                                        <input type='text' name='urlAddress' id='urlAddress'
                                               value={urlAddress ?? ''}
                                               onChange={handleChange}
                                               className={(urlAddressErrors.length > 0 ? 'error' : '') + ' form-control rounded-borders border-primary'}
                                               disabled={props.isView}
                                        />
                                        <div>
                                            {urlAddressErrors.map((error: string, index: number) => (
                                                <p key={index} className='error'>
                                                    {error}
                                                </p>
                                            ))}
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <div className="form-group w-100 text-center">
                                        <button
                                            type="submit"
                                            className={`${props.isView ? 'btn-dark disabled' : 'background-primary border-primary'} rounded-borders`}
                                            style={{fontFamily: 'OpenSansBold', fontSize: '18px', padding: '10px'}}
                                            disabled={props.isView}
                                        >
                                            Uložiť zmeny
                                        </button>
                                    </div>
                                </Grid>
                            </Grid>
                        </form>
                    </CardContent>
                </Card>
            </Box>
        </React.Fragment>
    )
}
