import * as React from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {Action, ClientContext} from "react-fetching-library"
import {toast} from "react-toastify"
import {AuthContext} from '../../App'
import {AdvertisementInterface} from '../../types/Advertisement/AdvertisementInterface'
import UserInterface from '../../types/User/UserInterface'
import {PackageInterface} from '../../types/package/PackageInterface'
import dayjs, {Dayjs} from "dayjs"
import {getApiUrlFromRelativeUrl, request} from '../../utils/api/ApiUtil'
import {Container, Typography} from "@mui/material"
import {DatePicker} from "@mui/x-date-pickers"
import CheckIcon from '@mui/icons-material/Check'
import Loading from "../../components/loader/Loading"
import ErrorComponent from "../../components/error/ErrorComponent"

export default function AddPackage(): JSX.Element {
    // constants
    const INTERNAL_SERVER_ERROR_MESSAGE: string = 'Nastala neočakavaná chyba!'
    const COULDNT_LOAD_USER_MESSAGE: string = 'Nepodarilo sa načítať používateľa!'
    //- context
    const authContext = React.useContext(AuthContext)
    const clientContext = React.useContext(ClientContext)
    //- state
    const [isLoading, setIsLoading] = React.useState<boolean>(true)
    const [user, setUser] = React.useState<UserInterface | null>(null)
    const [packages, setPackages] = React.useState<PackageInterface[]>([])
    const [advertisements, setAdvertisements] = React.useState<AdvertisementInterface[]>([])
    //- form data
    const [selectedPackageId, setSelectedPackageId] = React.useState<number | null>(null)
    const [selectedAdvertisementId, setSelectedAdvertisementId] = React.useState<number | null>(null)
    const [activeTill, setActiveTill] = React.useState<Date | null | Dayjs>(null)
    //- form errors
    const [selectedPackageIdErrors, setSelectedPackageIdErrors] = React.useState<string[]>([])
    const [selectedAdvertisementIdErrors, setSelectedAdvertisementIdErrors] = React.useState<string[]>([])
    const [activeTillErrors, setActiveTillErrors] = React.useState<string[]>([])
    const [isError, setIsError] = React.useState<boolean>(false)
    //- init params, navigate, get id from params
    const params = useParams()
    const navigate = useNavigate()
    const {id} = params

    //- actions
    const getUserAction: Action = {
        method: 'GET',
        endpoint: getApiUrlFromRelativeUrl('/api/secured/user/get/' + id),
        headers: {
            Authorization: 'Bearer ' + authContext.jwtToken,
            Accept: 'application/json'
        }
    }

    const getPackagesAction: Action = {
        method: 'GET',
        endpoint: getApiUrlFromRelativeUrl('/api/package/getAll/notPaginated'),
        headers: {
            Accept: 'application/json'
        },
    }

    const getAdvertisementsByUserAction: Action = {
        method: 'GET',
        endpoint: getApiUrlFromRelativeUrl(`/api/secured/advertisements/all/byUser/notPaginated/${id}`),
        headers: {
            Authorization: `Bearer ${authContext.jwtToken}`,
            Accept: 'application/json'
        },
    }

    const savePackageXAdvertisementXUserAction: Action = {
        method: 'POST',
        endpoint: getApiUrlFromRelativeUrl(`/api/secured/packageXAdvertisementXUser/setPackageXAdvertisementXUser`),
        headers: {
            Authorization: `Bearer ${authContext.jwtToken}`,
            Accept: 'application/json'
        }
    }

    //- effect - enabled
    React.useEffect(() => {
        if (!authContext.userLogged?.enabled) {
            //- ak user nie je enabled, tak ho presmerujeme aby si aktivoval ucet
            navigate('/user/not-enabled')
        }
    }, [])

    //- effect - right
    React.useEffect(() => {
        if (authContext.userLogged === null) {
            navigate('/login')
        }
        //- right - user musi mat 'canSetFeatureXUserXAdvertisement=true'
        if (!authContext.userLogged?.canSetPackageXAdvertisementXUser) {
            navigate('/users')
        }
    }, [authContext.userLogged])

    //- effect - nacitaj usera z backendu
    React.useEffect(() => {
        const getUser = async (): Promise<void> => {
            const {payload, error, status} = await request(getUserAction, clientContext)
            if (error) {
                setIsError(true)
            } else {
                if (status === 200) {
                    setUser(payload)
                }
            }
            if (status === 401) {
                authContext.logout()
            }
        }
        if (undefined !== id) {
            getUser()
        }
        //- POZOR: loading nechceme vypnut uz tu lebo, este niesu nacitane vsetky data, vid nizsie
    }, [id])

    //- effect - nacitaj features z backendu
    React.useEffect(() => {
        const getFeatures = async (): Promise<void> => {
            const {payload, error, status} = await request(getPackagesAction, clientContext)
            if (error) {
                setIsError(true)
            } else {
                if (status === 200) {
                    setPackages(payload)
                }
                //- POZOR: loading nechceme vypnut uz tu lebo, este niesu nacitane vsetky data, vid nizsie
            }
        }
        getFeatures()
    }, [])

    //- effect - nacitaj advertisements by user z backendu
    React.useEffect(() => {
        const getAdvertisementsByUser = async (): Promise<void> => {
            const {payload, error, status} = await request(getAdvertisementsByUserAction, clientContext)
            if (error) {
                setIsError(true)
            } else {
                if (status === 200) {
                    setAdvertisements(payload.data)
                }
            }
            if (status === 401) {
                authContext.logout()
            }
            //- loading vypneme az tu, lebo uz mame vsetky data
            setIsLoading(false)
        }
        getAdvertisementsByUser()
    }, [])

    const onSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
        e.preventDefault()
        if (selectedPackageId === null || selectedAdvertisementId === null || activeTill === null) {
            toast.error('Vyplňte všetky polia!')
            return
        }
        if (selectedAdvertisementId === 0 || selectedPackageId === 0) {
            toast.error('Vyplňte všetky polia!')
            return
        }
        setActiveTillErrors([])
        setSelectedPackageIdErrors([])
        setSelectedAdvertisementIdErrors([])
        setIsLoading(true)
        const savePackageXAdvertisementXUserAction2: Action = {
            ...savePackageXAdvertisementXUserAction,
            body: {
                userId: id !== undefined ? parseInt(id) : 0,
                packageId: selectedPackageId,
                advertisementId: selectedAdvertisementId,
                activeTill: dayjs(activeTill).format('DD.MM.YYYY')
            }
        }
        const {payload, status, error} = await request(savePackageXAdvertisementXUserAction2, clientContext)
        if (error) {
            toast.error(INTERNAL_SERVER_ERROR_MESSAGE)
        } else {
            if (status === 200 || status === 201) {
                toast.success('Balík bol pridaný!')
                navigate('/users')
            } else {
                toast.error('Balík sa nepodarilo pridať!')
                for (let key in payload.errors) {
                    switch (key) { // takyto krepy switch zatial, mozno v buducnosti prepisat na nieco rozumnejsie
                        case 'packageId':
                            setSelectedPackageIdErrors([...payload.errors[key]])
                            break
                        case 'advertisementId':
                            setSelectedAdvertisementIdErrors([...payload.errors[key]])
                            break
                        case 'activeTill':
                            setActiveTillErrors([...payload.errors[key]])
                            break
                        case 'userId':
                            toast.error(INTERNAL_SERVER_ERROR_MESSAGE)
                            break
                        default:
                            break
                    }
                }
            }
        }
        if (status === 401) {
            authContext.logout()
        }
        setIsLoading(false)
    }

    if (isLoading) {
        return (
            <Loading/>
        )
    }

    if (isError) {
        return (
            <ErrorComponent
                message={INTERNAL_SERVER_ERROR_MESSAGE}
            />
        )
    }

    //- render
    return (
        <Container sx={{mt: '3%', mb: '3%'}}>
            <Typography className='text-primary' variant='h6' style={{fontFamily: 'OpenSansBold'}}>
                Aktivovať balík použivateľovi {user?.title} {user?.firstName} {user?.lastName}
            </Typography>
            <form onSubmit={onSubmit}>
                <div className="form-group">
                    <label htmlFor="feature"
                           className={(selectedPackageIdErrors.length > 0 ? 'error' : 'text-primary') + ' required'}
                           style={{fontFamily: 'OpenSansSemiBold'}}>
                        Vlastnosť
                    </label>
                    <select
                        className={`form-control ${selectedPackageIdErrors.length > 0 ? 'error' : 'border-primary'} rounded-borders `}
                        id="package" value={selectedPackageId || 0}
                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setSelectedPackageId(parseInt(e.target.value))}>
                        <option value={0}>
                            Vyberte balík
                        </option>
                        {packages.map((packageItem: PackageInterface) => (
                            <option key={packageItem.id} value={packageItem.id}>{packageItem.name}</option>
                        ))}
                    </select>
                    <div>
                        {selectedPackageIdErrors.map((error: string, index: number) => (
                            <small key={index} className='text-danger'>{error}</small>
                        ))}
                    </div>
                </div>
                <div className="form-group">
                    <label htmlFor="advertisement"
                           className={(selectedAdvertisementIdErrors.length > 0 ? 'error' : 'text-primary') + ' required'}
                           style={{fontFamily: 'OpenSansSemiBold'}}>
                        Inzerát
                    </label>
                    <select
                        className={`form-control ${selectedAdvertisementIdErrors.length > 0 ? 'error' : 'border-primary'} rounded-borders`}
                        id="advertisement" value={selectedAdvertisementId || 0}
                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setSelectedAdvertisementId(parseInt(e.target.value))}>
                        <option value={0}>
                            Vyberte inzerát
                        </option>
                        {advertisements.map((advertisement: AdvertisementInterface) => (
                            <option key={advertisement.id} value={advertisement.id || 0}>
                                {advertisement.subject}
                            </option>
                        ))}
                    </select>
                    <div>
                        {selectedAdvertisementIdErrors.map((error: string, index: number) => (
                            <small key={index} className='text-danger'>{error}</small>
                        ))}
                    </div>
                </div>
                <div className="form-group">
                    <label htmlFor="activeTill" className={(activeTillErrors.length > 0 ? 'error' : 'text-primary') + ' required'}
                           style={{fontFamily: 'OpenSansSemiBold'}}>
                        Aktívny do
                    </label>
                    <DatePicker value={activeTill}
                                className={`form-control ${activeTillErrors.length > 0 ? 'error' : 'border-primary'} rounded-borders`}
                                onChange={(newDate) => setActiveTill(newDate)}
                                slotProps={{
                                    textField: {
                                        size: 'small',
                                        variant: 'standard',
                                        InputProps: {
                                            disableUnderline: true,
                                        }
                                    }
                                }}
                    />
                    <div>
                        {activeTillErrors.map((error: string, index: number) => (
                            <small key={index} className='text-danger'>{error}</small>
                        ))}
                    </div>
                </div>
                <div className="text-center mt-2">
                    <button className="btn btn-success">
                        <CheckIcon/>
                        Odoslať
                    </button>
                </div>
            </form>
        </Container>
    )
}
