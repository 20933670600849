import * as React from 'react'
import {AdvertisementInterface} from '../../types/Advertisement/AdvertisementInterface'
import Card from '@mui/material/Card'
import CardMedia from '@mui/material/CardMedia'
import CardContent from '@mui/material/CardContent'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import {Box, Collapse, Container, Tooltip, Typography} from "@mui/material"
import {PackageInterface} from '../../types/package/PackageInterface'
import PackageBootstrapSwitchButton from "../package/PackageBootstrapSwitchButton"
import {AuthContext} from '../../App'
import VisibilityIcon from '@mui/icons-material/Visibility'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import {DocumentInterface} from "../../types/Document/DocumentInterface";
import {getApiUrlFromRelativeUrl} from "../../utils/api/ApiUtil";

interface Props {
    advertisement: AdvertisementInterface,
    packages: PackageInterface[],
    canEdit: boolean,
    canDelete: boolean,
    handleClick: Function,
    handleEdit: Function,
    handleDelete: Function,
    handleSetAsSold: Function,
}

const getSubject = (subject: string): string => {
    // max 35 chars then add '...' and end on first possible space
    if (subject.length > 35) {
        return subject.substr(0, 35).concat('...')
    }
    return subject
}

export default function MyAdvertisementsCard(props: Props): JSX.Element {
    //- state
    const [isHovered, setIsHovered] = React.useState(false)
    const [open, setOpen] = React.useState(false)
    const authContext = React.useContext(AuthContext)

    const hasProtograph: boolean = props.advertisement.primaryPhotograph !== null && props.advertisement.primaryPhotograph !== undefined
    const protograph: DocumentInterface | null = hasProtograph ? props.advertisement.primaryPhotograph : null

    const handleIconButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation(); // Prevent the event from bubbling up to the parent div
        setOpen(!open);
    }

    const handleMouseEnter = () => {
        setIsHovered(true)
    }

    const handleMouseLeave = () => {
        setIsHovered(false)
    }
    const havePhotographs = props.advertisement.photographs?.length !== 0 || hasProtograph

    const imageStyle: React.CSSProperties = {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        borderRadius: '15px',
    }

    return (
        <Card sx={{mb: '10px', border: 'none', boxShadow: 'none'}}>
            <div
                className='clickable'
                onClick={() => props.handleEdit()}
                style={{cursor: 'pointer', height: '150px', position: 'relative', borderRadius: '15px'}}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                {isHovered && (
                    <div
                        className="overlay"
                        style={{
                            borderRadius: '15px',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            backgroundColor: !havePhotographs ? 'rgba(255,255,255,0.7)' : 'rgba(24, 81, 171, 0.7)',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            opacity: isHovered ? 1 : 0, // Apply fade-in/fade-out based on the hovered state
                            transition: 'opacity 0.3s ease', // Add transition for opacity change
                        }}>
                        <Typography
                            variant='h6'
                            align='center'
                            style={{
                                fontFamily: 'OpenSansBold',
                                color: !havePhotographs ? '#1851AB' : '#FFFFFF',
                                fontSize: '20px'
                            }}
                        >
                            Editovať
                        </Typography>
                    </div>
                )}
                {!hasProtograph && (
                    <div className="w-100 background-primary" style={{height: '100%', borderRadius: '15px'}}>
                        <Container style={{height: '100%'}}>
                            <Typography variant='h6' align='center' style={{color: 'white', marginTop: '2%'}}>
                                {getSubject(props.advertisement.subject ?? '')}
                            </Typography>
                            <Typography variant='body1' align='center' style={{color: 'white'}}>
                                Nenašli sa žiadne fotografie
                            </Typography>
                            <div className="rounded-borders"
                                 style={{
                                     zIndex: 2,
                                     position: 'absolute',
                                     bottom: '20px',
                                     left: '20px',
                                 }}>
                                <IconButton
                                    onClick={handleIconButtonClick}
                                    style={{ marginRight: '10px', fontSize: '18px'}}
                                    aria-label="expand"
                                    size="small"
                                >
                                    {open ? (
                                        <KeyboardArrowUpIcon style={{
                                            cursor: 'pointer',
                                            color: '#DADADA',
                                            fontSize: 32
                                        }} />
                                    ) : (
                                        <KeyboardArrowDownIcon style={{
                                            cursor: 'pointer',
                                            color: '#DADADA',
                                            fontSize: 30
                                        }} />
                                    )}
                                </IconButton>
                            </div>
                            <div className="rounded-borders"
                                 style={{
                                     zIndex: 2,
                                     position: 'absolute',
                                     bottom: '20px',
                                     right: '20px',
                                     padding: '5px',
                                     backgroundColor: '#FFFFFF'
                                 }}>
                                <Typography variant='body1' align='center' className='text-primary'
                                            style={{fontFamily: 'OpenSansBold'}}>
                                    {props.advertisement.startingPrice !== null ? (
                                        <span>
                                        {props.advertisement.startingPrice?.price?.toFixed(2)} {props.advertisement.startingPrice?.currency}
                                    </span>
                                    ) : (
                                        <span>
                                        Na vyžiadanie
                                    </span>
                                    )}
                                </Typography>
                            </div>
                        </Container>
                    </div>
                )}
                {(hasProtograph && protograph !== null) && (
                    <React.Fragment>
                        <CardMedia
                            src={protograph.url !== null && protograph.url !== undefined ? getApiUrlFromRelativeUrl(protograph.url) : ''}
                            component='img'
                            style={imageStyle}
                        />
                        <div className="rounded-borders"
                             style={{
                                 zIndex: 2,
                                 position: 'absolute',
                                 bottom: '20px',
                                 left: '20px',
                             }}>
                            <IconButton
                                onClick={handleIconButtonClick}
                                style={{ marginRight: '10px', fontSize: '18px'}}
                                aria-label="expand"
                                size="small"
                            >
                                {open ? (
                                    <KeyboardArrowUpIcon style={{
                                        cursor: 'pointer',
                                        color: '#1851AB',
                                        fontSize: 32
                                    }} />
                                ) : (
                                    <KeyboardArrowDownIcon style={{
                                        cursor: 'pointer',
                                        color: '#1851AB',
                                        fontSize: 30
                                    }} />
                                )}
                            </IconButton>
                        </div>
                        <div className="rounded-borders background-primary"
                             style={{zIndex: 2, position: 'absolute', bottom: '20px', right: '20px', padding: '5px'}}>
                            <Typography variant='body1' align='center'
                                        style={{color: 'white', fontFamily: 'OpenSansBold'}}>
                                {props.advertisement.startingPrice !== null ? (
                                    <span>
                                        {props.advertisement.startingPrice?.price?.toFixed(2)} {props.advertisement.startingPrice?.currency}
                                    </span>
                                ) : (
                                    <span>
                                        Na vyžiadanie
                                    </span>
                                )}
                            </Typography>
                        </div>
                    </React.Fragment>
                )}
            </div>
            <Collapse in={open} timeout="auto">
                <CardContent
                    sx={{boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', border: '1px solid rgba(0, 0, 0, 0.1)', borderRadius: '15px', marginTop: '2%'}}>
                    <Box display="flex" alignItems="start" justifyContent="space-between">
                        <Box display='flex' alignItems='start'>
                            <Tooltip title='Zobraziť inzerát'>
                                <IconButton aria-label="Zobraziť inzerát"
                                            onClick={() => props.handleClick()}>
                                    <VisibilityIcon className='icon'/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Zmazať inzerát">

                                <IconButton aria-label="Zmazať inzerát"
                                            onClick={() => props.handleDelete()}
                                >
                                    <DeleteIcon className='icon'/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title='Označiť ponuku ako úspešnu'>
                                <IconButton aria-label="Označiť ponuku ako úspešnu"
                                            onClick={() => props.handleSetAsSold()}
                                >
                                    <CheckCircleIcon className='icon'/>
                                </IconButton>
                            </Tooltip>
                        </Box>
                        <Box sx={{display: 'flex', flexDirection: 'column', width: '25%'}}>
                            {props.packages.map((p: PackageInterface) => (
                                <div
                                    style={{marginBottom: '5%'}}
                                    key={p.id}
                                >
                                    <PackageBootstrapSwitchButton
                                        packageName={p.name ?? ''}
                                        packageId={p.id}
                                        advertisementId={props.advertisement.id ?? 0}
                                        jwtToken={authContext.jwtToken ?? ''}
                                    />
                                </div>
                            ))}
                        </Box>
                    </Box>
                </CardContent>
            </Collapse>
        </Card>
    )
}
