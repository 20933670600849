import * as React from 'react'
import {Action, ClientContext } from 'react-fetching-library'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import ErrorComponent from '../../components/error/ErrorComponent'
import Loading from '../../components/loader/Loading'
import {getApiUrlFromRelativeUrl, request} from '../../utils/api/ApiUtil'

export default function AdvertisementSetAsSoldPage(): JSX.Element {
    //- constants
    const INTERNAL_SERVER_ERROR_MESSAGE: string = 'Nastala neočakavaná chyba!'
    const ADVERTISEMENT_SUCCESSFULLY_SET_AS_SOLD: string = 'Ponuka bola úspešne označená ako úspešná'
    //- state
    const [isLoading, setIsLoading] = React.useState<boolean>(true)
    const [isError, setIsError] = React.useState<boolean>(false)
    //- init navigate, params, get params
    const navigate = useNavigate()
    const params = useParams()
    const { advertisementId } = params

    //- context
    const clientContext = React.useContext(ClientContext)

    //- actions
    const setAsSoldAction: Action = {
        method: 'POST',
        endpoint: getApiUrlFromRelativeUrl(`/api/advertisement/set/sold/${advertisementId}`)
    }

    //- effect
    React.useEffect((): void => {
        const setAsSold = async () => {
            const {payload, error} = await request(setAsSoldAction, clientContext)
            if (error) {
                setIsError(true)
            }
            if (payload.success) {
                toast.success(ADVERTISEMENT_SUCCESSFULLY_SET_AS_SOLD)
                //- navigate
                navigate(`/advertisement/view/${advertisementId}`)
            } else {
                toast.error(INTERNAL_SERVER_ERROR_MESSAGE)
            }
            setIsLoading(false)
        }
        setAsSold()
    }, [])

    if (isLoading) {
        return (
            <Loading/>
        )
    }

    if (isError) {
        return (
            <ErrorComponent
                message={INTERNAL_SERVER_ERROR_MESSAGE}
            />
        )
    }

    //- render - netreba nic renderovat, lebo effect usera redirectne kam treba
    return (
        <React.Fragment></React.Fragment>
    )
}
