import * as React from 'react'
import { Action } from 'react-fetching-library'
import UserRegisterOrAdd from "../../components/user/UserRegisterOrAdd"
import { getApiUrlFromRelativeUrl } from "../../utils/api/ApiUtil"
import { Container, Typography } from '@mui/material'

export default function Registration(): JSX.Element {
    // constants
    const SUCCESSFULLY_REGISTERED_MESSAGE: string = 'Používateľ úspešne zaregistrovaný!'
    const COULDNT_REGISTER_USER: string = 'Neporadilo sa zaregistrovať používateľa!'
    const IS_RECAPTCHA_ENABLED: boolean = process.env.REACT_APP_IS_RECAPTCHA_ENABLED === 'true'
    //-- actions
    const registerAction: Action = {
        method: 'POST',
        endpoint: getApiUrlFromRelativeUrl('/api/user/register'),
        headers: {
            Accept: 'application/json',
        },
    }

    return (
        <Container sx={{mt: '5%'}}>
            <Typography
                variant='h5'
                component='div'
                style={{fontFamily: 'OpenSansBold'}}
                className='text-primary'
            >
                Registrácia použivateľa
            </Typography>
            <UserRegisterOrAdd
                isRegistration={true}
                action={registerAction}
                user={null}
                userLogged={null}
                messageOnError={COULDNT_REGISTER_USER}
                messageOnSuccess={SUCCESSFULLY_REGISTERED_MESSAGE}
                urlToRedirectToOnSuccess={'/login'}
                isRecaptchaEnabled={IS_RECAPTCHA_ENABLED}
            />
        </Container>
    )
}
