import { PriceObjectInterface } from "../../types/priceObject/PriceObjectInterface"

export const dividePriceString = (priceString: string | null): PriceObjectInterface => {
    if (priceString === null || priceString === '') {
        return {
            price: null,
            currency: null
        }
    }

    //- check
    if (isNaN(parseFloat(priceString))) {
        return {
            price: null,
            currency: null
        }
    }

    if (priceString.includes(',')) {
        priceString = priceString.replace(',', '.')
    }

    return {
        price: parseFloat(priceString.trim()),
        currency: '€'
    }
}

export const validatePriceString = (priceString: string | null, isVPK: boolean = false, isDepositPrice: boolean = false): string|true => {
    if (!isVPK && (priceString === null || priceString === '')) {
        return 'Cena je neplatná musi obsahovať len číslo'
    }
    if (isVPK && (priceString === null || priceString === '')) {
        return true
    }
    if ((isVPK && isDepositPrice) && isNaN(parseFloat(priceString ?? ''))) {
        return true
    }
    if ((isVPK && !isDepositPrice) && isNaN(parseFloat(priceString ?? ''))) {
        return 'Cena je neplatná musi obsahovať len číslo'
    }
    if (!isVPK && isNaN(parseFloat(priceString ?? ''))) {
        return 'Cena je neplatná musi obsahovať len číslo'
    }
    if (priceString?.includes('€')) {
        return 'Cena je neplatná, musí obsahovať len číslo bez meny'
    }
    if (priceString?.includes(',' )) {
        priceString = priceString.replace(',', '.')
    }
    const priceStringArray = priceString?.split('.')
    // ak user zadal viac ako 3 desatinne miesta - neplatna cena
    if (!isNaN(parseFloat((priceString ?? '').trim())) && priceStringArray !== undefined && priceStringArray[1] !== undefined &&  (priceStringArray[1].trim()).length > 2) {
        return 'Cena je neplatná, musí obsahovať len 2 desatinne miesta'
    }
    return true
}

export const getPriceString = (priceObject: PriceObjectInterface | null, withCurrency: boolean = true): string | null => {
    if (priceObject === null || (priceObject?.price === null || priceObject?.currency === null)) {
        return null
    }
    return `${priceObject?.price.toFixed(2)}${withCurrency ? priceObject.currency : ''}`
}
