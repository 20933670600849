import * as React from 'react'
import MenuContextType from '../types/MenuContext/MenuContextType'
import MenuItemInterface from "../types/Menu/MenuItemInterface"
import { AuthContext, MenuContext } from '../App'

export enum MenuItemType {
    USER = 'USER',
    LOGIN = 'LOGIN',
    REGISTRATION = 'REGISTRATION',
    BASIC = 'BASIC',
}

interface Props {
    children?: React.ReactNode,
}

const menuItemsData: MenuItemInterface[] = [
    {
        id: 1,
        name: 'FAQ',
        url: '/faq',
        icon: '',
        isDisabled: false,
        isHidden: false,
        isProtected: false,
        type: MenuItemType.BASIC,
        activeRoutes: [
            '/faq',
        ],
    },
    {
        id: 2,
        name: 'Zoznam použivateľov',
        url: '/users',
        icon: '',
        isDisabled: false,
        isHidden: false,
        isProtected: true,
        type: MenuItemType.BASIC,
        activeRoutes: [
            '/users',
            '/add-user',
            '/edit-profile',
            '/addPackage/',
            '/addFeature/',
        ],
    },
    {
        id: 3,
        name: 'FAQ administrácia',
        url: '/administration-faq',
        icon: '',
        isDisabled: false,
        isHidden: true,
        isProtected: true,
        type: MenuItemType.BASIC,
        activeRoutes: [
            '/administration-faq',
            '/add/faq-item',
            '/edit/faq-item/',
            '/view/faq-item/'
        ],
    },
    {
        id: 4,
        name: 'Objednávky',
        url: '/orders-pro',
        icon: '',
        isDisabled: false,
        isHidden: true,
        isProtected: true,
        type: MenuItemType.BASIC,
        activeRoutes: [
            '/orders-pro',
            '/createPayment/byOrder/'
        ],
    },
    {
        id: 5,
        name: 'Ponuka',
        url: '/advertisements',
        icon: '',
        isDisabled: false,
        isHidden: false,
        isProtected: false,
        type: MenuItemType.BASIC,
        activeRoutes: [
            '/advertisements',
            '/advertisement/view'
        ],
    },
    {
        id: 6,
        name: 'Pridať inzerát',
        url: '/advertisement/add',
        icon: '',
        isDisabled: false,
        isHidden: false,
        isProtected: false,
        type: MenuItemType.BASIC,
        activeRoutes: [
            '/advertisement/add',
        ]
    },
    {
        id: 7,
        name: 'Prihlásiť sa',
        url: '/login',
        icon: '',
        isDisabled: false,
        isHidden: true,
        isProtected: false,
        type: MenuItemType.LOGIN,
        activeRoutes: [
            '/login'
        ],
    },
    {
        id: 8,
        name: 'Registrácia',
        url: '/registration',
        icon: '',
        isDisabled: false,
        isHidden: true,
        isProtected: false,
        type: MenuItemType.REGISTRATION,
        activeRoutes: [
            '/registration'
        ]
    },
    {
        id: 9,
        name: 'Môj profil',
        url: '/my-profile',
        icon: '',
        isDisabled: false,
        isHidden: true,
        isProtected: false,
        type: MenuItemType.USER,
        activeRoutes: [
            '/my-profile',
        ]
    },
    {
        id: 10,
        name: 'Moje inzeráty',
        url: '/my-advertisements',
        icon: '',
        isDisabled: false,
        isHidden: true,
        isProtected: false,
        type: MenuItemType.USER,
        activeRoutes: [
            '/my-advertisements',
            '/advertisement/edit',
        ]
    },
    {
        id: 11,
        name: 'Zmena hesla',
        url: '/change-password',
        icon: '',
        isDisabled: false,
        isHidden: true,
        isProtected: false,
        type: MenuItemType.USER,
        activeRoutes: [
            '/change-password',
        ]
    },
    {
        id: 12,
        name: 'Odhlasiť sa',
        url: '',
        icon: '',
        isDisabled: false,
        isHidden: true,
        isProtected: false,
        type: MenuItemType.USER,
        activeRoutes: [] // tento item nemôže byť aktívny
    },
]

export default function MenuProvider(props: Props): JSX.Element {
    const authContext = React.useContext(AuthContext)
    // state
    const [isVisible, setIsVisible] = React.useState<boolean>(true)
    const [menuItems, setMenuItems] = React.useState<MenuItemInterface[]>(menuItemsData)
    const [activeItem, setActiveItem] = React.useState<MenuItemInterface | null>(null)
    const [activeItemIndex, setActiveItemIndex] = React.useState<number | null>(null)

    const getLoginAndRegistrationMenuItems = (): MenuItemInterface[] => {
        return menuItems.filter((menuItem: MenuItemInterface) =>
            menuItem.type === MenuItemType.LOGIN || menuItem.type === MenuItemType.REGISTRATION
        )
    }

    const checkAccessToMenuItem = (menuItem: MenuItemInterface): boolean => {
        if (!menuItem.isProtected) {
            return true
        }
        switch (menuItem.name) {
            case 'Zoznam použivateľov':
                return authContext.userLogged !== null && authContext.userLogged.hasAccessToUserList
            case 'FAQ administrácia':
                return authContext.userLogged !== null && authContext.userLogged.hasAccessToFaqAdministration
            case 'Objednávky':
                return authContext.userLogged !== null && authContext.userLogged.hasAccessToProOrders
            default:
                return false
        }
    }

    const isMenuItemLogout = (menuItem: MenuItemInterface): boolean => {
        return menuItem.type === MenuItemType.USER && menuItem.name === 'Odhlasiť sa'
    }

    const getMenuItemsWithoutLoginAndRegistration = (): MenuItemInterface[] => {
        return menuItems.filter((menuItem: MenuItemInterface) =>
            menuItem.type !== MenuItemType.LOGIN && menuItem.type !== MenuItemType.REGISTRATION
        )
    }

    const getMenuItemsBasic = (): MenuItemInterface[] => {
        return menuItems.filter((menuItem: MenuItemInterface) =>
            menuItem.type === MenuItemType.BASIC
        )
    }

    const getUserMenuItems = (): MenuItemInterface[] => {
        return menuItems.filter((menuItem: MenuItemInterface) =>
            menuItem.type === MenuItemType.USER
        )
    }

    const isItemActive = (menuItem: MenuItemInterface): boolean => {
        // if the item contains only a bit of the url of any of the active routes, it is active
        return menuItem.activeRoutes.some((activeRoute: string) => {
            return window.location.pathname.includes(activeRoute)
        })
    }

    const isAnyItemActive = (menuItems: MenuItemInterface[]): boolean => {
        return menuItems.some((menuItem: MenuItemInterface) => {
            return isItemActive(menuItem)
        })
    }


    const getMenuItemsByIsUserLogged = (isUserLoggedIn: boolean): MenuItemInterface[] => {
        if (isUserLoggedIn) {
            return getMenuItemsWithoutLoginAndRegistration()
        }
        return [...getMenuItemsBasic(), ...getLoginAndRegistrationMenuItems()]
    }

    // context
    const menuContext: MenuContextType = {
        isVisible,
        setIsVisible,
        menuItems,
        setMenuItems,
        activeItem,
        setActiveItem,
        activeItemIndex,
        setActiveItemIndex,
        getLoginAndRegistrationMenuItems,
        getMenuItemsWithoutLoginAndRegistration,
        getUserMenuItems,
        getMenuItemsBasic,
        isMenuItemLogout,
        checkAccessToMenuItem,
        getMenuItemsByIsUserLogged,
        isItemActive,
        isAnyItemActive
    }

    // render
    return (
        <MenuContext.Provider value={menuContext}>
            {props.children}
        </MenuContext.Provider>
    )
}