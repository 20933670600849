import { Container, Typography } from '@mui/material'
import * as React from 'react'
import UserRegisterOrAdd from "../../components/user/UserRegisterOrAdd"
import { Action } from "react-fetching-library"
import { getApiUrlFromRelativeUrl } from '../../utils/api/ApiUtil'
import { AuthContext } from "../../App"
import {useNavigate} from "react-router-dom"

export default function AddUserPage(): JSX.Element {
    const COULD_NOT_CREATE_USER: string = 'Nepodarilo sa vytvoriť použivateľa'
    const USER_SUCCESSFULLY_CREATED: string = 'Použivateľ úspešne pridaný'
    // context
    const authContext = React.useContext(AuthContext)
    const navigate = useNavigate()

    const registerAction: Action = {
        method: 'POST',
        endpoint: getApiUrlFromRelativeUrl('/api/user/register'),
        headers: {
            Authorization: `Bearer ${authContext.jwtToken}`,
            Accept: 'application/json',
        }
    }


    //- effect - enabled
    React.useEffect(() => {
        if (!authContext.userLogged?.enabled) {
            //- ak user nie je enabled, tak ho presmerujeme aby si aktivoval ucet
            navigate('/user/not-enabled')
        }
    }, [])

    return (
        <Container sx={{mt: '5%'}}>
            <Typography variant='h5' component='div' className='text-primary' style={{fontFamily: 'OpenSansBold'}}>
                Pridanie použivateľa
            </Typography>
            <UserRegisterOrAdd
                isRegistration={false}
                action={registerAction}
                userLogged={authContext.userLogged}
                user={null}
                messageOnError={COULD_NOT_CREATE_USER}
                messageOnSuccess={USER_SUCCESSFULLY_CREATED}
                urlToRedirectToOnSuccess={'/users'}
                isRecaptchaEnabled={false}
            />
        </Container>
    )
}
