import * as React from 'react'
import { AdvertisementFilterContextType } from "../types/AdvertisementFilterContext/AdvertisementFilterContextType"
import { AdvertisementFilterContext } from "../App"
import { AdvertisementFilterInterface } from "../types/AdvertisementFilter/AdvertisementFilterInterface"

interface Props {
    children?: React.ReactNode,
}

enum AdvertisementThingType {
    MOVABLE = 1,
    IMMOVABLE = 2
}

export default function AdvertisementFilterProvider(props: Props): JSX.Element {
    //- get filter - TODO 4 K toto by malo byt nizsie asi... ale state to potrebuje
    const getFilterFromSessionStorage = (): AdvertisementFilterInterface => {
        const filter: string | null = sessionStorage.getItem('filter')
        if (filter) {
            return JSON.parse(filter)
        }
        return {
            thingType: AdvertisementThingType.IMMOVABLE,
            districtIds: [],
            cityIds: [],
            categoryIds: [],
            priceFrom: 0,
            priceTo: 0,
            sortBy: 'relevant',
        }
    }
    //- state
    const [filter, setFilter] = React.useState<AdvertisementFilterInterface>(getFilterFromSessionStorage())

    const setFilterToState = (filter: AdvertisementFilterInterface): void => {
        setFilter(filter)
    }

    //- set filter to state and session storage
    const setFilterToStateAndSessionStorage = (filter: AdvertisementFilterInterface): void => {
        setFilter(filter)
        sessionStorage.setItem('filter', JSON.stringify(filter))
    }

    //- remove filter z session storage
    const removeFilterFromSessionStorage = (): void => {
        sessionStorage.removeItem('filter')
    }

    //- context
    const advertisementFilterContextType: AdvertisementFilterContextType = {
        filter,
        setFilter,
        setFilterToState,
        setFilterToStateAndSessionStorage,
        getFilterFromSessionStorage,
        removeFilterFromSessionStorage
    }

    return (
        <AdvertisementFilterContext.Provider value={advertisementFilterContextType}>
            {props.children}
        </AdvertisementFilterContext.Provider>
    )
}
